export enum ChannelType {
  DIGITAL = 'WEB',
  AFFILIATE = 'AFF'
}

export enum SegmentType {
  RESIDENTIAL = 'residential',
  BUSINESS = 'business',
  STUDENT = 'student'
}

export enum ProductType {
  PACKAGES = 'packages',
  BUNDLES = 'bundles',
  PRODUCTS = 'products'
}

export enum CustomerType {
  BUSINESS = 'BUSINESS',
  RESIDENTIAL = 'RESIDENTIAL',
  COMMUNITY = 'COMMUNITY'
}

export enum ServiceType {
  BROADBAND = 'BROADBAND',
  VOICE = 'VOICE',
  TELEVISION = 'TELEVISION',
  WIER = 'WIER',
  VAS = 'VAS'
}

export enum ContractType {
  ANNUAL_24 = 'ANNUAL_24',
  ANNUAL = 'ANNUAL',
  ANNUAL_6 = 'ANNUAL_6',
  MONTHLY = 'MONTHLY'
}

export enum CategoryType {
  BASE_PRODUCT = 'BASE_PRODUCT',
  ADD_ON_SERVICE = 'ADD_ON_SERVICE',
  ADD_ON_PRODUCT = 'ADD_ON_PRODUCT'
}

export enum DiscountModeType {
  NO_DISCOUNT = 'NO_DISCOUNT',
  RECURRING_FIXED_END = 'RECURRING_FIXED_END',
}

export enum PeriodType {
  months = 'months',
}

export enum DiscountType {
  AMOUNT = 'AMOUNT',
}

export enum ModelType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING'
}

export enum TaxModeType {
  INCLUSIVE = 'INCLUSIVE'
}

export enum ChargeEndType {
  NO_END = 'NO_END',
  FIXED_END = 'FIXED_END'
}

export enum ChargeStartType {
  ACCOUNT_STAGE_INVOICING = 'ACCOUNT_STAGE_INVOICING',
}

export enum TrueType {
  TRUE = 'TRUE'
}

export enum PlatformType {
  BOX = 'BOX',
  CF = 'CF'
}

export enum StepType {
  broadband = 'broadband',
  broadbandBundles = 'broadband-bundles',
  addons = 'addons',
  checkout = 'checkout',
  checkoutDetails = 'checkout-details',
  checkoutInstallation = 'checkout-installation',
  checkoutOneTouchSwitching = 'checkout-one-touch-switching',
  checkoutPayment = 'checkout-payment',
  checkoutOrderSummary = 'checkout-order-summary',
  checkoutOrderProcessing = 'checkout-order-processing',
  checkoutOrderConfirmation = 'checkout-order-confirmation',
  orderManagement = 'order-management',
  orderManagementPortingConfirmation = 'order-management-porting-confirmation',
  orderManagementNewNumberConfirmation = 'order-management-new-number-confirmation',
}

