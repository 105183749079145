import React, { useContext } from 'react'
import classes from './OrderBottomBar.styles'
import { Product } from '../../utils/commonInterfaces'
import {
  OrderOptions, THREE_GIGA_IDS, useOrderContext,
} from '../NewOrderFlow/OrderContext'
import { useRouter } from 'next/router'
import getStepUrl from '../NewOrderFlow/getStepUrl'
import Bar from './Bar'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { getOrderProducts } from '../../utils/getOrderProducts'
import productsHaveSameContract from '../../utils/productsHaveSameContract'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import { TechnologyType } from '../../api/Addresses'
import { getFormattedMonthlyCost } from '../../utils/getProductDetails'
import { HobsServiceType } from '../../api/Products'
import { checkForVoucher } from '../Packages/Helpers'
import { scrollStepIntoView } from '../../utils'
import { StepType } from '../../utils/commonEnums'

export interface OrderBottomBarProps {
  title?: string;
  orderOptions?: OrderOptions;
  voice_icon?: string;
  tv_icon?: string;
  broadband_icon?: string;
  wier_icon?: string;
  vas_icon?: string;
  bundle_icon?: string;
  anchor?: string;
  button_copy_first_step?: string;
  button_copy_second_step?: string;
}

const OrderBottomBar = ({
  orderOptions, voice_icon, tv_icon, broadband_icon, wier_icon, vas_icon, bundle_icon, anchor, button_copy_first_step, button_copy_second_step,
}: OrderBottomBarProps) => {
  const router = useRouter()
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    broadband, voice, tv, wier, vas, bundle, cartInstanceIdentifier, channel,
  } = options

  const { premise } = useContext(PremiseContext)
  const isEligibleFor3Giga = premise?.technology === TechnologyType.XGSPON || premise?.technology === TechnologyType.COMBO
  const broadbandData = orderOptions?.broadband
  const voiceData = orderOptions?.voice
  const wierData = orderOptions?.wier
  const vasData = orderOptions?.vas
  const tvData = orderOptions?.tv
  const bundleData = orderOptions?.bundle
  const discount = options.discount
  const monthlyCost = broadbandData || bundleData ? `${getFormattedMonthlyCost({
    broadband: broadbandData,
    voice: voiceData,
    tv: tvData,
    wier: wierData,
    vas: vasData,
    bundle: bundleData,
    discount,
  })}` : ''

  const { sprn } = router.query
  const sprnNumber = isNaN(Number(sprn)) ? undefined : Number(sprn)

  const orderProducts = getOrderProducts({
    broadband,
    voice,
    tv,
    wier,
    vas,
    bundle,
  })

  const premiseCannotOrder3Giga = !isEligibleFor3Giga && orderProducts.some((product: any) => THREE_GIGA_IDS.includes(product._id))

  const handleClick = async (broadband?: Product) => {
    setOptions({
      ...options,
      broadband: broadband?.service_type === HobsServiceType.BUNDLE ? null : broadband,
      bundle: broadband?.service_type === HobsServiceType.BUNDLE ? broadband : null,
    })

    const sameContractLength = productsHaveSameContract({
      broadband,
      voice,
      tv,
      wier,
      vas,
      bundle,
    })

    if (!sameContractLength) {
      setOptions({
        ...options,
        showDifferentContractError: true,
      })

      setTimeout(() => {
        router.push(`${getStepUrl(StepType.broadbandBundles, sprnNumber)}#contract-length-error`, undefined, { shallow: true })
      }, 250)
      return
    }

    if (broadband) {
      marketingEvents.addToCartFacebook(orderProducts)
      router.push(getStepUrl(StepType.addons, sprnNumber))
    }

    const currentUrl = router.asPath
    const addons = currentUrl?.includes(StepType.addons)
    if (addons) {
      if (options.offer === 'accepted' && (broadband?.voucher! || options.voucher_flags!.length > 0 || options.voucherProduct)) {
        if (broadband) {
          const addVoucher = await checkForVoucher(options.broadband, options, channel, cartInstanceIdentifier)
          setOptions(addVoucher)
        }
      }

      marketingEvents.addToCartFacebook(orderProducts)
      router.push(getStepUrl(StepType.checkout, sprnNumber))
    }

    scrollStepIntoView('#header', 300)
  }

  if ((!broadbandData && !bundleData) || premiseCannotOrder3Giga) {
    return null
  }

  return (
    <section className={classes.sectionWrapper} id={anchor}>
      {(broadbandData || bundleData) &&
        <Bar
          voiceData={voiceData}
          broadbandData={broadbandData}
          broadbandIcon={broadband_icon}
          voiceIcon={voice_icon}
          tvData={tvData}
          tvIcon={tv_icon}
          wierData={wierData}
          wierIcon={wier_icon}
          vasData={vasData}
          vasIcon={vas_icon}
          bundleData={bundleData}
          bundleIcon={bundle_icon}
          buttonCopyFirstStep={button_copy_first_step}
          buttonCopySecondStep={button_copy_second_step}
          onClick={handleClick}
          monthlyCost={monthlyCost}
        />}
    </section>
  )
}

export default OrderBottomBar
