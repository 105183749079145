import { StepType } from '../../utils/commonEnums'
import { StepTypes } from './steps/CheckoutStep/CheckoutStep'

export default function getStepTitle(step: StepTypes): string {
  const base = (() => {
    switch (step) {
      case StepType.broadband:
      case StepType.broadbandBundles:
        return 'Choose your broadband'
      case StepType.addons:
        return 'Choose addons'
      case StepType.checkout:
      case StepType.checkoutDetails:
      case StepType.checkoutInstallation:
      case StepType.checkoutOneTouchSwitching:
      case StepType.checkoutPayment:
        return StepType.checkout
      case StepType.checkoutOrderSummary:
      case StepType.checkoutOrderProcessing:
      case StepType.checkoutOrderConfirmation:
        return 'Order Summary'
      case StepType.orderManagement:
        return 'Order Management'
      case StepType.orderManagementPortingConfirmation:
        return 'Porting request successful'
      case StepType.orderManagementNewNumberConfirmation:
        return 'New porting request successful'
    }
  })()

  return `${base} | Community Fibre`
}
