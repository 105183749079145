import React from 'react'
import Head from 'next/head'
import parse from 'html-react-parser'
import { sanitiseHtml, SanitiseProfile } from '../../utils/sanitise'

export interface MetadataControlProps {
  metadata: string;
}

export default function MetadataControl({
  metadata,
}: MetadataControlProps) {
  const parsedMetadata = parse(sanitiseHtml(metadata, SanitiseProfile.EMBED))

  return (
    <Head>
      {parsedMetadata}
    </Head>
  )
}
