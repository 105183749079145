import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react'
import createCFOApiClient from '../api/CFO/CFOAuth'
import { PostToken } from '../api/CFO/GetToken'
import marketingEvents from '../utils/marketing/marketingEvents'
import { useOrderContext } from '../components/NewOrderFlow/OrderContext'

  interface AuthState {
    token?: string | null;
    isTokenAuthenticated?: boolean;
  }

  interface AuthContextData {
    isAuthenticated?: boolean;
    signIn(): Promise<string | null>;
  }

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC = ({ children }) => {
  const [
    data,
    setData,
  ] = useState<AuthState | null>(() => {
    if (typeof sessionStorage !== 'undefined') {
      const token = sessionStorage.getItem('access_token')
      const tokenExpire = sessionStorage.getItem('token_expires')
      const currentTime = Date.now()
      if (token && currentTime <= Number(tokenExpire)) {
        return { token }
      }
    }

    return {} as AuthState
  })

  useEffect(() => {
    signIn()

    // Refresh token every 30 minutes
    const refreshInterval = setInterval(() => {
      signIn()
    }, 30 * 60 * 1000) // 30 minutes in milliseconds

    // Cleanup interval on unmount
    return () => clearInterval(refreshInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getToken(): Promise<PostToken | null> {
    const cfoClient = createCFOApiClient()
    const premise = await cfoClient.getToken.getAccessToken()
    return premise
  }

  const signIn = useCallback(
    async () => {
      if (typeof sessionStorage !== 'undefined') {
        const token = sessionStorage.getItem('access_token')
        const tokenExpire = sessionStorage.getItem('token_expires')
        const currentTime = Date.now()

        if (tokenExpire && Number(tokenExpire) > currentTime) {
          setData({
            token,
            isTokenAuthenticated: true,
          })
          return token
        }

        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('token_expires')

        const authToken = await getToken()

        if (authToken) {
          sessionStorage.setItem('access_token', authToken.access_token)
          sessionStorage.setItem(
            'token_expires',
            (Date.now() + (30 * 60 * 1000)).toString(), // 30 minutes in milliseconds
          )
          setData({
            token: authToken.access_token,
            isTokenAuthenticated: true,
          })

          return authToken.access_token
        }
      }
      // Unable to get a new token, set isAuthenticated to false

      setData({
        token: undefined,
        isTokenAuthenticated: false,
      })

      return null
    },
    [],
  )
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: data?.token && data?.isTokenAuthenticated ? true : undefined,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  const {
    options,
  } = useOrderContext()

  if (!context) {
    const errorMsg = 'useAuth must be used within a AuthProvider'
    marketingEvents.error(options, 'ERR-AUTH-1', 'No auth context', errorMsg)
    throw new Error(errorMsg)
  }

  return context
}

export {
  AuthProvider,
  useAuth,
}
