import { isSameDay } from 'date-fns'
import {
  Timeslot as BackendTimeslot, TimeslotData,
} from '../../api/BookingCalendar'
import moment from 'moment'

type Timeslot = {
  time: string;
  start: number;
  end: number;
} & ({
  isAvailable: true;
  timeslot: BackendTimeslot;
} | {
  isAvailable: false;
  timeslot: null;
})

let startDate: string
let endDate: string

export interface AppointmentWindowProps {
  startDateTime: string;
  endDateTime: string;
}

export const AM_PM_SCHEDULE = {
  am: {
    start: 8,
    end: 13,
  },
  pm: {
    start: 13,
    end: 18,
  },
}

export const AM_PM_EMPTY_SLOTS = {
  am: [[
    AM_PM_SCHEDULE.am.start,
    AM_PM_SCHEDULE.am.end,
  ]],
  pm: [[
    AM_PM_SCHEDULE.pm.start,
    AM_PM_SCHEDULE.pm.end,
  ]],
}

export function getDayByIndex({
  year,
  week,
  dayIndex,
}: {
  year: number;
  week: number;
  dayIndex: number;
}): Date {
  // Use moment for consistent ISO week handling
  return moment()
    .isoWeekYear(year)
    .isoWeek(week)
    .isoWeekday(dayIndex + 1)
    .toDate()
}

export function transformTimeslot(timeslot: BackendTimeslot): Timeslot {
  const {
    startHour, endHour,
  } = getTimeslotHours(timeslot)
  const time = formattedSlot(startHour, endHour)
  return ({
    time,
    start: startHour,
    end: endHour,
    isAvailable: true,
    timeslot,
  })
}

export function formattedSlot(start: number, end: number) {
  const formattedStart = `${('0' + start).slice(-2)}:00`
  const formattedEnd = `${('0' + end).slice(-2)}:00`
  return `${formattedStart} - ${formattedEnd}`
}

export function isSameDayTimeslot(timeslot: BackendTimeslot, dayDate: Date): boolean {
  const timeslotDate = moment.utc(timeslot._id.startDateTime)
    .toDate()
  return isSameDay(timeslotDate, dayDate)
}

export function formatTimeForTimeslotData(timeslot: TimeslotData | BackendTimeslot | AppointmentWindowProps) {
  let startDateTime = timeslot.startDateTime
  let endDateTime = timeslot.endDateTime

  if ('appointmentWindowData' in timeslot) {
    startDateTime = timeslot.appointmentWindowData[0]?.startDateTime
    endDateTime = timeslot.appointmentWindowData[0]?.endDateTime
  }

  const {
    startHour, endHour,
  } = getTimeslotDataHours(startDateTime, endDateTime)
  return formattedSlot(startHour, endHour)
}

function calculateDaylightSavings() {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  // Get last Sunday in March (DST start)
  const marchLastDay = new Date(currentYear, 3, 0) // Last day of March
  const dstStart = new Date(currentYear, 2, marchLastDay.getDate() - marchLastDay.getDay(), 2, 0, 0) // Last Sunday in March at 2:00 AM

  // Get last Sunday in October (DST end)
  const octoberLastDay = new Date(currentYear, 10, 0) // Last day of October
  const dstEnd = new Date(currentYear, 9, octoberLastDay.getDate() - octoberLastDay.getDay(), 3, 0, 0) // Last Sunday in October at 3:00 AM

  return currentDate >= dstStart && currentDate < dstEnd
}

export const isDaylightSavings: boolean = calculateDaylightSavings()

function daylightSavings(startDateTime: string, endDateTime: string) {
  const momentStart = moment.utc(startDateTime)
  const momentEnd = moment.utc(endDateTime)

  if (isDaylightSavings === true) {
    startDate = momentStart.add(1, 'hours')
      .format('YYYY-MM-DD HH:mm:ss')
    endDate = momentEnd.add(1, 'hours')
      .format('YYYY-MM-DD HH:mm:ss')
  } else {
    startDate = momentStart.format('YYYY-MM-DD HH:mm:ss')
    endDate = momentEnd.format('YYYY-MM-DD HH:mm:ss')
  }

  return {
    startDate,
    endDate,
  }
}

export function getTimeslotDataHours(startDateTime: string, endDateTime: string): { startHour: number; endHour: number } {
  daylightSavings(startDateTime, endDateTime)
  const startHour = moment.utc(startDate)
    .hour()
  const endHour = moment.utc(endDate)
    .hour()
  return {
    startHour,
    endHour,
  }
}

export function getTimeslotHours(timeslot: BackendTimeslot): { startHour: number; endHour: number } {
  const {
    startDateTime, endDateTime,
  } = timeslot._id
  daylightSavings(startDateTime, endDateTime)
  const startHour = moment.utc(startDate)
    .hour()
  const endHour = moment.utc(endDate)
    .hour()
  return {
    startHour,
    endHour,
  }
}

export function isAmPmMorningTimeslot(timeslot: BackendTimeslot, dayDate: Date): boolean {
  const {
    startHour, endHour,
  } = getTimeslotHours(timeslot)
  return isSameDayTimeslot(timeslot, dayDate) && startHour === AM_PM_SCHEDULE.am.start && endHour === AM_PM_SCHEDULE.am.end
}

export function isAmPmAfternoonTimeslot(timeslot: BackendTimeslot, dayDate: Date): boolean {
  const {
    startHour, endHour,
  } = getTimeslotHours(timeslot)
  return isSameDayTimeslot(timeslot, dayDate) && startHour === AM_PM_SCHEDULE.pm.start && endHour === AM_PM_SCHEDULE.pm.end
}
