import React, {
  useEffect, useRef,
} from 'react'
import {
  Container, Box,
  useMediaQuery,
} from '@material-ui/core'
import { useGeneralContext } from '../../GeneralContext/GeneralContext'
import theme from '../../../styles/theme'
import TypographyCMS, {
  createTypographyProps, TypographyProps,
} from '../../Typography/Typography'
import { cx } from 'linaria'
import classes from './TrustPilotBox.styles'

export interface TrustPilotBoxProps {
  inverted?: boolean;
  wrapped?: boolean;
  widget?: string;
  width?: string;
  margin?: number;
  padding?: number;
  offset?: number;
  background_color?: string;
  title?: TypographyProps;
  styles?: string;
  mobile_center?: boolean;
}

declare global {
  interface Window { Trustpilot: any }
}

export const createTrustPilotProps = (trustpilot: TrustPilotBoxProps) => ({
  background_color: trustpilot.background_color,
  widget: trustpilot.widget,
  padding: trustpilot.padding,
  margin: trustpilot.margin,
  width: trustpilot.width,
  offset: trustpilot.offset,
  inverted: trustpilot.inverted,
  mobile_center: trustpilot.mobile_center,
})

function TrustPilotBox({
  inverted,
  widget,
  wrapped,
  padding,
  background_color,
  width,
  offset,
  title,
  styles,
  margin,
  mobile_center,
}: TrustPilotBoxProps) {
  const trustBox = useRef(null)

  const { generalOptions } = useGeneralContext()
  const {
    trustpilotWidgets, trustpilotId,
  } = generalOptions

  const filteredWidget = trustpilotWidgets?.filter((name) => name[0] === widget)[0]

  useEffect(() => {
    try {
      if (window.Trustpilot && filteredWidget && filteredWidget[0]) {
        window.Trustpilot.loadFromElement(trustBox.current, true)
      }
    } catch (err) {
      console.error('Error loading TrustPilot:', err)
    }
  }, [
    filteredWidget,
    trustpilotId,
  ])

  const createBackgroundColor = (color: string | undefined) => {
    if (wrapped || !color) {
      return
    }

    return {
      backgroundColor: color,
    }
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const renderWidget = () => {
    const template = filteredWidget && filteredWidget[1] ? filteredWidget[1] : '5419b732fbfb950b10de65e5'
    const height = filteredWidget && filteredWidget[2] ? filteredWidget[2] : '24px'
    const getPvalue = () => {
      if (!wrapped && padding) {
        return isMobile ? '40px' : padding
      }

      return 0
    }

    return (
      <Box
        mt={!wrapped && margin ? margin : 0}
        p={getPvalue()}
        style={createBackgroundColor(background_color)}
      >
        {title && <TypographyCMS {...createTypographyProps(title)}/>}
        <div
          ref={trustBox}
          className={cx(classes.trustpilot, mobile_center && 'mobileCenter', styles)}
          data-locale="en-GB"
          data-template-id={template}
          data-businessunit-id={trustpilotId}
          data-style-height={height}
          data-style-width={width ? width : '100%'}
          data-theme={inverted ? 'dark' : 'light'}
          data-stars="4,5"
          data-review-languages="en"
          style={{
            position: 'relative',
            left: offset ? '-' + theme.spacing(offset) + 'px' : '0',
            width: '100%',
          }}
        />
      </Box>
    )
  }

  if (wrapped) {
    return (
      <section style={{ backgroundColor: background_color ? background_color : 'transparent' }}>
        <Box component={Container} py={padding || padding === 0 ? padding : 10}>
          {renderWidget()}
        </Box>
      </section>
    )
  }

  return renderWidget()
}

export default TrustPilotBox
