import { useRouter } from 'next/router'
import { ButterCMSComponent } from '../butter'
import { generateStructuredDataFromComponents } from '../utils/structuredData'

interface UseStructuredDataProps {
  components: ButterCMSComponent[];
  title: string;
  description?: string;
}

/**
 * Hook to generate structured data for a page
 */
export const useStructuredData = ({
  components, title, description,
}: UseStructuredDataProps) => {
  const router = useRouter()
  const hostname = 'https://communityfibre.co.uk'
  const pageUrl = `${hostname}${router.asPath.split('?')[0]}`

  // Skip structured data for package pages
  if (router.pathname === '/packages' || router.pathname.startsWith('/packages/')) {
    return []
  }

  // Get meta description from the page if not provided
  let metaDescription = description
  if (!metaDescription && typeof document !== 'undefined') {
    const metaTag = document.querySelector('meta[name="description"]')
    if (metaTag) {
      metaDescription = metaTag.getAttribute('content') || undefined
    }
  }

  return generateStructuredDataFromComponents(components, title, pageUrl, metaDescription)
}
