import React, {
  useEffect, useState,
} from 'react'
import {
  Box,
} from '@material-ui/core'
import classes, {
  lineStyles, productStyles,
} from './OrderBottomBar.styles'
import StyledButton from '../StyledButton/StyledButton'
import { Product } from '../../utils/commonInterfaces'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { checkIfContinueDisabled } from '../Products/Products'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useRouter } from 'next/router'
import Wrapper from '../Wrapper/Wrapper'
import {
  mapParamsToStep,
} from '../../pages/order/[...step]'
import { StepType } from '../../utils/commonEnums'
interface BarProps {
  voiceData?: any;
  tvData?: Product | null;
  broadbandData: any;
  broadbandIcon?: string;
  voiceIcon?: string;
  tvIcon?: string;
  wierData?: Product | null;
  wierIcon?: string;
  vasData?: Product | null;
  vasIcon?: string;
  bundleData?: any;
  bundleIcon?: string;
  buttonText?: string;
  onClick: ((e: Product) => void);
  monthlyCost?: string;
  buttonCopyFirstStep?: string;
  buttonCopySecondStep?: string;
}

const Bar = ({
  voiceData, tvData, broadbandData, broadbandIcon, voiceIcon, tvIcon, wierData, wierIcon, vasData, vasIcon, bundleData, bundleIcon,
  onClick, monthlyCost, buttonCopyFirstStep, buttonCopySecondStep,
}: BarProps) => {
  const {
    options,
  } = useOrderContext()

  const {
    alternativePhone,
  } = options

  const router = useRouter()

  const [
    step,
    setStep,
  ] = useState<string|undefined>('')

  const [
    buttonCopy,
    setButtonCopy,
  ] = useState<string>('Continue')

  const currentUrl = router.asPath

  function handleRouteChange(url: string) {
    const parts = url.split('?')[0].split('/')

    const pathStep = parts[2]
    const pathSubStep = parts[3]

    const step = mapParamsToStep({
      step: pathStep,
      subStep: pathSubStep,
    })

    if (step) {
      setStep(step)
    }
  }

  useEffect(() => {
    handleRouteChange(currentUrl)
  }, [currentUrl])

  useEffect(() => {
    if (step === StepType.broadband && buttonCopyFirstStep) {
      setButtonCopy(buttonCopyFirstStep)
    }

    if (step === StepType.addons && buttonCopySecondStep) {
      setButtonCopy(buttonCopySecondStep)
    }
  }, [step])

  return (
    <Wrapper containerClasses={classes.container}>
      <Box
        display="flex"
        alignItems={{
          xs: 'flex-start',
          sm: 'center',
        }}
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
        flexGrow={1}
      >
        <Box
          display="flex"
          alignItems={{
            xs: 'flex-start',
            md: 'center',
          }}
          flexGrow={1}
          className={classes.products}
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
        >
          {bundleData &&
            <Box {...productStyles}>
              <img className={classes.icon} src={bundleIcon} alt="bundle-icon" style={{ height: '22px' }}/>
              <Box display="flex" alignItems="center">
                {bundleData.display_name}
                <Box {...lineStyles}/>
              </Box>
            </Box>}
          {broadbandData &&
            <Box {...productStyles}>
              <img className={classes.icon} src={broadbandIcon} alt="broadband-icon"/>
              <Box display="flex" alignItems="center">
                {broadbandData?.display_name}
                <Box {...lineStyles}/>
              </Box>
            </Box>}
          {tvData &&
            <Box {...productStyles}>
              <img className={classes.icon} src={tvIcon} alt="tv-icon"/>
              <Box display="flex" alignItems="center">
                {tvData.display_name}
                <Box {...lineStyles}/>
              </Box>
            </Box>}
          {voiceData &&
            <Box {...productStyles}>
              <img className={classes.icon} src={voiceIcon} alt="voice-icon"/>
              <Box display="flex" alignItems="center">
                {voiceData.display_name}
                <Box {...lineStyles}/>
              </Box>
            </Box>}
          {wierData &&
            <Box {...productStyles}>
              <img className={classes.icon} src={wierIcon} alt="wier-icon" style={{ height: '28px' }}/>
              <Box display="flex" alignItems="center">
                {wierData.display_name}
              </Box>
            </Box>}
          {vasData &&
            <Box {...productStyles}>
              <img className={classes.icon} src={vasIcon} alt="vas-icon"/>
              <Box display="flex" alignItems="center">
                {vasData.display_name}
              </Box>
            </Box>}
        </Box>
        <Box
          pl={{
            xs: 0,
            sm: 4,
            md: 8,
          }}
          mt={{
            xs: 1,
            sm: 0,
          }}
        >
          {monthlyCost}/month
        </Box>
      </Box>
      <Box
        pl={2}
      >
        <StyledButton
          color="primary"
          className={classes.button}
          handleClick={() => {
            onClick(broadbandData || bundleData!)
            marketingEvents.cta({
              name: buttonCopyFirstStep,
              router,
              options,
            })
          }}
          disabled={checkIfContinueDisabled(broadbandData, bundleData, voiceData, (step === StepType.addons ? alternativePhone : true))}
        >
          {buttonCopy}
        </StyledButton>
      </Box>
    </Wrapper>
  )
}

export default Bar
