import { css } from 'linaria'
import theme, {
  COLORS,
  GRADIENTS, fontWeights,
} from '../../styles/theme'

const classes = {
  button: css`
    text-align: center;
    &:not(.disablePadding) {
      &.MuiButton-root {
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
    }
    &.disablePadding {
      line-height: 1.5;
      padding: 0;
      .MuiButton-label {
        margin: 0;
        font-weight: ${fontWeights.semibold};
      }
    }
  `,
  packageButtons: css`
    width:100%;
    display: flex;
    gap: 8px;
    ${theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    }
    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
      }
    margin-bottom: 24px;
    margin-top: 24px;
  `,
  justifyEnd: css`
  justify-content: flex-end;
  `,
  justifyBetween: css`
  justify-content: space-between;
  `,
  hideBack: css`
    display: none !important;
  `,
  fullWidth: css`
    width: 100%;
  `,
  blueGradient: css`
    &.MuiButton-root {
      background: ${GRADIENTS.businessButton};
      :hover {
        background: ${GRADIENTS.businessButtonHover};
      }
    }
  `,
  moreInfoBtn: css`
    max-width: 80%;
    &.MuiButtonBase-root {
      margin-top: ${theme.spacing(1)}px;
    }
    ${theme.breakpoints.down('sm')} {
      &.MuiButtonBase-root {
        padding: ${theme.spacing(1, 0, 5)};
      }
    }
  `,
  disableContained: css`
    &.MuiButton-contained {
      color: #fff !important;
      opacity: 0.2 !important;
      background: ${COLORS.secondary} !important;
    }
  `,
  activeContained: css`
    &.MuiButton-contained {
      &:hover {
        background-color: unset !important;
        color: unset !important;
        box-shadow: unset !important;
      }
    }
  `,
  direction: css`
    &.MuiButtonBase-root {
      display: flex;
      align-items: center;
    }
  `,
}

export default classes
