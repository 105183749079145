import React, {
  useEffect,
  useState,
} from 'react'
import {
  Box, CircularProgress, Grid, Typography,
} from '@material-ui/core'
import { classes } from './RescheduleInstallation.styles'
import Calendar from '../../../../Calendar/Calendar'
import {
  ScheduleType,
  Timeslot, TimeslotData,
} from '../../../../../api/BookingCalendar'
import createCRMApiClient from '../../../../../api/CRMApi'
import logger from '../../../../../utils/logger'
import StyledButton from '../../../../StyledButton/StyledButton'
import {
  AppointmentWindowProps, formatTimeForTimeslotData,
} from '../../../../Calendar/calendarUtils'
import { IWorkorderProps } from '../../../../../api/WorkOrder'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import { useForm } from 'react-hook-form'
import WrapperWithSection from '../../../../Wrapper/WrapperWithSection'
import { useOrderContext } from '../../../OrderContext'
import { PremiseDetail } from '../../../../../api/Addresses'

interface RescheduleInstallationProps {
  account: IWorkorderProps;
  recentWorkorder: string;
  scheduleType: ScheduleType;
  fullPremise: PremiseDetail;
}
export interface ReschedulePremiseDetailsProps {
  installType: number;
  type: string;
  borough: string;
}

const accountFilteredStatus = {
  open: 'Open',
  scheduled: 'Scheduled',
  dispatached: 'Dispatached',
}

const RescheduleInstallation = ({
  account, recentWorkorder, scheduleType, fullPremise,
}: RescheduleInstallationProps) => {
  const [
    timeslot,
    setTimeslot,
  ] = useState<Timeslot | null>(null)

  const [
    error,
    setError,
  ] = useState('')

  const [
    success,
    setSuccess,
  ] = useState(false)

  const [
    disableButton,
    setDisableButton,
  ] = useState(false)

  useEffect(() => {
    setDisableButton(false)
    setError('')
  }, [timeslot])

  const {
    options,
  } = useOrderContext()

  useEffect(() => {
    marketingEvents.appointmentEvent({
      eventName: 'reschedule_appointment_login',
      options,
      accountNumber: account?.accountNumber,
      borough: fullPremise.borough || account?.region,
      broadbandProduct: account?.broadbandProduct,
    })
  }, [])

  const methods = useForm()

  const {
    handleSubmit, formState,
  } = methods

  const { isSubmitting } = formState

  const premiseDetails: ReschedulePremiseDetailsProps = {
    installType: fullPremise.install_type || fullPremise.installType || account.installationType,
    type: fullPremise.type || account.accountType,
    borough: fullPremise.borough || account.region,
  }

  const allowedStatuses = new Set([
    accountFilteredStatus.open,
    accountFilteredStatus.dispatached,
    accountFilteredStatus.scheduled,
  ])

  const currentDate: AppointmentWindowProps = {
    startDateTime: account.workOrderTaskAppointmentWindowStartDate,
    endDateTime: account.workOrderTaskAppointmentWindowEndDate,
  }

  const onSubmit = handleSubmit(async () => {
    const errorName = 'Reschedule installation error'

    if ((!timeslot)) {
      const errorMsg = 'Please select an appointment.'
      marketingEvents.error(options, 'ERR-RESCHEDULE-1', errorName, errorMsg)
      setError(errorMsg)
      setDisableButton(true)
      return
    }

    const client = createCRMApiClient()
    const result = await client.bookingCalendar.rescheduleTimeslot({
      recentWorkorder,
      timeslot,
    })

    if ('error' in result || result.data[0].customerResponseData.responseTitle === 'Appointment Failed') {
      logger.error(result.message || result.data[0].customerResponseData.responseTitle, result.error || result.data[0].customerResponseData.responsMessage)
      const errorMsg = 'Unable to reserve the appointment, please contact customer service on 0800 082 0770'
      marketingEvents.error(options, 'ERR-RESCHEDULE-2', errorName, errorMsg)
      setError(errorMsg)
      setDisableButton(true)
      return
    }

    marketingEvents.appointmentEvent({
      eventName: 'reschedule_appointment_submit',
      options,
      accountNumber: account?.accountNumber,
      appointmentWindowId: timeslot?.appointmentWindowId,
      date: timeslot?.date,
      borough: fullPremise.borough || account?.region,
      broadbandProduct: account?.broadbandProduct,
    })
    setSuccess(true)
  })

  const formatTimeslotDetails = (timeslot: Timeslot | TimeslotData | AppointmentWindowProps) => {
    const {
      startDateTime,
    } = timeslot
    const start = new Date(startDateTime)
    const time = formatTimeForTimeslotData(timeslot)
    return `${start.toLocaleDateString('en-GB')} - ${time}`
  }

  const FormSuccessZinier = ({ timeslot }: { timeslot: TimeslotData | Timeslot | AppointmentWindowProps }) => (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={2} height={300}>
      <Typography color="primary" align="center" variant="h3">Appointment Rescheduled</Typography>
      <Box mt={3}>
        <Typography color="primary" align="center" variant="body1">Your appointment has been successfully rescheduled for:</Typography>
        <br/>
        <Typography color="primary" variant="body1" align="center">{formatTimeslotDetails(timeslot)}</Typography>
      </Box>
    </Box>
  )
  const FormRejectZinier = () => (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={2} height={300}>
      <Typography color="primary" align="center" variant="h3">There&#39;s an issue with your reschedule</Typography>
      <Box mt={3} minWidth="120%">
        <Typography color="primary" align="center" variant="body1">Please retry later; if you still have issues please get in touch with our friendly team by calling 0800 082 0770 Monday to Friday, 8am - 9pm, weekends and bank holidays, 9am - 7pm.</Typography>
      </Box>
    </Box>
  )

  const broadbandProduct = account.broadbandProduct
  const accountStatus = account.statusName
  const renderCalendar = () => {
    if (!allowedStatuses.has(accountStatus)) {
      return (
        <h3>Sorry, you cannot Reschedule the installation.</h3>
      )
    }

    return (
      <Calendar
        fullPremise={premiseDetails}
        onTimeslotChange={(t: Timeslot) => {
          setTimeslot(t)
        }}
        broadbandProduct={broadbandProduct}
        scheduleType={scheduleType}
      />
    )
  }

  const renderInstallationLayout = () => {
    if (error) {
      return (
        <FormRejectZinier/>
      )
    }

    if (timeslot && success) {
      return (
        <FormSuccessZinier timeslot={timeslot}/>
      )
    }

    return (
      <Box component="form" onSubmit={onSubmit} mb={6}>
        <Box display="flex" flexDirection="column" justifyContent="left" textAlign="left" py={2} mb={8} className={classes.title}>
          {scheduleType === ScheduleType.INSTALL ?
            <>
              <Typography variant="h1" color="primary">Reschedule your installation appointment</Typography>
              <Typography variant="body1" color="primary"><b>Selected installation date:</b> <span>{currentDate && formatTimeslotDetails(currentDate)}</span></Typography>
              <Typography variant="body1" color="primary"><b>Select a new date and time for installation by using the calendar below</b></Typography>
            </> :
            <>
              <Typography variant="h1" color="primary">Reschedule your service call</Typography>
              <Typography variant="body1" color="primary"><b>Selected installation date:</b> <span>{currentDate && formatTimeslotDetails(currentDate)}</span></Typography>
              <Typography variant="body1" color="primary"><b>Select a new date and time for your engineering visit by using the calendar below</b></Typography>
            </>}
        </Box>
        {renderCalendar()}
        {error && <Box mt={3}><Typography align="center" color="error">{error}</Typography></Box>}
        {timeslot && formatTimeslotDetails &&
        <Box mt={3} className={classes.selectedTimeslot}>
          <Typography variant="body1" align="left">
            {scheduleType === ScheduleType.INSTALL ?
              <b>Selected installation date:</b> :
              <b>Your new service call date:</b>}
            <span>{timeslot && formatTimeslotDetails(timeslot)}</span>
          </Typography>
        </Box>}
        <StyledButton className={classes.button} color="primary" type="submit" disabled={isSubmitting || disableButton || !allowedStatuses.has(accountStatus)}>
          Confirm
          {(isSubmitting && !disableButton) && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
        </StyledButton>
      </Box>
    )
  }

  return (
    <WrapperWithSection>
      <Grid container justify="center">
        <Grid item xs={12} md={10} lg={8}>
          {renderInstallationLayout()}
        </Grid>
      </Grid>
    </WrapperWithSection>
  )
}

export default RescheduleInstallation
