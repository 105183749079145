import React, {
  useState,
  useEffect,
} from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import classes from './USPItem.styles'
import { sanitiseHtml } from '../../utils/sanitise'

export interface USP {
  readonly icon: string;
  readonly body: string;
}

const fetchSvgData = async (iconLink: string) => {
  const res = await fetch(iconLink)
  const svgData = await res.text()
  return svgData
}

function USPItem({ usp }: {usp: USP}) {
  const [
    svgData,
    setSvgData,
  ] = useState<string>()

  useEffect(() => {
    async function getSvgString() {
      const svg = await fetchSvgData(usp.icon)
      if (svg.includes('id="clip-path"')) {
        // Create unqiue clip-path IDs for Adobe exported SVGs
        const uniqueSvg = svg
          .replace(/id="clip-path/g, `id="clip-path-${usp.body}`)
          .replace(/url\(#clip-path/g, `url(#clip-path-${usp.body}`)
        setSvgData(uniqueSvg)
      } else {
        setSvgData(svg)
      }
    }

    getSvgString()
  }, [usp])

  return (
    <section className={classes.item}>
      <Grid item xs={12} className={classes.imageContainer}>
        {/* Sanitized SVG content */}
        {svgData && <div dangerouslySetInnerHTML={{ __html: sanitiseHtml(svgData) }}/>}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{usp.body}</Typography>
      </Grid>
    </section>
  )
}

export default USPItem
