/* eslint complexity: ["error", 100] */
import React from 'react'
import {
  getDiscountPercent,
  getDiscountPrice, getStandardPrice,
} from '../../utils/getProductDetails'
import {
  Box, PropTypes, Typography,
} from '@material-ui/core'
import classesOld from './Packages.styles'
import { Variant } from '@material-ui/core/styles/createTypography'
import { ProductProps } from '../Products/Product'
import { PackageProps } from './Package'
import { cx } from 'linaria'

interface PriceProps {
  product: PackageProps | ProductProps;
  showTerm?: boolean;
  variant?: Variant | 'inherit' | 'huge';
  subtitleVariant?: Variant | 'inherit';
  color?: 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  align?: PropTypes.Alignment;
  term?: number;
  discount_full?: boolean;
  price?: string;
  old_price?: string;
  show_free_months?: boolean;
  hide_strikethrough?: boolean;
  price_guidance?: string;
  after_price_copy?: string;
  is_affiliate_package?: boolean;
}

export const getPrice = (price: string, full?: boolean) => {
  if (full) {
    return '£' + price
  }

  return '£' + price?.split('.')[0]
}

export const getDecimals = (price: string) => {
  const decimals = price?.split('.')[1]
  if (decimals && decimals !== '00') {
    return '.' + decimals
  }

  return null
}

export const flexAlign = (align: PropTypes.Alignment) => {
  switch (align) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    default:
      return 'center'
  }
}

const Price = ({
  product,
  showTerm,
  variant = 'h2',
  subtitleVariant = 'caption',
  color = 'secondary',
  align = 'center',
  term,
  discount_full,
  show_free_months,
  price,
  old_price,
  hide_strikethrough,
  price_guidance,
  after_price_copy,
  is_affiliate_package = false,
}: PriceProps) => {
  const standardPrice = getStandardPrice(product)
  const discount = getDiscountPrice(product)
  const discount_percent = getDiscountPercent(product)
  const priceGuidance = price_guidance
  const packagePrice = getPrice(getStandardPrice(product))
  const priceDecimals = getDecimals((price || discount) && !show_free_months ? (price || discount) : standardPrice)

  const setVariant = (variant: Variant | 'inherit' | 'huge') => {
    if (variant === 'huge') {
      return 'h1'
    }

    return variant
  }

  return (
    <>
      <Box display="flex" justifyContent={flexAlign(align)}>
        {(old_price || Number(discount) !== 0) && !hide_strikethrough &&
          <Typography
            component="span"
            variant={setVariant(variant)}
            color={color === 'error' ? 'error' : 'primary'}
            className={cx(classesOld.oldPrice, variant === 'huge' && classesOld.huge)}
          >
            { getPrice(old_price ? old_price : standardPrice) }
          </Typography>}
        {after_price_copy &&
        <Box id={`strikethrough_price_${product.productOfferingId}`} display="flex" alignItems="center" pr={1}>
          <Typography
            variant="overline" color="textPrimary" style={{
              textDecoration: 'line-through',
              fontSize: is_affiliate_package ? '25px' : '20px',
            }}
          >{packagePrice}
          </Typography>
        </Box>}
        <Box display="flex" alignItems="baseline" ml={(old_price || Number(discount) !== 0) && !hide_strikethrough && 1}>
          <Typography
            component="span"
            variant={setVariant(variant)}
            color={color}
            className={variant === 'huge' ? classesOld.huge : undefined}
          >
            { getPrice((price || discount) && !show_free_months ? (price || discount) : standardPrice) }
          </Typography>
          <Box ml={0.5} className="right" position="relative">
            {priceDecimals &&
              <Typography
                variant="h5"
                color={color}
                className={classesOld.price.decimals}
              >
                {priceDecimals}
              </Typography>}
            <Typography variant="overline" color="textPrimary" className={classesOld.price.fraction}>{ priceGuidance }</Typography>
          </Box>
        </Box>
      </Box>
      {(discount_full || show_free_months) && discount_percent && term &&
        <Box mt={1}>
          <Typography align={align} variant="body2" color="error">
            {discount_full && !show_free_months &&
              <>For {discount_percent.amount} months then £{ price } until month { term }</>}
            {show_free_months &&
              <>{ discount_percent.amount } months free</>}
          </Typography>
        </Box>}
      {after_price_copy &&
        <Typography variant="overline" color="textPrimary" component="div" align={align}>
          then {packagePrice}{after_price_copy}
        </Typography>}
      {showTerm &&
        <Typography className={classesOld.priceLabel} component="div" align={align} variant={subtitleVariant} color="primary">{term}-month contract</Typography>}
    </>
  )
}

export default Price
