import * as React from 'react'
import {
  Card, CardContent, CardMedia, Box, Typography,
} from '@material-ui/core'
import classes from './Tile.styles'
import { Author } from '../../../utils/blog'
import { formatDate } from '../../../utils'

interface TileProps {
  featured_image: string;
  title?: string;
  children: React.ReactNode;
  date?: string;
  author?: Author;
  featured_image_alt?: string;
}

export default function Tile({
  featured_image,
  children,
  title,
  date,
  author,
  featured_image_alt,
}: TileProps) {
  const isBody1Variant = (child: React.ReactNode) => {
    if (!child || typeof child !== 'object') {
      return false
    }

    const element = child as React.ReactElement<any>
    return (
      element.props &&
      element.props.variant === 'body1'
    )
  }

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text
    }

    const lastSpaceIndex = text.lastIndexOf(' ', maxLength)
    return `${text.slice(0, lastSpaceIndex)}...`
  }

  return (
    <Card style={{ maxWidth: 345 }} className={classes.card}>
      <Box style={{
        position: 'relative',
        width: '100%',
        height: '150px',
        borderRadius: '12px',
      }}
      >
        <CardMedia
          component="img"
          image={featured_image}
          className={classes.image}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '20px',
          }}
          alt={featured_image_alt || undefined}
        />
      </Box>
      <CardContent title={title} className={classes.text}>
        <Typography variant="caption" className={classes.date}>
          {author?.first_name && author.first_name}{' '}
          {author?.last_name && author.last_name}{' '} -
          {' '}{date && formatDate(date)}
        </Typography>
        {React.Children.map(children, child => {
          if (isBody1Variant(child)) {
            const element = child as React.ReactElement<any>
            return React.cloneElement(element, {
              children: typeof element.props.children === 'string' ?
                truncateText(element.props.children, 150) :
                element.props.children,
            })
          }

          return child
        })}
      </CardContent>
    </Card>
  )
}
