import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import {
  Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Link, Radio, RadioGroup, Typography,
  TextField,
  PopperProps,
  Popper,
} from '@material-ui/core'
import {
  Info,
} from '@material-ui/icons'
import { cx } from 'linaria'
import {
  Controller, FormContext, useForm,
} from 'react-hook-form'
import { Container } from 'next/app'
import {
  OrderOptions, useOrderContext,
} from '../../../OrderContext'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'
import { formatInstallationAddress } from '../InstallationSection/InstallationSection'
import TextInput from '../../../../TextInput/TextInput'
import {
  handleCopyPasteEvents, handleKeyDown, handlePhoneChange, nameRegex, phoneRegex, specialCharactersSanitizer,
} from '../../../../../utils'
import classes from './SwitchingSection.styles'
import { ChangeCollapsible } from '../CheckoutStep'
import createCRMApiClient from '../../../../../api/CRMApi'
import logger from '../../../../../utils/logger'
import { OTSFormProps } from '../../../../../api/OTSForm'
import { checkIfVasExists, checkIfVoiceExists, checkIfWierExists } from '../../../../Bundles/Bundle'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import { useRouter } from 'next/router'
import { ProductToCartRequest } from '../../../../../api/Cart'
import ModalBox from '../../../../ModalBox/ModalBox'
import StyledButton from '../../../../StyledButton/StyledButton'
import capitalize from 'lodash.capitalize'
import { Autocomplete } from '@material-ui/lab'
import { StepType } from '../../../../../utils/commonEnums'

export interface SwitchingDetailsProps {
  lastNameOnCurrentProviderBill: string;
  provider: {
    name: string;
    id: string;
  };
  phone: boolean;
  landlineNumber: string;
  provideNewNumberOption: string;
}

enum ProvideNewNumberOption {
  YES = 'yes',
  NO = 'no',
}

interface ToggleLinkProps {
  show: boolean;
  onClick: () => void;
}

export const SwitchingSection = ({
  changeCollapsible, backUrl,
}: { changeCollapsible: ChangeCollapsible; backUrl: string}) => {
  const router = useRouter()
  const currentUrl = router.asPath
  const {
    options, setOptions,
  } = useOrderContext()
  const { premise } = useContext(PremiseContext)

  const {
    data, voice, bundle,
  } = options
  const address = data.address || premise?.address
  const lastName = options.data.lastName
  const bundleWithVoice = checkIfVoiceExists(bundle)

  const methods = useForm<SwitchingDetailsProps>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const {
    handleSubmit, control, watch, formState, setValue, register,
  } = methods

  const { isSubmitting } = formState
  const watchProvider = watch('provider')

  const [
    providers,
    setProviders,
  ] = useState<Array<{ id: string; name: string }>>([])

  const [
    selectedProvider,
    setSelectedProvider,
  ] = useState<{ name: string; id: string } | null>(null)
  const [
    providerError,
    setProviderError,
  ] = useState<boolean>(false)
  const [
    name,
    setName,
  ] = useState<string>(lastName)
  const [
    showMoreText,
    setShowMoreText,
  ] = useState<boolean>(false)
  const [
    switchPhone,
    setSwitchPhone,
  ] = useState<boolean>(false)
  const [
    modalVisible,
    setModalVisible,
  ] = useState<boolean>(false)
  const [
    modalAddressVisible,
    setModalAddressVisible,
  ] = useState<boolean>(false)
  const [
    modalNameVisible,
    setModalNameVisible,
  ] = useState<boolean>(false)
  const [
    provideNewNumberOption,
    setProvideNewNumberOption,
  ] = useState<ProvideNewNumberOption>(ProvideNewNumberOption.YES)
  const [
    inputValue,
    setInputValue,
  ] = useState('')
  const [
    open,
    setOpen,
  ] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const selectedProviders = [
    'Sky',
    'Plusnet',
    'Virgin Media Limited',
    'EE',
    'BT',
    'TalkTalk Telecom Group Limited',
    'NOW',
    'Hyperoptic Limited',
    'Vodafone Ltd',
    'Three UK',
    'Hey! Broadband',
    'G.Network Communications Limited',
    'Utility Warehouse',
    'Zen Internet',
  ]

  useEffect(() => {
    getOTSList()
  }, [options])

  useEffect(() => {
    setName(lastName)
  }, [lastName])

  useEffect(() => {
    if (currentUrl.includes('order/checkout/switching')) {
      setOptions({
        ...options,
        OTSPhone: false,
      })
      setSwitchPhone(false)
      setValue('phone', false)
    }
  }, [currentUrl])

  useEffect(() => {
    setProviderError(false)
    if (watchProvider?.name) {
      marketingEvents.simpleEvent('OTS_provider_selected', options)
    }
  }, [watchProvider])
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [open])
  // Function to retrieve OTS list
  const getOTSList = () => {
    const cachedOTSList = localStorage.getItem('OTSList')
    if (cachedOTSList) {
      const cachedOTSListParsed = JSON.parse(cachedOTSList)[0].identity

      // Filter out "Community Fibre Ltd"
      const filteredProviders = cachedOTSListParsed.filter((provider: { name: string; id: string }) => provider.name !== 'Community Fibre Ltd')

      // Sort the filtered providers by name
      const sortedProviders = filteredProviders.sort((a: { name: string; id: string }, b: { name: string; id: string }) => {
        return a.name.localeCompare(b.name)
      })

      setProviders(sortedProviders)
    }
  }

  // Toggle Read more/Read less text
  const toggleText = () => {
    setShowMoreText(prev => !prev)
    marketingEvents.simpleEvent('OTS_porting_readMore_click', options)
  }
 const bundleWithVoiceOnly = bundle && checkIfVoiceExists(bundle) && !checkIfWierExists(bundle) && !checkIfVasExists(bundle)
  const ToggleLink: React.FC<ToggleLinkProps> = ({
    show, onClick,
  }) => (
    <Link
      onClick={onClick}
      color="secondary"
      underline="always"
      variant="overline"
      className={classes.boldText}
    >
      {show ? 'Read less' : 'Read more'}
    </Link>
  )

  // Checkbox for switching phone service
  const handlePhoneCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSwitchPhone(isChecked)
    setValue('phone', isChecked)

    if (isChecked) {
      // Reset radio option to the initial value when Phone is selected
      setProvideNewNumberOption(ProvideNewNumberOption.YES)
      setValue('provideNewNumberOption', ProvideNewNumberOption.YES)
    } else {
      // Reset the landline number field and hide it when the phone checkbox is unchecked
      setValue('provideNewNumberOption', event.target.value)
    }

    setOptions({
      ...options,
      OTSPhone: isChecked,
    })
    marketingEvents.simpleEvent(`OTS_Phone_selected_${isChecked}`, options)
  }

  // Radio button change handler
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('provideNewNumberOption', event.target.value)
    setProvideNewNumberOption(event.target.value as ProvideNewNumberOption)
    marketingEvents.simpleEvent(`OTS_Phone_porting_${event.target.value === 'yes' ? 'no' : 'yes'}`, options)
  }

  // Handle name change and sanitize special characters
  const handleNameChange = (event: any) => {
    specialCharactersSanitizer(event)
    setName(event.target.value)
  }

  // Validate input and trigger marketing event if valid
  const handleInputBlur = (eventName: string, options: OrderOptions, validator: (value: string) => boolean) => (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value
    let isValid = false

    if (value && value.trim().length > 0) {
      isValid = validator(value)
    }

    if (isValid) {
      // Field is valid and complete, trigger GA event
      marketingEvents.simpleEvent(eventName, options)
    }
  }

  // Generic function to toggle modals
  const toggleModal = (modalSetter: React.Dispatch<React.SetStateAction<boolean>>, eventName?: string) => {
    modalSetter((prevState) => !prevState)
    if (eventName && !modalVisible) {
      marketingEvents.simpleEvent(eventName, options)
    }
  }

  // Handle form submission
  const onSubmit = handleSubmit(async (data: SwitchingDetailsProps) => {
    const client = createCRMApiClient()

    const {
      provider,
      lastNameOnCurrentProviderBill,
      phone,
      landlineNumber,
      provideNewNumberOption,
    } = data
    if (providerError || !provider || !provider.name) {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      marketingEvents.simpleEvent('OTS_current_provider_error_not_selected', options)
      return
    }

    marketingEvents.simpleEvent('OTS_details_submit', options)

    // <Porting number and current provider >
    // We should remove and add voice again.
    // Because we have to send somehow portedNumber and provider. But HOBS don't want to change their API's.
    if (phone && landlineNumber) {
      // Remove existing products from the cart
      const productsToRemove = [
      options.voice?.productOfferingId,
      options.bundle?.productOfferingId,
      options.tv?.productOfferingId,
      options.vas?.productOfferingId,
      ].filter(Boolean)

      for (const productId of productsToRemove) {
      await client.cart.removeProductFromCart(
        options.cartInstanceIdentifier,
        productId!,
        options.channel,
      )
      }

      const reqObj: ProductToCartRequest = {
      channel: options.channel,
      cartInstanceIdentifier: options.cartInstanceIdentifier,
      phoneNumber: landlineNumber,
      provider: provider.name,
      isTVProduct: !!options.tv?.productOfferingId,
      isVASProduct: !!options.vas?.productOfferingId,
      isVoiceProduct: !!options.voice?.productOfferingId,
      isBundledProduct: !!options.bundle?.productOfferingId,
      bundleWithVoiceOnly,
      }

      // Add pre-configured products to the cart
      const productsToAdd = [
      options.voice,
      options.bundle,
      options.tv,
      options.vas,
      ].filter(Boolean)

      for (const product of productsToAdd) {
      await client.cart.addPreConfiguredProductToCart(
        product!,
        reqObj,
      )
      }
    }
    // </Porting number and current provider>

    // Provider object already contains the id
    const providerId = provider.id

    function splitAddressParts(addressString: string): string[] {
      return addressString.trim()
        .split(',')
        .map(part => part.trim()
          .replace(/\w+/g, capitalize))
    }

    function splitAddress(addressString: string, providerId: string): string[] {
      let sliceCount = -1
      if (providerId === 'RVQD') { // If provider is Virgin Media then Remove London from Address Lines
        sliceCount = -2
      }

      const parts = splitAddressParts(addressString)
      return parts.slice(0, sliceCount)
        .filter(part => part.length > 0)
    }

    function getTown(addressString: string): string {
      const parts = splitAddressParts(addressString)
      return parts.length > 1 ? parts[parts.length - 2] : ''
    }

    function getPostcode(addressString: string): string {
      const parts = splitAddressParts(addressString)
      return parts.length > 0 ? parts[parts.length - 1].toUpperCase() : ''
    }

    let postTown = getTown(premise?.address || '')
    if (/^(?:E|EC|N|NW|SE|SW|W|WC)/.test(premise?.postcode || '') && providerId === 'RVQD') {
      postTown = 'London'
    }

    const params: OTSFormProps = {
      lastName: lastNameOnCurrentProviderBill,
      addressLines: premise ? [
        ...splitAddress(premise?.address || '', providerId),
premise?.borough || '',
      ] : [''],
      postTown,
      postCode: getPostcode(premise?.address || ''),
      partnerID: providerId,
      uprn: premise?.uprn || '',
      phone,
      landlineNumber,
      provideNewNumberOption: provideNewNumberOption === ProvideNewNumberOption.YES,
    }

    const result: any = await client.otsform.post(params)

    if (result && 'error' in result) {
      setOptions({
        ...options,
        oneTouchSwitching: true,
        OTSOptOut: false,
        OTSMatchRequestError: true,
      })
      logger.error(result.message, result.error)
    } else {
      setOptions({
        ...options,
        oneTouchSwitching: true,
        OTSOptOut: false,
        OTSMatchRequestError: false,
        OTSFormProps: params,
      })
    }

    changeCollapsible(StepType.checkoutInstallation)
  })

  // Opt-out of switching
  const optOut = () => {
    setOptions({
      ...options,
      oneTouchSwitching: false,
      switchingConfirmation: false,
      switchingConfirmationError: false,
      OTSMatchRequestError: false,
      OTSOptOut: true,
    })
    marketingEvents.simpleEvent('OTS_optOut_clicked', options)
    changeCollapsible(StepType.checkoutInstallation)
  }

  const scrollSearchbarIntoView = () => {
    marketingEvents.simpleEvent('OTS_provider_list_open', options)

    if (!window) {
      return
    }

    const documentWidth: number = window.innerWidth
    if (documentWidth < 700) {
      const searchBar = document.querySelector('#someId')
      const scrollToSearchBar = () => {
        searchBar && searchBar.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }

      setTimeout(scrollToSearchBar, 250)
    }
  }

  const DropdownPopper = (props: PopperProps) => {
    return (
      <Popper
        {...props}
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            options: {
              boundary: 'viewport',
            },
          },
          offset: {
            options: {
              offset: [
                0,
                8,
              ],
            },
          },
        }}
        container={document.querySelector('#inputContainer')}
        data-test-id="autoCompleteMenu"
      />
    )
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  const sortedProviders = [...providers].sort((a, b) => {
    const isASelected = selectedProviders.includes(a.name)
    const isBSelected = selectedProviders.includes(b.name)

    if (isASelected && !isBSelected) {
      return -1
    }

    if (!isASelected && isBSelected) {
      return 1
    }

    return 0
  })
  const handleProviderInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const matchedProvider = sortedProviders.find(
      (option) => option.name.toLowerCase() === event.target.value.toLowerCase(),
    )
    if (matchedProvider) {
      control.setValue('provider', matchedProvider)
      setInputValue(matchedProvider.name)
      setSelectedProvider(matchedProvider)
      setOptions((prev) => ({
        ...prev,
        selectedProvider: matchedProvider,
      }))
      setProviderError(false)
    } else {
      setProviderError(true)
    }
  }

  return (
    <FormContext {...methods}>
      <Container>
        <form onSubmit={onSubmit}>
          <Box className={classes.wrapper}>
            <Grid container justify="space-between" id="someId">
              <Grid container direction="column" justify="flex-start">
                <Box pb={1}>
                  <Link
                    onClick={() => toggleModal(setModalAddressVisible, 'OTS_Address_info')}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '-25px',
                    }}
                    color="secondary"
                  >
                    <Info style={{ marginLeft: 'auto' }}/>
                  </Link>
                  <ModalBox visible={modalAddressVisible} sync={() => toggleModal(setModalAddressVisible)}>
                    <Box pb={2}>
                      <Typography variant="h4" component="p" color="primary">
                        Address with current provider
                      </Typography>
                    </Box>
                    <Box pb={6}>
                      <Typography variant="body1" color="primary">
                        The address you are signing up with must match with the address on your current provider&apos;s bill.
                      </Typography>
                    </Box>
                  </ModalBox>
                  <Typography variant="body1" color="primary" className={classes.boldText}>Address with current provider</Typography>
                </Box>
                <Box pb={2}>
                  <Typography variant="body1" color="textPrimary">{address && formatInstallationAddress(address)}</Typography>
                </Box>
              </Grid>
              <Grid container sm={7} className={classes.selectContainer}>
                <Typography className={cx(classes.inputLabel, providerError && classes.errorLabel)}>Current provider*</Typography>
                <Grid direction="row" container className={classes.selectContainer} id="inputContainer" ref={dropdownRef}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="provider"
                    onChange={([
                      , obj,
                    ]) => obj}
                    as={
                      <Autocomplete
                        id="providerInput"
                        options={sortedProviders}
                        getOptionLabel={(option: any) => option.name}
                        filterOptions={(options, state) => {
                          const normalize = (str: string) => str.toLowerCase()
                            .replace(/[.\s]/g, '')
                          const inputValue = normalize(state.inputValue)
                          return options.filter((option) =>
                            normalize(option.name)
                              .includes(inputValue),
                          )
                        }}
                        className={classes.autocompleteRoot}
                        PopperComponent={DropdownPopper}
                        disableClearable
                        getOptionSelected={(obj, newval) =>
                          obj.name.toLowerCase()
                            .replace(/[.\s]/g, '') ===
                        newval.name.toLowerCase()
                          .replace(/[.\s]/g, '')}
                        open={open}
                        classes={{
                          popper: classes.popper,
                        }}
                        onOpen={() => {
                          setOpen(true)
                          marketingEvents.simpleEvent('OTS_provider_list_open', options)
                        }}
                        value={selectedProvider?.name}
                        onClose={() => setOpen(false)}
                        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                        onFocus={scrollSearchbarIntoView}
                        renderOption={(option: any) => (
                          <div className={cx(classes.option)}>{option.name}</div>
                        )}
                        freeSolo
                        groupBy={(option: any) =>
                          selectedProviders.includes(option.name) ?
                            'Popular Providers' :
                            'Other Providers'}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            className={cx(
                              classes.selectClass,
                            watchProvider?.name && classes.activeSelect,
                            )}
                            variant="outlined"
                            value={inputValue}
                            placeholder="Select your current provider"
                            onChange={(event) => {
                              const normalize = (str: string) => str.toLowerCase()
                                .replace(/[.\s]/g, '')
                              const isValid = sortedProviders.some(
                                (option) => normalize(option.name) === normalize(event.target.value),
                              )
                              setProviderError(!isValid)
                              handleProviderInputChange(event)
                            }}
                            inputRef={register({ required: true })}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            required
                          />
                        )}
                        onChange={(_: any, selectedValue: any) => {
                          if (selectedValue) {
                            control.setValue('provider', selectedValue)
                            setSelectedProvider(selectedValue)
                            setOptions((prev) => ({
                              ...prev,
                              selectedProvider: selectedValue,
                            }))
                          }
                        }}
                      />

                    }
                  />
                </Grid>
                {providerError &&
                <Typography variant="caption" color="error">
                  Please select one of the providers from the list
                </Typography>}
              </Grid>
              <Grid container sm={12}>
                <Box pt={1} pb={2}>
                  <Link
                    onClick={() => toggleModal(setModalVisible, 'OTS_Provider_not_in_list')}
                    underline="always"
                    style={{
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                    }}
                    color="secondary"
                  >
                    Can&apos;t find your provider?
                  </Link>
                  <ModalBox visible={modalVisible} sync={() => toggleModal(setModalVisible)}>
                    <Box pb={2}>
                      <Typography variant="h4" component="p" color="primary">
                        Your current provider is not on the list
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="primary">
                      If you can&apos;t find your provider in the list, it means they are not using the Switching system and we cannot continue with your match request. You can continue with your order without switching.
                    </Typography>
                    <Box display="flex" justifyContent="center" pb={6} pt={3}>
                      <StyledButton
                        color="primary"
                        handleClick={() => {
                          toggleModal(setModalVisible)
                          optOut()
                        }}
                      >
                        <Typography noWrap>Continue without switching</Typography>
                      </StyledButton>
                    </Box>
                  </ModalBox>
                </Box>
              </Grid>
              <Box mt={4} mb={2}>
                <Link
                  onClick={() => toggleModal(setModalNameVisible, 'OTS_Surname_info')}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '-45px',
                  }}
                  color="secondary"
                >
                  <Info style={{ marginLeft: 'auto' }}/>
                </Link>
                <ModalBox visible={modalNameVisible} sync={() => toggleModal(setModalNameVisible)}>
                  <Box pb={2}>
                    <Typography
                      variant="h4" component="p" color="primary"
                    >
                      Last name on current provider bill
                    </Typography>
                  </Box>
                  <Box pb={6}>
                    <Typography variant="body1" color="primary">
                      You can change this at a later date, but for now, you&apos;ll need to use the same last name that&apos;s on the bill with your current provider.
                    </Typography>
                  </Box>
                </ModalBox>
                <Box
                  mt={3}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 'auto',
                    width: '95%',
                  }}
                >
                  <TextInput
                    gridWidth={12}
                    label="Last name on current provider bill"
                    placeholder="Last name"
                    handleChange={handleNameChange}
                    inputProps={{
                      onBlur: handleInputBlur('OTS_lastName_with_current_provider_completed', options, (value) => nameRegex.test(value)),
                    }}
                    value={name}
                    required
                    validation={{
                      pattern: {
                        value: nameRegex,
                        message: 'Please use a valid name. Insert alphabetic characters only',
                      },
                      minLength: {
                        value: 1,
                        message: 'Minimum length is 3 characters',
                      },
                      maxLength: {
                        value: 25,
                        message: 'Maximum length is 25 characters',
                      },
                      validate: (value) => value.trim().length > 0 || 'Last Name is required and cannot be just spaces',
                    }}
                  />
                </Box>
                {(voice || bundleWithVoice) &&
                <>
                  <Box display="flex" flexDirection="column" mt={3}>
                    <FormGroup>
                      <Typography variant="body1" color="primary" className={classes.inputLabel}>
                        We will be switching your broadband, do you also want to switch your phone service?
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={false}
                        name="phone"
                        className={classes.checkbox}
                        as={
                          <FormControlLabel
                            label="Phone"
                            control={
                              <Checkbox
                                color="primary"
                                checked={switchPhone}
                                onChange={handlePhoneCheckbox}
                              />
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </Box>
                  {switchPhone &&
                  <>
                    <Grid container justify="center">
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="provideNewNumberOption"
                        defaultValue={ProvideNewNumberOption.YES}
                        as={
                          <RadioGroup
                            name="provideNewNumberOption"
                            value={provideNewNumberOption}
                            defaultValue={ProvideNewNumberOption.YES}
                          >
                            <Grid container spacing={2} className={classes.radioContainer}>
                              <Grid xs md={12} item>
                                <FormControlLabel
                                  className={classes.radio}
                                  value={ProvideNewNumberOption.YES}
                                  defaultChecked
                                  control={<Radio required color="primary"/>}
                                  onChange={handleRadioChange}
                                  label={<Typography variant="h5" color="primary">I want a new number</Typography>}
                                />
                                <Typography variant="body2" color="textPrimary" className={classes.radioUnderLabel}>
                                  We&apos;ll confirm this by email. This will be after installation. No need to remain with your current provider.
                                </Typography>
                              </Grid>
                              <Grid xs md={12} item>
                                <FormControlLabel
                                  className={classes.radio}
                                  value={ProvideNewNumberOption.NO}
                                  control={<Radio required color="primary"/>}
                                  onChange={handleRadioChange}
                                  label={<Typography variant="h5" color="primary">I want to keep this number</Typography>}
                                />
                                <Typography variant="body2" color="textPrimary" className={classes.radioUnderLabel}>
                                  Porting your telephone number will take 12 working days after your broadband has been successfully installed.
                                  {showMoreText && (
                                    <Typography variant="body2" component="span" color="textPrimary">
                                      {' '}
                                      Whilst your existing phone number is being transferred, you will not be able to make or receive calls from that number. But you will be able to on your temporary number.
                                    </Typography>
                                  )}
                                  {' '}
                                  <ToggleLink show={showMoreText} onClick={toggleText}/>
                                </Typography>
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        }
                      />
                    </Grid>
                    {provideNewNumberOption === ProvideNewNumberOption.NO &&
                    <Box mt={3}>
                      <TextInput
                        label="Current landline number"
                        placeholder="E.g. 08878775858"
                        id="landlineNumber"
                        type="tel"
                        required
                        onCopy={handleCopyPasteEvents}
                        onCut={handleCopyPasteEvents}
                        onPaste={handleCopyPasteEvents}
                        onKeyDown={handleKeyDown}
                        handleChange={handlePhoneChange}
                        inputProps={{
                          maxLength: 11,
                          autoComplete: 'off',
                          onBlur: handleInputBlur('OTS_phone_to_be_ported_completed', options, (value) => value.length === 11 && phoneRegex.test(value)),
                        }}
                        validation={{
                          maxLength: {
                            value: 11,
                            message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                          },
                          minLength: {
                            value: 11,
                            message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                          },
                          pattern: {
                            value: phoneRegex,
                            message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                          },
                        }}
                      />
                    </Box>}
                  </>}
                </>}
                <Typography variant="caption" color="primary" className={classes.fontWeightMedium}>
                  By continuing I give permission to Community Fibre to process my data to complete the switching request with a third party provider.
                </Typography>
              </Box>
              <Box
                className={classes.buttonContainer}
              >
                <StyledButton
                  variant="outlined"
                  color="secondary"
                  className={classes.backButton}
                  handleClick={() => router.push(backUrl, undefined, { shallow: true })}
                >
                  <Typography noWrap>
                    Back
                  </Typography>

                </StyledButton>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  id="step-1"
                  disabled={isSubmitting}
                >
                  <Typography noWrap>Continue</Typography>
                  {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
                </StyledButton>
              </Box>

              <Box mt={3} className={classes.optOut}>
                <Typography variant="body1" color="textPrimary">
                  You can continue your order without our help to switch. By doing this, you agree to cancel your contract with your current provider separately.
                </Typography>
                <StyledButton
                  handleClick={optOut}
                  variant="outlined"
                  color="secondary"
                >
                  <Typography noWrap>Continue without switching</Typography>
                </StyledButton>
              </Box>
            </Grid>
          </Box>
        </form>
      </Container>
    </FormContext>
  )
}
