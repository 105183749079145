import React, { useState, useEffect, useContext } from "react";
import { Hidden, Typography } from "@material-ui/core";
import { useRouter } from "next/router";
import { useOrderContext } from "../NewOrderFlow/OrderContext";
import { useGeneralContext } from "../GeneralContext/GeneralContext";
import { PremiseContext } from "../PremiseTracker/PremiseContext";
import { TechnologyType } from "../../api/Addresses";
import ModalBox from "../ModalBox/ModalBox";
import theme, { COLORS } from "../../styles/theme";
import Wrapper from "../Wrapper/Wrapper";
import { ContractType } from "../../utils/commonEnums";
import { Product } from "../../utils/commonInterfaces";
import ComparisonTableNew from "../ComparisonTableNew/ComparisonTableNew";
import SpeedTabs from "../SpeedTabs/SpeedTabs";
import DesktopComparisonModal from "./DesktopComparisonModal";
import MobileComparisonModal from "./MobileComparisonModal";

export interface ColumnCompany {
  readonly package: string;
  readonly avg_download: string;
  readonly avg_upload: string;
  readonly price_during_contract: string;
  readonly price_after_contract: string;
  readonly set_up_fee: string;
  readonly contract_length: string;
  readonly price_locked: string;
  readonly voice_control: string;
  readonly devices: string;
  readonly channels_included: string;
  readonly hours_on_demand: string;
  readonly add_on_embedded: string;
  readonly wifi_enabled: string;
  readonly box: string;
  readonly price: string;
}
export interface ColumnTime {
  readonly header: string;
  readonly work: string;
  readonly binge: string;
  readonly game: string;
}

export interface TextWithImageProps {
  readonly title: string;
  readonly body: string;
  readonly image: string;
  readonly imageWidth?: number;
  readonly imageHeight?: number;
  readonly button: {
    readonly text: string;
    readonly url: string;
  };
}
export interface SpeedColumn {
  readonly company_name: string;
  readonly company_image: string;
  readonly package: string;
  readonly avg_download: string;
  readonly avg_upload: string;
  readonly monthly_price: string;
  readonly set_up_fee: string;
  readonly contract_length: string;
  readonly price_locked: string;
  readonly total_cost: string;
  readonly total_saving: string;
}
export interface SpeedCompany {
  readonly tab_title: string;
  readonly contract_length: string;
  readonly speed_comparison: SpeedColumn[];
  readonly text_with_image?: TextWithImageProps;
}

export interface ComparisonModalProps {
  readonly anchor_id: string;
  readonly columns_company: ColumnCompany[];
  readonly columns_time: ColumnTime[];
  readonly tabs: SpeedCompany[];
  readonly last_updated: string;
  readonly product_id?: string;
  readonly product?: Product;
  readonly row_names: {};
  readonly modal_display: boolean;
  readonly tv_columns_company: ColumnCompany[];
  readonly button: {
    readonly modal?: boolean;
    readonly url: string;
    readonly title: string;
    readonly text: string;
  };
  readonly legal_text: string;
  readonly legal_logo: string;
  readonly products?: Product[];
  readonly title?: string;
  readonly contract_length?: ContractType.ANNUAL | ContractType.ANNUAL_24;
  readonly sync_with_packages?: boolean;
  comparison_table_file?: string;
  text_with_image?: any;
  name?: string;
}

const temporaryTermConversion = (term: number) => {
  switch (term) {
    case 6:
      return ContractType.ANNUAL_6;
    case 12:
      return ContractType.ANNUAL;
    default:
      return ContractType.ANNUAL_24;
  }
};

export default function ComparisonModal({
  anchor_id,
  name,
  last_updated,
  columns_company,
  columns_time,
  row_names,
  modal_display,
  tv_columns_company,
  tabs,
  button,
  legal_text,
  legal_logo,
  title,
  contract_length,
  sync_with_packages,
  comparison_table_file,
  text_with_image
}: ComparisonModalProps) {
  const { generalOptions } = useGeneralContext();
  const { activeSpeedTabMobile } = generalOptions;
  const contractLengthCaps = contract_length?.toUpperCase() as ContractType;

  const [open, setOpen] = useState(false);
  const router = useRouter();

  const [filteredTabs, setFilteredTabs] = useState<SpeedCompany[]>();
  const [activeSpeedTab, setActiveSpeedTab] = useState<number>(1);
  const [activeContractLength, setActiveContractLength] = useState<ContractType>(
    contractLengthCaps || ContractType.ANNUAL_24
  );

  const { options } = useOrderContext();
  const { broadband, packagesTerm } = options;

  useEffect(() => {
    const residentialTerm = packagesTerm?.residential;
    if (sync_with_packages && residentialTerm) {
      const activeTerm = temporaryTermConversion(residentialTerm);

      if (activeTerm) {
        setActiveContractLength(activeTerm);
      }
    }
  }, [packagesTerm, sync_with_packages]);

  const { premise } = useContext(PremiseContext);

  useEffect(() => {
    const modalRouteChecker = anchor_id ? anchor_id : "#comparison-modal";
    if (router.asPath.includes(modalRouteChecker) || !modal_display) {
      setOpen(true);

      const filterContractLengthTabs = () => {
        const isEligibleFor3Giga =
          premise?.technology === TechnologyType.XGSPON ||
          premise?.technology === TechnologyType.COMBO;
        const containsActiveTerm = tabs.some(
          (tab) => tab?.contract_length?.toUpperCase() === activeContractLength
        );

        const contractLengthTabs =
          tabs &&
          containsActiveTerm &&
          tabs.filter((tab) => {
            if (router.pathname.includes("order")) {
              const tabIs3Giga =
                tab.text_with_image &&
                Object.keys(tab.text_with_image).length > 0;

              if (tabIs3Giga && !isEligibleFor3Giga) {
                return null;
              }
            }

            return tab.contract_length.toUpperCase() === activeContractLength;
          });

        const tabsFallback =
          tabs &&
          tabs.filter(
            (tab) => tabs[0] && tabs[0].contract_length === tab.contract_length
          );
        setFilteredTabs(contractLengthTabs || tabsFallback);
      };

      const saveActiveSpeedTab = () => {
        const hasActiveSpeedTabMobile = activeSpeedTabMobile !== undefined;
        const selectedBroadbandOnDesktop =
          broadband?.downloadSpeedDescription && !hasActiveSpeedTabMobile;

        if (selectedBroadbandOnDesktop && filteredTabs) {
          filteredTabs.forEach((tab, index) => {
            if (
              tab.tab_title.toLowerCase() ===
              broadband?.downloadSpeedDescription?.toLowerCase()
            ) {
              setActiveSpeedTab(index);
            }
          });
        } else {
          const selectedOrDefaultSpeedTab = hasActiveSpeedTabMobile
            ? activeSpeedTabMobile
            : 1;
          setActiveSpeedTab(Number(selectedOrDefaultSpeedTab));
        }
      };

      filterContractLengthTabs();
      saveActiveSpeedTab();
    }
  }, [
    router.asPath,
    router.pathname,
    broadband,
    activeSpeedTabMobile,
    premise,
    activeContractLength,
    tabs,
    anchor_id,
    modal_display,
  ]);

  const speedComparisonTab =
    filteredTabs &&
    filteredTabs.length > 0 &&
    filteredTabs[Number(activeSpeedTab)]?.speed_comparison;

  const createTableRows = () => {
    let allColumns = [{}];
    if (tv_columns_company && tv_columns_company.length > 0) {
      allColumns = [...tv_columns_company, ...columns_time];
    } else if (columns_company) {
      allColumns = [...columns_company, ...(speedComparisonTab || [])];
    }

    return allColumns.reduce(
      (
        rows: { [key: string]: string[] },
        column: ColumnCompany | ColumnTime | SpeedCompany
      ) => {
        Object.entries(column).forEach(([key, value]) => {
          if (key === "name" || key === "meta") {
            return rows;
          }

          if (rows[String(key)]) {
            rows[String(key)].push(value);
          } else {
            rows[String(key)] = [value];
          }

          return rows;
        });

        return rows;
      },
      {}
    );
  };

  const comparisonData = createTableRows();

  const modalProps = {
    name,
    columns: comparisonData,
    last_updated,
    rowNames: row_names,
    button,
    legal: {
      text: legal_text,
      logo: legal_logo,
    },
    text_with_image,
    comparison_table_file,
  };

  const handleTabClick = (tab: number) => {
    setActiveSpeedTab(tab);
  };

  const speedTabs =
    filteredTabs &&
    filteredTabs.map((tab) => {
      const [speed_number, speed_text] = tab.tab_title.split(" ");

      return {
        speed_number,
        speed_text,
      };
    });

  const tabsProps: any = {
    hidden: false,
    tabs: speedTabs,
    activeSpeedTab,
    handleClick: (tab: number) => handleTabClick(tab),
    modal_display,
    mb: 3,
  };

  function handleClose(): void {
    setOpen(false);
    const modalRouteChecker = anchor_id ? anchor_id : "#comparison-modal";
    if (router.asPath.includes(modalRouteChecker)) {
      router.push(router.pathname, undefined, { shallow: true });
    }
  }
  return modal_display ? (
    <>
      <Hidden smDown>
        <ModalBox
          visible={open}
          sync={handleClose}
          aria-labelledby={anchor_id}
          aria-describedby="compare-packages"
          px={0}
          py={4}
          maxWidth="90vw"
          bgcolor={COLORS.gray1}
          id={anchor_id}
        >
          {comparison_table_file ? (
            <>
              {title && (
                <Typography
                  variant="h3"
                  color="primary"
                  align="center"
                  style={{ padding: `${theme.spacing(2)}px` }}
                >
                  {title}
                </Typography>
              )}
              <ComparisonTableNew {...modalProps} />
            </>
          ) : (
            <>
              {speedTabs && speedTabs.length > 0 && <SpeedTabs {...tabsProps} />}
              <DesktopComparisonModal {...modalProps} />
            </>
          )}
        </ModalBox>
      </Hidden>
      <Hidden mdUp>
        <ModalBox
          visible={open}
          sync={handleClose}
          px={0}
          py={5}
          my={0}
          bgcolor={COLORS.gray1}
          fullScreen
          id={anchor_id}
        >
          {comparison_table_file ? (
            <>
              {title && (
                <Typography
                  variant="h3"
                  color="primary"
                  align="center"
                  style={{ padding: `${theme.spacing(2)}px` }}
                >
                  {title}
                </Typography>
              )}
              <ComparisonTableNew {...modalProps} />
            </>
          ) : (
            <>
              {speedTabs && speedTabs.length > 0 && <SpeedTabs {...tabsProps} />}
              <MobileComparisonModal {...modalProps} />
            </>
          )}
        </ModalBox>
      </Hidden>
    </>
  ) : (
    <Wrapper
      verticalPaddings
      gradient
      id={anchor_id}
      container={false}
      horizontalPaddings={false}
    >
      {title && (
        <Typography
          variant="h3"
          color="primary"
          align="center"
          style={{ padding: `${theme.spacing(2)}px` }}
        >
          {title}
        </Typography>
      )}
      <Hidden smDown>
        {comparison_table_file ? (
          <ComparisonTableNew {...modalProps} />
        ) : (
          <>
            {speedTabs && speedTabs.length > 0 && <SpeedTabs {...tabsProps} />}
            <DesktopComparisonModal {...modalProps} />
          </>
        )}
      </Hidden>
      <Hidden mdUp>
        {comparison_table_file ? (
          <ComparisonTableNew {...modalProps} />
        ) : (
          <>
            {speedTabs && speedTabs.length > 0 && <SpeedTabs {...tabsProps} />}
            <MobileComparisonModal {...modalProps} />
          </>
        )}
      </Hidden>
    </Wrapper>
  );
}