import { AxiosInstance } from 'axios'
import {
  DCMSProps, RAFProps,
} from '../components/Packages/Helpers'
import {
  decryptString, getCustomerBillCycleId,
} from '../utils'
import {
  HobsProduct, ProductSpec, ProductSpecCharacteristic,
} from './Packages'
import { ChannelType } from '../utils/commonEnums'

export interface CartCustomerAccountWrapperProps {
  readonly channel: string;
  readonly cartInstanceIdentifier: string;
  readonly customerCategory: string;
  readonly customerSegment: string;
  readonly primaryEmailID: string;
  readonly telephoneNumber: string;
  readonly addressSplited: string[];
  readonly borough: string;
  readonly givenName: string;
  readonly familyName: string;
  readonly accountHolderName: string;
  readonly bankCode: string;
  readonly accountNumber: string;
  readonly sprn: string;
  readonly partnerID: string;
}

export interface ProductToCartRequest {
  channel: string;
  cartInstanceIdentifier?: string;
  phoneNumber?: string;
  provider?: string;
  isTVProduct?: boolean;
  isVASProduct?: boolean;
  isVoiceProduct?: boolean;
  isBundledProduct?: boolean;
  bundleWithVoiceOnly?: boolean;
}

export default function Cart(client: AxiosInstance) {
  async function addPreConfiguredProductToCart(
    product: HobsProduct,
    reqObj: ProductToCartRequest,
  ) {
    try {
      const previousProvider = 'Previous_Provider'
      const portedNumber = 'Number_to_be_ported'
      const productOfferingID = (reqObj?.isVoiceProduct || reqObj?.isBundledProduct) && product?.productSpecID ?
        product?.productSpecID :
        product?.productOfferingId
      const bundleWithVoiceOnly = reqObj?.isBundledProduct && reqObj?.bundleWithVoiceOnly

      // Define a type for our offering ID mapping
      type OfferingIdMap = {
        'Number_to_be_ported': string;
        'Previous_Provider': string;
      }

      // In the getProductSpecCharacteristic function
      const getProductSpecCharacteristic = (
        source: any,
        characteristicName: string,
        fromBundle = false,
      ) => {
        if (fromBundle) {
          const offeringIdMap: OfferingIdMap = {
            Number_to_be_ported: 'Calling_Product_bundle',
            Previous_Provider: 'CallingProductVoice',
          }

          // Type guard to ensure characteristicName is a valid key
          const isValidCharacteristic = (key: string): key is keyof OfferingIdMap => {
            return key in offeringIdMap
          }

          if (!isValidCharacteristic(characteristicName)) {
            return undefined
          }

          const offeringId = offeringIdMap[characteristicName]

          return source?.productOffering
            ?.find((offering: any) => offering.productOfferingId === offeringId)
            ?.productSpec?.[0]
            ?.productSpecCharacteristic
            ?.find((char: any) => char.name === characteristicName)
            ?.productSpecCharID
        }

        return source?.productSpec
          ?.flatMap((spec: ProductSpec) => spec.productSpecCharacteristic)
          .find((specChar: ProductSpecCharacteristic) => specChar.name === characteristicName)
          ?.productSpecCharID
      }

      // Get IDs for voice product
      const productSpecPortID = getProductSpecCharacteristic(product, portedNumber)
      const productSpecProviderID = getProductSpecCharacteristic(product, previousProvider)

      // Get IDs for bundle with voice
      const productSpecPortIDFromBundle = getProductSpecCharacteristic(product, portedNumber, true)
      const productSpecProviderIDFromBundle = getProductSpecCharacteristic(product, previousProvider, true)

      // Base request structure
      const baseRequest = {
        ...(reqObj.cartInstanceIdentifier && { cartInstanceIdentifier: reqObj.cartInstanceIdentifier }),
        qualificationCriteria: {
          channelID: reqObj.channel,
          serviceType: product?.serviceType,
          productType: product?.productType,
        },
        channelID: reqObj.channel,
      }

      // Handle different types of products
      const createVoiceNodeNumber = (id: string, portId: string) => ({
        productOfferingID: id,
        action: 'I',
        quantity: 1,
        productCharacteristicList: [{
          id: portId,
          name: portedNumber,
          valueType: 'Text',
          value: reqObj.phoneNumber,
        }],
      })

      const createVoiceNodeProvider = (providerId: string) => ({
        productOfferingID: 'CallingProductVoice',
        action: 'I',
        quantity: 1,
        productCharacteristicList: [{
          id: providerId,
          name: previousProvider,
          valueType: 'Text',
          value: reqObj.provider,
        }],
      })

      let nodesToConfigure
      if (bundleWithVoiceOnly) {
        // Bundle with voice only configuration
        nodesToConfigure = [
          {
            productOfferingID,
            action: 'I',
            quantity: 1,
          },
          createVoiceNodeNumber('Calling_Product_bundle', productSpecPortIDFromBundle),
          createVoiceNodeProvider(productSpecProviderIDFromBundle),
        ]
      } else if (reqObj?.isVoiceProduct) {
        // Voice configuration
        nodesToConfigure = [
          createVoiceNodeNumber('CallingProduct', productSpecPortID),
          createVoiceNodeProvider(productSpecProviderID),
        ]
      } else {
        // Other products configuration
        nodesToConfigure = [{
          productOfferingID,
          action: 'I',
          quantity: 1,
          productCharacteristicList: [],
        }]
      }

      const params = {
        ...baseRequest,
        requestObject: [{
          rootNode: productOfferingID,
          nodesToConfigure,
        }],
      }

      const { data } = await client.post<{ data: any }>('cart/v1/0.0/addproduct', params)
      return data
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  const addRafToCart = async ({
    appliedProductId,
    appliedReferralCode,
    channel,
    cartInstanceIdentifier,
    product,
  }: RAFProps) => {
    try {
      const decryptedProductId = decryptString(appliedProductId)
      const decryptedReferralCode = decryptString(appliedReferralCode)
      const attrId = product?.productSpec
        ?.find(e => e.productSpecID === decryptedProductId)?.productSpecCharacteristic
        ?.find(e => e.name === 'ReferenceCode')?.productSpecCharID
      const productType = product?.productType
      const requestBody = {
        qualificationCriteria: {
          channelID: channel,
          productType,
        },
        channelID: channel,
        cartInstanceIdentifier,
        requestObject: [{
          rootNode: decryptedProductId.length > 24 ? 'Refer_friend' : decryptedProductId,
          nodesToConfigure: [{
            productOfferingID: decryptedProductId.length > 24 ? 'Refer_friend' : decryptedProductId,
            action: 'I',
            quantity: 1,
            productCharacteristicList: [{
              id: attrId,
              name: 'ReferenceCode',
              valueType: 'Text',
              value: decryptedReferralCode,
            }],
          }],
        }],
      }

      const { data } = await client.post<{ data: any }>('cart/v1/0.0/addproduct', requestBody)

      return data
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  const addDCMSToCart = async ({
    cartInstanceIdentifier,
    channel,
    product,
  }: DCMSProps) => {
    try {
      const productType = product?.productType
      const serviceType = product?.serviceType
      const requestBody = {
        cartInstanceIdentifier,
        qualificationCriteria: {
          channelID: channel,
          serviceType,
          productType,
        },
        channelID: channel,
        requestObject: [{
          rootNode: 'Dcms_Product',
          nodesToConfigure: [{
            productOfferingID: 'Dcms_Product',
            action: 'I',
            quantity: 1,
          }],
        }],
      }

      const { data } = await client.post<{ data: any }>('cart/v1/0.0/addproduct', requestBody)

      return data
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  async function setCartCustomerAccountWrapper(props: CartCustomerAccountWrapperProps) {
    const {
      channel,
      cartInstanceIdentifier,
      customerCategory,
      customerSegment,
      primaryEmailID,
      telephoneNumber,
      addressSplited,
      borough,
      givenName,
      familyName,
      accountHolderName,
      bankCode,
      accountNumber,
      sprn,
      partnerID,
    } = props
    try {
      const customerBillCycleId = await getCustomerBillCycleId()

      const params = {
        interactionID: cartInstanceIdentifier,
        interactionDate: new Date()
          .toISOString(),
        serviceName: 'createAndMapCustomerAccount',
        channel,
        cartInstanceIdentifier,
        partnerID,
        customer: {
          customerContact: [{
            contactMedium: [{
              primaryEmailID,
              telephoneNumber,
              eveningContactNumber: telephoneNumber,
              address: [{
                addressLine1: addressSplited[0].trim(),
                addressLine2: addressSplited.length === 4 ? addressSplited[1].trim() : '',
                addressLine3: addressSplited.length === 5 ? addressSplited[2].trim() : '',
                city: addressSplited.length === 4 ? addressSplited[2].trim() : addressSplited[1].trim(),
                pinCode: addressSplited.slice(-1)[0].trim(),
                countryCode: 'ENG',
                stateOrProvince: borough,
                externalAddressRefID: sprn,
              }],
            }],
            party: {
              individualName: [{
                givenName,
                familyName,
              }],
            },
          }],
          customerCategory,
          customerSegment,
          customerType: 'NO',
          customerSubType: 'NOT_APPLICABLE',
        },
        customerAccount: {
          customerId: '',
          name: accountHolderName,
          customerBillCycle: {
            billingFrequency: 'MONTHLY',
            customerBillCycleId,
          },
          preferedPaymentMethod: {
            payMethod: {
              bankPaymentMethod: {
                bankCode,
                bankAccountName: '',
                accountNumber,
              },
            },
          },
        },
      }

      const {
        data,
      } = await client.post<{ data: any[] }>('accountservice/v1/0.0/customer-account', params)

      return data
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  async function removeProductFromCart(
    cartInstanceIdentifier: string,
    requestObject: string,
    channel: string,
  ) {
    try {
      const params = {
        cartInstanceIdentifier,
        channelID: channel,
        userTokenString: 'CFLHOBS',
        requestObject,
      }

      const { data } = await client.patch<{ data: any }>(
        'cart/v1/0.0/removeproduct',
        params,
      )
      return data
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  async function validateCart(cartInstanceIdentifier: string, channel: string) {
    try {
      const params = {
        interactionID: cartInstanceIdentifier,
        interactionDate: new Date()
          .toISOString(),
        description: 'wrapperDigitalService',
        serviceName: 'basketValidationService',
        triggeredBy: ChannelType.DIGITAL,
        channel,
        buID: 'DEFAULT',
        opID: 'CFL',
        lang: 'ENG',
        cartInstanceIdentifier,
      }

      const {
        data,
      } = await client.post<{ data: any }>('cart/v1/0.0/validate', params)

      return data
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  return {
    addPreConfiguredProductToCart,
    setCartCustomerAccountWrapper,
    validateCart,
    removeProductFromCart,
    addRafToCart,
    addDCMSToCart,
  }
}
