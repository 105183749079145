import React from 'react'
import {
  Typography, Link, Box,
} from '@material-ui/core'
import classes from './ComparisonModal.styles'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import { ArrowForwardIos } from '@material-ui/icons'
import { TextWithImageProps as ImportedTextWithImageProps } from './ComparisonModal'
import MediaImage from '../Media/MediaImage'
interface LocalTextWithImageProps {

  textWithImageTab?: ImportedTextWithImageProps;
  text_with_image?: any;
}
const TextWithImage = ({
  textWithImageTab,
  text_with_image,
}: LocalTextWithImageProps) => {
  const data = textWithImageTab || text_with_image

  return (
    <Box
      className={classes.textwithImageContainer}
      px={4}
      id="textWithImage"
    >
      {data ? (
        <>
          {data.imageWidth && data.imageHeight ? (
            <MediaImage
              src={data.image}
              width={data.imageWidth}
              height={data.imageHeight}
              alt=""
            />
          ) : (
            <img src={data.image} alt=""/>
          )}
          <Typography variant="h3">{data.title}</Typography>
          <Wysiwyg variant="body1" body={data.body}/>
          {data?.button?.url && (
            <Link href={data.button.url}>
              <Box display="flex" width="min-content" whiteSpace="nowrap" mx="auto" alignItems="center" gridGap={8}>
                <Typography variant="body1">{data.button.text}</Typography>
                <ArrowForwardIos/>
              </Box>
            </Link>
          )}
        </>
      ) : null}
    </Box>
  )
}

export default TextWithImage
