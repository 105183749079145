
import { StepType } from '../../utils/commonEnums'
import { StepTypes } from './steps/CheckoutStep/CheckoutStep'

export const BUNDLES_HASH = '#bundles'
export const BUNDLES_PATH = '/bundles'
export const ADDONS_PATH = '/addons'

const ORDER_FLOW_BASE = '/order'
export default function getStepUrl(step: StepTypes, sprn?: number): string {
  const pathname: string = (() => {
    switch (step) {
      case StepType.broadband:
      case StepType.broadbandBundles:
        return `${ORDER_FLOW_BASE}/package${BUNDLES_PATH}`
      case StepType.addons:
        return `${ORDER_FLOW_BASE}/package${ADDONS_PATH}`
      case StepType.checkout:
      case StepType.checkoutDetails:
        return `${ORDER_FLOW_BASE}/checkout/details`
      case StepType.checkoutInstallation:
        return `${ORDER_FLOW_BASE}/checkout/installation`
      case StepType.checkoutOneTouchSwitching:
        return `${ORDER_FLOW_BASE}/checkout/switching`
      case StepType.checkoutPayment:
        return `${ORDER_FLOW_BASE}/checkout/payment`
      case StepType.checkoutOrderSummary:
        return `${ORDER_FLOW_BASE}/checkout/summary`
      case StepType.checkoutOrderProcessing:
        return `${ORDER_FLOW_BASE}/checkout/order-processing`
      case StepType.checkoutOrderConfirmation:
        return `${ORDER_FLOW_BASE}/checkout/confirmation`
      case StepType.orderManagement:
        return `${ORDER_FLOW_BASE}/management`
      case StepType.orderManagementPortingConfirmation:
        return `${ORDER_FLOW_BASE}/management/keep-number-confirmation`
      case StepType.orderManagementNewNumberConfirmation:
        return `${ORDER_FLOW_BASE}/management/new-number-confirmation`
    }
  })()

  return sprn ? `${pathname}?sprn=${sprn}` : pathname
}
