import { useContext } from 'react'
import {
  PremiseDetail, TechnologyType,
} from '../../api/Addresses'
import { newOrderFlowStore } from '../../sessionStorage'
import productsHaveSameContract from '../../utils/productsHaveSameContract'
import { handleRedirect } from '../../utils/redirect'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import getStepUrl from './getStepUrl'
import {
  INITIAL_ORDER_OPTIONS,
  OrderOptions,
  THREE_GIGA_IDS,
} from './OrderContext'
import { StepType } from '../../utils/commonEnums'
import { StepTypes } from './steps/CheckoutStep/CheckoutStep'

export default function useValidateStep() {
  const { premise } = useContext(PremiseContext)

  async function validateStep(step: StepTypes) {
    const options = (await newOrderFlowStore.get()) ?? undefined
    if (JSON.stringify(options) === JSON.stringify(INITIAL_ORDER_OPTIONS) || JSON.stringify({
      ...options,
      reachedInstallationStep: false,
    }) === JSON.stringify(INITIAL_ORDER_OPTIONS)) {
      return
    }

    switch (step) {
      case StepType.broadband:
      case StepType.orderManagement:
      case StepType.orderManagementNewNumberConfirmation:
        break
      case StepType.orderManagementPortingConfirmation:
        await validatePortedNumberConfirmation(options)
        break
      case StepType.checkoutDetails:
        await validateBroadband(options)
        await validatePackagesContractLength(options)
        await validate3Giga(options, premise)
        break
      case StepType.checkoutOneTouchSwitching:
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        break
      case StepType.checkoutInstallation:
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        break
      case StepType.checkoutPayment:
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        await validateInstallation(options)

        break
      case StepType.checkoutOrderSummary:
      case StepType.checkoutOrderProcessing:
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        await validateInstallation(options)
        await validatePayment(options, premise)

        break
      case StepType.checkoutOrderConfirmation:
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        await validateInstallation(options)
        await validatePayment(options, premise)
        await validateOrderConfirmation(options)

        break
      default:
        await validateBroadband(options)

        break
    }
  }

  return {
    validateStep,
  }
}

async function validateOrderConfirmation(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
    return
  }

  if (!options.accountId) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
  }
}

async function validatePackagesContractLength(options?: OrderOptions) {
  if (options) {
    const {
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
    } = options

    const haveSameContractLength = productsHaveSameContract({
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
    })

    if (!haveSameContractLength) {
      await handleRedirect({ to: getStepUrl(StepType.broadband) })
    }
  }
}

async function validatePayment(options?: OrderOptions, premise?: PremiseDetail | null) {
  if (!options) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
    return
  }

  if (premise?.type === 'Community') {
    return
  }

  if (!options.paymentDetails) {
    await handleRedirect({ to: getStepUrl(StepType.checkoutPayment) })
  }
}

async function validateInstallation(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
    return
  }

  if (options.timeslotError) {
    await handleRedirect({ to: getStepUrl(StepType.checkoutInstallation) })
  }
}

async function validateBroadband(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
    return
  }

  if (!options.broadband && !options.bundle) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
  }
}

async function validateDetails(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
    return
  }

  if (!options.data.email) {
    await handleRedirect({ to: getStepUrl(StepType.checkoutDetails) })
    return
  }

  if (!options.data.firstName || !options.data.lastName || !options.data.phone) {
    await handleRedirect({ to: getStepUrl(StepType.checkoutDetails) })
  }
}

async function validateEmail(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
    return
  }

  if (!options.data.email) {
    await handleRedirect({ to: getStepUrl(StepType.checkoutDetails) })
  }
}

async function validate3Giga(options?: OrderOptions, premise?: PremiseDetail | null) {
  if (options?.broadband && THREE_GIGA_IDS.includes(options.broadband._id) && premise && premise?.technology !== TechnologyType.XGSPON) {
    await handleRedirect({ to: getStepUrl(StepType.broadband) })
  }
}

async function validatePortedNumberConfirmation(options?: OrderOptions) {
  if (!options?.hasPortedNumber) {
    await handleRedirect({ to: getStepUrl(StepType.orderManagement) })
  }
}
