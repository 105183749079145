import React, {
  useContext, useEffect,
} from 'react'
import {
  ButterCMSComponent, getButterComponentByType,
} from '../../../../butter'
import { useRouter } from 'next/router'
import {
  OrderOptions, useOrderContext,
} from '../../OrderContext'
import { Tab } from './Tab'
import { PremiseContext } from '../../../PremiseTracker/PremiseContext'
import { TechnologyType } from '../../../../api/Addresses'
import { PackageProps } from '../../../Packages/Package'
import createCRMApiClient, { CRMApiClient } from '../../../../api/CRMApi'
import { ProductProps } from '../../../Products/Product'
import {
  addToCartOptions,
} from '../../../Packages/Helpers'
import { StepTypes } from '../CheckoutStep/CheckoutStep'

interface PackageStepProps {
  bundlesData: {
    components: ButterCMSComponent[];
  };
  generalSettings: any;
  preselectedPackageId: string | null;
  step: StepTypes;
}

export interface PackageAddProps {
  bundle: PackageProps | null;
  broadband: PackageProps | null;
  cartInstanceIdentifier: string;
  channel: string;
  options: OrderOptions;
  client: CRMApiClient;
}

export default function PackageStep({
  preselectedPackageId, bundlesData, generalSettings, step,
}: PackageStepProps) {
  const {
    options, setOptions,
  } = useOrderContext()

  const router = useRouter()
  const isBundles = router.asPath.includes('bundles')
  const { premise } = useContext(PremiseContext)
  const isEligibleFor3Giga = premise?.technology === TechnologyType.XGSPON || premise?.technology === TechnologyType.COMBO

  const setProduct = async (product: ProductProps | PackageProps, channel: string, hide_strikethrough: boolean) => {
    const productOptions = await addToCartOptions({
      product,
      options,
      client,
      channel,
      hide_strikethrough,
    })
    setOptions(productOptions)
  }

  const client = createCRMApiClient()

  useEffect(() => {
    if (options.showDifferentContractError) {
      setOptions({
        ...options,
        showDifferentContractError: false,
      })
    }
  }, [
    options,
    setOptions,
  ])

  const selectedBroadbandId = options.broadband ? options.broadband.productOfferingId : preselectedPackageId

  return (
    <div>
      <Tab
        getButterComponentByType={getButterComponentByType}
        data={bundlesData}
        options={options}
        generalSettings={generalSettings}
        selectedBroadbandId={selectedBroadbandId}
        setBroadband={setProduct}
        setBundleOption={setProduct}
        isBundles={isBundles}
        isEligibleFor3Giga={isEligibleFor3Giga}
        step={step}
      />
    </div>
  )
}
