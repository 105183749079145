import React from 'react'
import {
  Box,
  Typography,
  Grid,
  Link,
} from '@material-ui/core'
import { format } from 'date-fns'
import { Create } from '@material-ui/icons'
import { TimeslotData } from '../../../../../../api/BookingCalendar'
import { formatTimeForTimeslotData } from '../../../../../Calendar/calendarUtils'
import { StepType } from '../../../../../../utils/commonEnums'

interface TimeslotProps {
  timeslot?: TimeslotData | null;
  switchStep: ((e: string) => void);
  dcms: boolean;
}

const standartCopy = (timeslot?: TimeslotData | null) => timeslot ?
  <>
    <Typography variant="body1">{format((new Date(timeslot.startDateTime)), 'dd/MM/yyyy')}</Typography>
    <Typography variant="body1">{timeslot.label} {formatTimeForTimeslotData(timeslot)}</Typography>
    <Box mt={2}>
      <Typography variant="body2">*The engineer may arrive at any time during your chosen time slot, but will be there for less than two hours once they arrive.</Typography>
    </Box>
  </> :
  <Box mt={2}>
    <Typography variant="body1">
      To arrange an installation go to the{' '}
      <Link underline="always" href="/order/management">
        Order Management portal
      </Link> after having placed the order.
    </Typography>
  </Box>

const dcmsCopy = (
  <Box mt={2}>
    <Typography variant="body1">Your address is part of the Government Gigabit Broadband
      Voucher Scheme. Once your order is complete, you&apos;ll receive two emails that will require your action to get your full fibre broadband connected.
    </Typography>
  </Box>
)

const InstallationDetails = ({
  timeslot, switchStep, dcms,
}: TimeslotProps) => {
  return (
    <>
      <Box mb={2}>
        <Grid container justify="space-between">
          <Typography variant="h4">Installation</Typography>
          <Create color="secondary" onClick={() => switchStep(StepType.checkoutInstallation)}/>
        </Grid>
      </Box>
      {dcms ?
        dcmsCopy :
        standartCopy(timeslot)}
    </>
  )
}

export default InstallationDetails
