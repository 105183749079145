import { css } from "linaria";
import theme, { COLORS, fontWeights } from "../../styles/theme";
import fontSizes from "../../styles/fontSizes";

const classes = {
  wrapper: css`
    ${theme.breakpoints.up("md")} {
      overflow-x: auto;
      width: 100%;
    }
  `,

  root: css`
    margin: 0 auto;
    padding-left: ${theme.spacing(4)}px;
    padding-right: ${theme.spacing(4)}px;
    width: fit-content;
    ${theme.breakpoints.down("sm")} {
      padding-left: 5vw;
      padding-right: 5vw;
    }
    img {
      object-fit: contain;
      width: 100%;
    }
    .MuiTableCell-body {
      background-color: ${theme.palette.common.white} !important;
    }
    ::-webkit-scrollbar {
      color: white;
      padding-bottom: 0px;
    }
    tr:nth-of-type(odd) th:first-child,
    .MuiTableRow-root:not(:last-child, :first-child) {
      background-color: ${theme.palette.common.white} !important;
    }
    .MuiTableRow-root {
      margin: 0px;
      th {
        ${theme.breakpoints.up("md")} {
          text-align: center;
        }
      }
      th .MuiTypography-root p,
      td {
        ${theme.breakpoints.down("xs")} {
          font-size: 0.75rem;
        }
      }
      :nth-of-type(even) {
        thead,
        .MuiTableCell-root {
          background-color: ${theme.palette.warning.light} !important;
        }
      }
    }
    .MuiTableCell-root {
      overflow: hidden;
      padding: ${theme.spacing(1, 2)} !important;
      box-sizing: border-box;
      text-align: center;
      border: none;
      border-width: 0;
      font-weight: ${theme.typography.fontWeightLight!};
      ${theme.breakpoints.up("lg")} {
        padding: 0 ${theme.spacing(1)}px;
      }
      p {
        line-height: 1.2;
        margin: 0;
      }
      :first-child {
        p {
          text-align: left;
        }
      }
      :not(:last-child) {
        border-right: solid 1px rgba(82, 70, 88, 0.16);
      }
    }
  `,
  table: css`
    margin: 0 auto;
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.divider};
    &.MuiTable-root {
      border-collapse: separate;
      overflow: hidden;
      width: auto;
    }
  `,
  tableCell: css`
    width: 180px;
    min-width: 180px;
    font-size: 0.75rem !important;
    display: table-cell;
    &:first-child {
      width: 200px;
      min-width: 200px;
      ${theme.breakpoints.down("sm")} {
        min-width: 30vw;
        width: 30vw;
      }
    }
    .MuiTypography-root {
      font-weight: ${fontWeights.book};
      color: ${theme.palette.text.primary};
    }
    ${theme.breakpoints.down("sm")} {
      min-width: 30vw;
      width: 30vw;
    }
  `,
  tableCellCompany: css`
    > .MuiBox-root {
      height: ${theme.spacing(5)}px;
    }
  `,
  checkBox: css`
    .MuiCheckbox-root {
      color: ${theme.palette.primary.main};
      padding: 0;
      margin-right: 8px;
    }
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    width: 100vw;
  `,
  checkBoxLabel: css`
    .MuiTypography-root {
      font-weight: ${fontWeights.medium};
      color: ${theme.palette.primary.main};
    }
  `,
  title: css`
    &.MuiTypography-h3 {
      text-align: center;
      padding-top: ${theme.spacing(4)}px;
      padding-bottom: ${theme.spacing(3)}px;
      color: ${theme.palette.primary.main};
      ${theme.breakpoints.up("md")} {
        padding-top: 0;
        padding-bottom: ${theme.spacing(5)}px;
        font-size: ${fontSizes.h2};
      }
    }
    &.MuiTypography-subtitle2 {
      padding-top: ${theme.spacing(2)}px;
      padding-bottom: ${theme.spacing(4)}px;
      font-weight: ${theme.typography.fontWeightLight!};
      margin-left: ${theme.spacing(8)}px;
      color: ${theme.palette.primary.main};
      ${theme.breakpoints.up("md")} {
        width: 90%;
        text-align: right;
      }
    }
  `,
  emptyHeaderCell: css`
    height: ${theme.spacing(6)}px;
  `,
  logo: css`
    max-width: 90%;
    ${theme.breakpoints.up("md")} {
      max-width: 100%;
    }
  `,
  doneIncon: css`
    svg {
      color: ${theme.palette.success.main};
    }
  `,
  closeIcon: css`
    svg {
      color: ${theme.palette.error.main};
    }
  `,
  providerName: css`
    ${theme.breakpoints.down("xs")} {
      max-width: 73px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${fontSizes.body2} !important;
    }
    ${theme.breakpoints.up("md")} {
      color: ${COLORS.bodyTextPrimary} !important;
      font-weight: ${fontWeights.bold} !important;
      font-size: ${fontSizes.h5} !important;
    }
  `,
  primaryColourCell: css`
    .MuiTypography-root {
      color: ${theme.palette.primary.main} !important;
      font-weight: ${fontWeights.bold} !important;
    }
  `,
  priceDiferenceColourCell: css`
    .MuiTypography-root {
      color: ${theme.palette.error.main} !important;
      font-weight: ${fontWeights.bold} !important;
    }
  `,
  secondaryColourCell: css`
    .MuiTypography-root {
      color: ${theme.palette.secondary.main} !important;
      font-weight: ${fontWeights.bold} !important;
    }
  `,
  mobileArrowForward: css`
    right: 5px;
    ${theme.breakpoints.down("sm")} {
      right: -25px;
    }
  `,
  mobileArrowBack: css`
    left: 5px;
    ${theme.breakpoints.down("sm")} {
      left: -25px;
    }
    svg {
      position: relative;
      left: 6px; // MUI icon bug
    }
  `,
  mobileArrow: css`
    position: absolute !important;
    top: -7px;
    z-index: 1000;
    cursor: pointer;
    color: ${theme.palette.primary.main};
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
      background-color: white;
      border-radius: 50%;
    }
  `,
  compare: css`
    gap: 8px;
    display: flex;
    padding: 8px 16px;
    ${theme.breakpoints.down("sm")} {
      padding: 5vw;
    }



    ${theme.breakpoints.down("xs")} {
      flex-direction: column;
      width: fit-content;
      min-width: 90%;
      max-width: 90%;
    }
    align-items: center;
    margin-bottom: ${theme.spacing(1)}px;
    max-width: fit-content;
    .MuiSelect-select:focus {
      background-color: inherit !important;
    }
  `,
  formControl: css`
    min-width: 125px;
    width: 100%;
    ${theme.breakpoints.up("md")} {
      width: auto;
    }
    ${theme.breakpoints.down("sm")} {
    }
    .MuiOutlinedInput-root {
      border-radius: 24px;
      min-width: 226px;
      ${theme.breakpoints.down("sm")} {
        min-width: 100%;
      }
    }
  `
};

export default classes;
