import {
  Box, Typography,
} from '@material-ui/core'
import React, {
  useContext, useState, useEffect,
} from 'react'
import { useRouter } from 'next/router'
import { PremiseContext } from '../../PremiseTracker/PremiseContext'
import {
  useOrderContext, THREE_GIGA_IDS,
} from '../../NewOrderFlow/OrderContext'
import { getOrderProducts } from '../../../utils/getOrderProducts'
import {
  Close, ArrowForwardIos,
} from '@material-ui/icons'
import miniBasket from '../../../../public/shopping-basket.svg'
import getStepUrl from '../../NewOrderFlow/getStepUrl'
import classes from './StickyBannerFinishOrder.styles'
import { TechnologyType } from '../../../api/Addresses'
import {
  getFormattedMonthlyCost, getTotalInstallationCost,
} from '../../../utils/getProductDetails'
import { StepType } from '../../../utils/commonEnums'

interface StickyBannerFinishOrderProps {
  sticky_banner?: boolean;
}

export const StickyBannerFinishOrder = ({
  sticky_banner,
}: StickyBannerFinishOrderProps) => {
  const router = useRouter()
  const currentUrl = router.asPath
  const { premise } = useContext(PremiseContext)
  const { sprn } = router.query
  const sprnNumber = isNaN(Number(sprn)) ? undefined : Number(sprn)
  const [
    isBannerVisible,
    setIsBannerVisiblity,
  ] = useState<boolean>(true)

  const [
    initialOffset,
    setInitialOffset,
  ] = useState<number>(150)

  const [
    offsetTop,
    setOffsetTop,
  ] = useState<Number>(initialOffset)

  const [
    stickyOffset,
    setStickyOffset,
  ] = useState<Number>(20)

  useEffect(() => {
    calculateInitHeightTimeout()
  }, [])

  useEffect(() => {
    calculateInitHeight()
  }, [currentUrl])

  useEffect(() => {
    const hide = window.sessionStorage.getItem('hideStickyFinishOrder')
    if (hide === 'true') {
      setIsBannerVisiblity(false)
    }
  }, [isBannerVisible])

  const hideBanner = (): void => {
    window.sessionStorage.setItem('hideStickyFinishOrder', JSON.stringify(true))
    setIsBannerVisiblity(false)
  }

  const {
    options: {
      broadband,
      voice,
      tv,
      wier,
      vas,
      bundle,
      discount,
      alternativePhone,
    },
  } = useOrderContext()

  const orderProducts = getOrderProducts({
    broadband,
    voice,
    tv,
    wier,
    vas,
    bundle,
  })

  useEffect(() => {
    const CloseInfobarElement: HTMLElement | null = document.querySelector('[aria-label="close-info-bar"]')
    if (CloseInfobarElement) {
      CloseInfobarElement.addEventListener('click', calculateInitHeightTimeout)
      return () => {
        CloseInfobarElement.removeEventListener('click', calculateInitHeightTimeout)
      }
    }

    return undefined
  }, [])

  const calculateInitHeightTimeout = () => {
    setTimeout(() => calculateInitHeight(), 100)
  }

  const calculateInitHeight = () => {
    const InfobarElement: HTMLElement | null = document.querySelector('#infobar_top')
    const HeaderElement: HTMLElement | null = document.querySelector('#header')
    const ValuePropsElement: HTMLElement | null = document.querySelector('#value_props_bar')
    let totalHeight = 20
    totalHeight +=
    ((InfobarElement) ? InfobarElement?.offsetHeight : 0) +
    ((HeaderElement) ? HeaderElement?.offsetHeight : 0) +
    ((ValuePropsElement) ? ValuePropsElement?.offsetHeight : 0)
    if (InfobarElement) {
      const compStyles = window.getComputedStyle(InfobarElement)
      if (compStyles.getPropertyValue('position') === 'fixed') {
        setStickyOffset(InfobarElement?.offsetHeight + 20)
      }
    }

    setInitialOffset(totalHeight)
    setOffsetTop(totalHeight)
  }

  const handleScroll = () => {
    const positionDiff: Number = initialOffset - Number(document.body.scrollTop)
    const currentScrollPos = (positionDiff < stickyOffset) ? stickyOffset : positionDiff
    setOffsetTop(currentScrollPos)
  }

  useEffect(() => {
    function watchScroll() {
      document.body.addEventListener('scroll', handleScroll)
    }

    watchScroll()
    return () => document.body.removeEventListener('scroll', handleScroll)
  }, [
    handleScroll,
    offsetTop,
    initialOffset,
  ])

  const isEligibleFor3Giga = premise?.technology === TechnologyType.XGSPON || premise?.technology === TechnologyType.COMBO
  const filteredOrderProducts = isEligibleFor3Giga ? orderProducts : orderProducts.filter((el: any) => !THREE_GIGA_IDS.includes(el._id))
  const premiseCanOrder = premise?.accepting === 'order'
  const showProducts = filteredOrderProducts?.length > 0 && premiseCanOrder

  if (isBannerVisible === false || showProducts === false || !sticky_banner) {
    return null
  }

  const handleClick = () => {
    const editBasket = () => {
      if (broadband && broadband.contractType === 'MONTHLY') {
        router.push(getStepUrl(StepType.broadband, premise?.sprn), undefined, { shallow: true })
      } else {
        router.push(getStepUrl(StepType.broadbandBundles, premise?.sprn), undefined, { shallow: true })
      }
    }

    if (voice && !alternativePhone) {
      editBasket()
    } else {
      router.push(getStepUrl(StepType.checkout, sprnNumber), undefined, { shallow: true })
    }
  }

  return (
    <Box mr={2} className={classes.main} style={{ top: offsetTop + 'px' }}>
      <button type="button" className={classes.buttonClose} onClick={hideBanner}>
        <Close/>
      </button>
      <div className={classes.arrowIcon}><ArrowForwardIos/></div>
      <button type="button" className={classes.buttonNoBg} onClick={handleClick}>
        <Box className={classes.boxTop}><img src={miniBasket} alt=""/><Typography variant="body2" className={classes.finishText}> Finish your order</Typography></Box>
        <Box className={classes.boxMiddle}>
          {broadband && <Typography variant="body2">{broadband.display_name} Broadband</Typography>}
          {voice && <Typography variant="body2">{voice.display_name} </Typography>}
          {tv && <Typography variant="body2">{tv.display_name} </Typography>}
          {wier && <Typography variant="body2">{wier.display_name} </Typography>}
          {vas && <Typography variant="body2">{vas.display_name} </Typography>}
          {bundle && <Typography variant="body2">{bundle.display_name} </Typography>}
        </Box>
        <Box>
          <Box className={classes.boxBottom}>
            <Typography variant="subtitle2">Monthly: {`${getFormattedMonthlyCost({
              broadband,
              bundle,
              voice,
              tv,
              wier,
              vas,
              discount,
            })}`}
            </Typography>
            <span className={classes.separator}/>
            <Typography variant="subtitle2">Set up: {`${getTotalInstallationCost({
              broadband,
              bundle,
              voice,
              tv,
              wier,
              vas,
            })}`}
            </Typography>
          </Box>
        </Box>
      </button>
    </Box>
  )
}

export default StickyBannerFinishOrder
