import React from 'react'
import {
  Box, Container, Typography,
} from '@material-ui/core'
import classes from './BannerWithIcon.styles'
import { Close } from '@material-ui/icons'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import TrustPilotBox, { TrustPilotBoxProps } from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import { sanitiseHtml } from '../../utils/sanitise'
export interface BannerWithIconProps {
  body?: any;
  background: string;
  closedBannerWithIcon: boolean;
  image: MediaImageProps;
  handleClose: () => void;
  trustpilot: boolean;
  trustpilot_box: TrustPilotBoxProps;
  closable: boolean;
}

const BannerWithIcon = ({
  body,
  closedBannerWithIcon,
  handleClose,
  image,
  background = 'transparent',
  trustpilot_box,
  closable,
}: BannerWithIconProps) => {
  if (closedBannerWithIcon) {
    return null
  }

  return (
    <Box style={{ backgroundColor: background }} className={classes.wrapper} py={2}>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box display="flex">
            {image && image.src && <Box mr={2} display="flex" alignItems="center"><MediaImage src={image.src} alt={image.alt} width={image.width} height={image.height}/></Box>}
            {body && <Typography variant="overline" dangerouslySetInnerHTML={{ __html: sanitiseHtml(body) }}/>}
          </Box>
          {trustpilot_box && trustpilot_box.widget &&
            <Box mt={1}>
              <TrustPilotBox
                background_color={trustpilot_box.background_color}
                widget={trustpilot_box.widget}
                padding={trustpilot_box.padding}
                margin={trustpilot_box.margin}
                wrapped={trustpilot_box.wrapped}
                inverted={trustpilot_box.inverted}
                width={trustpilot_box.width}
                mobile_center={trustpilot_box.mobile_center}
              />
            </Box>}
        </Box>
        {closable && <Close onClick={handleClose} color="primary" className={classes.closeButton}/>}
      </Container>
    </Box>
  )
}

export default BannerWithIcon
