import { ButterCMSComponent } from '../butter'

interface WebsiteData {
  name: string;
  url: string;
  logo: string;
  sameAs?: string[];
}

interface BreadcrumbItem {
  name: string;
  item: string;
}

const websiteData: WebsiteData = {
  name: 'Community Fibre',
  url: 'https://communityfibre.co.uk',
  logo: 'https://communityfibre.co.uk/logo.png',
  sameAs: [
    'https://www.facebook.com/communityfibre',
    'https://twitter.com/communityfibre',
    'https://uk.linkedin.com/company/communityfibre',
  ],
}

/**
 * Generate organisation structured data
 */
export const generateOrganisationData = (): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: websiteData.name,
    url: websiteData.url,
    logo: websiteData.logo,
    sameAs: websiteData.sameAs,
  }
}

/**
 * Generate website structured data
 */
export const generateWebsiteData = (description?: string): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: websiteData.name,
    url: websiteData.url,
    ...(description && { description }),
    potentialAction: {
      '@type': 'SearchAction',
      target: `${websiteData.url}/search?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  }
}

/**
 * Generate breadcrumb structured data
 */
export const generateBreadcrumbData = (
  items: BreadcrumbItem[],
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.name,
      item: item.item,
    })),
  }
}

/**
 * Generate product structured data
 */
export const generateProductData = (
  name: string,
  description: string,
  image: string,
  price: number,
  priceCurrency = 'GBP',
  availability = 'https://schema.org/InStock',
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name,
    description,
    image,
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency,
      availability,
      url: websiteData.url,
    },
  }
}

/**
 * Generate article structured data
 */
export const generateArticleData = (
  headline: string,
  image: string,
  datePublished: string,
  dateModified: string,
  authorName: string,
  description?: string,
  publisherName: string = websiteData.name,
  publisherLogo: string = websiteData.logo,
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline,
    image,
    datePublished,
    dateModified,
    author: {
      '@type': 'Person',
      name: authorName,
    },
    publisher: {
      '@type': 'Organization',
      name: publisherName,
      logo: {
        '@type': 'ImageObject',
        url: publisherLogo,
      },
    },
    ...(description && { description }),
  }
}

/**
 * Generate FAQ structured data
 */
export const generateFAQData = (
  questions: { question: string; answer: string }[],
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.map((q) => ({
      '@type': 'Question',
      name: q.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: q.answer,
      },
    })),
  }
}

/**
 * Generate local business structured data
 */
export const generateLocalBusinessData = (
  name: string,
  image: string,
  telephone: string,
  address: {
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
  },
  geo: {
    latitude: number;
    longitude: number;
  },
  openingHours: string[],
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name,
    image,
    telephone,
    address: {
      '@type': 'PostalAddress',
      ...address,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: geo.latitude,
      longitude: geo.longitude,
    },
    openingHoursSpecification: openingHours.map((hours) => ({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: hours.split(' ')[0],
      opens: hours.split(' ')[1].split('-')[0],
      closes: hours.split(' ')[1].split('-')[1],
    })),
  }
}

/**
 * Generate service structured data
 */
export const generateServiceData = (
  name: string,
  description: string,
  provider: string = websiteData.name,
  areaServed = 'London, UK',
): Record<string, unknown> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Service',
    name,
    description,
    provider: {
      '@type': 'Organization',
      name: provider,
    },
    areaServed,
  }
}

/**
 * Generate structured data based on page components
 */
export const generateStructuredDataFromComponents = (
  components: ButterCMSComponent[],
  pageTitle: string,
  pageUrl: string,
  description?: string,
): Record<string, unknown>[] => {
  const structuredData: Record<string, unknown>[] = [
    generateOrganisationData(),
    generateWebsiteData(description),
  ]

  // Add breadcrumb data if not home page
  if (pageUrl !== websiteData.url && pageUrl !== `${websiteData.url}/`) {
    const breadcrumbItems: BreadcrumbItem[] = [{
      name: 'Home',
      item: websiteData.url,
    }]

    // Extract path segments for breadcrumbs
    const pathSegments = pageUrl
      .replace(websiteData.url, '')
      .split('/')
      .filter(Boolean)

    let currentPath = websiteData.url
    pathSegments.forEach((segment, index) => {
      currentPath += `/${segment}`
      if (index === pathSegments.length - 1) {
        breadcrumbItems.push({
          name: pageTitle,
          item: currentPath,
        })
      } else {
        breadcrumbItems.push({
          name: segment.replace(/-/g, ' ')
            .replace(/\b\w/g, (l) => l.toUpperCase()),
          item: currentPath,
        })
      }
    })

    structuredData.push(generateBreadcrumbData(breadcrumbItems))
  }

  // Check for FAQ components
  const faqComponents = components.filter(
    (component) => component.type === 'faq' || component.type === 'faq_dropdown',
  )

  if (faqComponents.length > 0) {
    // Extract FAQ questions and answers
    const questions: { question: string; answer: string }[] = []

    faqComponents.forEach((component) => {
      // Access dynamic properties using index signature
      const faqs = (component as any).faqs as any[] | undefined

      if (faqs && Array.isArray(faqs)) {
        faqs.forEach((faq: any) => {
          if (faq.question && faq.answer) {
            questions.push({
              question: faq.question,
              answer: faq.answer,
            })
          }
        })
      }
    })

    if (questions.length > 0) {
      structuredData.push(generateFAQData(questions))
    }
  }

  // Check for product components
  const productComponents = components.filter(
    (component) => component.type === 'product' || component.type === 'product_card',
  )

  if (productComponents.length > 0) {
    productComponents.forEach((component) => {
      // Access dynamic properties using index signature
      const name = (component as any).name as string | undefined
      const description = (component as any).description as string | undefined
      const image = (component as any).image as string | undefined
      const price = (component as any).price as number | undefined

      if (name && description && image && price !== undefined) {
        structuredData.push(
          generateProductData(
            name,
            description,
            image,
            price,
          ),
        )
      }
    })
  }

  return structuredData
}
