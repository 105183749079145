/**
 * Sanitisation profiles for different use cases
 */
export enum SanitiseProfile {
  /** Standard profile for general HTML content */
  STANDARD = 'standard',
  /** Strict profile for user-generated content */
  STRICT = 'strict',
  /** Relaxed profile for trusted external embeds that require scripts */
  EMBED = 'embed'
}

/**
 * Basic HTML sanitisation function
 * 
 * This is a simplified sanitisation function that removes potentially dangerous
 * elements and attributes from HTML content. For production use, consider using
 * a more robust library like DOMPurify.
 * 
 * @param html - The HTML content to sanitise
 * @param profile - The sanitisation profile to use
 * @returns Sanitised HTML string
 */
export const sanitiseHtml = (html: string, profile: SanitiseProfile = SanitiseProfile.STANDARD): string => {
  if (!html) return ''
  
  // For EMBED profile, return the original HTML since we need to allow scripts
  if (profile === SanitiseProfile.EMBED) {
    return html
  }
  
  // For STANDARD and STRICT profiles, remove potentially dangerous elements and attributes
  let sanitised = html
  
  // Remove script tags and their content
  sanitised = sanitised.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
  
  // Remove event handlers
  const eventHandlers = [
    'onabort', 'onblur', 'onchange', 'onclick', 'ondblclick', 'onerror', 'onfocus',
    'onkeydown', 'onkeypress', 'onkeyup', 'onload', 'onmousedown', 'onmousemove',
    'onmouseout', 'onmouseover', 'onmouseup', 'onreset', 'onresize', 'onscroll',
    'onselect', 'onsubmit', 'onunload'
  ]
  
  eventHandlers.forEach(handler => {
    const regex = new RegExp(`\\s${handler}\\s*=\\s*["'][^"']*["']`, 'gi')
    sanitised = sanitised.replace(regex, '')
  })
  
  // For STRICT profile, also remove iframe, object, and embed tags
  if (profile === SanitiseProfile.STRICT) {
    sanitised = sanitised
      .replace(/<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe>/gi, '')
      .replace(/<object\b[^<]*(?:(?!<\/object>)<[^<]*)*<\/object>/gi, '')
      .replace(/<embed\b[^<]*(?:(?!<\/embed>)<[^<]*)*<\/embed>/gi, '')
      .replace(/<form\b[^<]*(?:(?!<\/form>)<[^<]*)*<\/form>/gi, '')
  }
  
  return sanitised
}
