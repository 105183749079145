import createCRMApiClient, { CRMApiClient } from '../../api/CRMApi'
import {
  HobsServiceType, getProducts,
} from '../../api/Products'
import {
  HobsProduct, ProductOffering,
} from '../../api/Packages'
import { decryptString } from '../../utils'
import marketingEvents from '../../utils/marketing/marketingEvents'
import {
  checkIfVasExists,
  checkIfVoiceExists, checkIfWierExists,
} from '../Bundles/Bundle'
import { OrderOptions } from '../NewOrderFlow/OrderContext'
import { ProductProps } from '../Products/Product'
import { PackageProps } from './Package'
import { ProductToCartRequest } from '../../api/Cart'

export interface CartOptionsProps {
  options: OrderOptions;
  client: CRMApiClient;
  channel: string;
  cartInstanceIdentifier?: string;
  hide_strikethrough?: boolean;
}

export interface CartProps extends CartOptionsProps {
  product: PackageProps | ProductProps;
}

export interface RAFProps {
  appliedProductId: string;
  appliedReferralCode: string;
  channel: string;
  cartInstanceIdentifier: string;
  product: ProductProps;
}

export interface DCMSProps {
  cartInstanceIdentifier: string;
  channel: string;
  product: PackageProps | ProductProps;
}

export const removeAddonsIfExist = async ({
  client, cartInstanceIdentifier, channel, options,
}: CartOptionsProps) => {
  const {
    wier, vas, tv, voice, promo, discount,
  } = options

  const addOns = [
    wier,
    vas,
    tv,
    voice,
    promo,
    discount,
  ].filter(Boolean)

  let addOnsToBeRemoved = {} as any

  for await (const addOn of addOns) {
    const removedAddOn = removeFromCart({
      product: addOn,
      client,
      cartInstanceIdentifier,
      channel,
      options,
    })

    addOnsToBeRemoved = {
      ...addOnsToBeRemoved,
      ...await removedAddOn,
    }
  }

  return addOnsToBeRemoved
}

export const removeFromCart = async ({
  product, client, cartInstanceIdentifier, channel, options,
}: CartProps): Promise<OrderOptions> => {
  const key = product?.service_type as string
  const removingObject = { [key]: null } as any
  let productsToBeRemoved = removingObject as any

  if (product?.service_type === HobsServiceType.BROADBAND || product?.service_type === HobsServiceType.BUNDLE) {
    const removeAddons = await removeAddonsIfExist({
      client,
      cartInstanceIdentifier,
      channel,
      options,
    })

    productsToBeRemoved = {
      ...productsToBeRemoved,
      ...removeAddons,
    }
  }

  await client.cart.removeProductFromCart(cartInstanceIdentifier!, product?.productOfferingId!, channel)
  marketingEvents.engageWithCart('remove_from_cart', product, options)
  return productsToBeRemoved
}

export const removeProductsFromCart = async ({
  product,
  client,
  channel,
  options,
}: CartProps) => {
  const {
    broadband,
    bundle,
    wier,
    vas,
    voice,
    cartInstanceIdentifier,
  } = options

  const removeObj = (object: PackageProps | ProductProps): CartProps => ({
    product: object,
    client,
    cartInstanceIdentifier,
    channel,
    options,
  })

  const isBundle = product?.service_type === HobsServiceType.BUNDLE
  const isBroadband = product?.service_type === HobsServiceType.BROADBAND
  const isVoucher = options.voucherProduct?.productOfferingId

  let productsToBeRemoved = [] as any

  if (isBundle) {
    const includedWIER = checkIfWierExists(product)
    const includedVAS = checkIfVasExists(product)
    const includedVoice = checkIfVoiceExists(product)

    if (wier && includedWIER) {
      productsToBeRemoved = {
        ...productsToBeRemoved,
        ...await removeFromCart(removeObj(wier)),
      }
    }

    if (vas && includedVAS) {
      productsToBeRemoved = {
        ...productsToBeRemoved,
        ...await removeFromCart(removeObj(vas)),
      }
    }

    if (voice && includedVoice) {
      productsToBeRemoved = {
        ...productsToBeRemoved,
        ...await removeFromCart(removeObj(voice)),
      }
    }

    if (broadband) {
      productsToBeRemoved = {
        ...productsToBeRemoved,
        ...await removeFromCart(removeObj(broadband)),
      }
    }

    if (bundle && product?.productOfferingId !== bundle?.productOfferingId) {
      productsToBeRemoved = {
        ...productsToBeRemoved,
        ...await removeFromCart(removeObj(bundle)),
      }
    }
  }

  if (isVoucher) {
    productsToBeRemoved = {
      ...productsToBeRemoved,
      ...await removeFromCart(removeObj(options.voucherProduct)),
    }
  }

  if (isBroadband) {
    if (bundle) {
      productsToBeRemoved = {
        ...productsToBeRemoved,
        ...await removeFromCart(removeObj(bundle)),
      }
    }

    if (broadband && product?.productOfferingId !== broadband?.productOfferingId) {
      productsToBeRemoved = {
        ...productsToBeRemoved,
        ...await removeFromCart(removeObj(broadband)),
      }
    }
  }

  return productsToBeRemoved
}

export const addToCartOptions = async ({
  product, options, client, channel, hide_strikethrough,
}: CartProps): Promise<OrderOptions> => {
  const {
    broadband,
    bundle, wier, vas, tv, voice,
    cartInstanceIdentifier, appliedProductId,
    appliedReferralCode, promo, discount,
    dcmsEligibility,
  } = options

  const allProducts = [
    bundle,
    broadband,
    wier,
    vas,
    tv,
    voice,
    promo,
    discount,
  ]

  if (allProducts.some(e => e?.productOfferingId === product?.productOfferingId)) {
    return options
  }

  const isBroadband = product?.service_type === HobsServiceType.BROADBAND
  const isBundle = product?.service_type === HobsServiceType.BUNDLE
  // Clear cartInstanceIdentifier when broadband changes
  const shouldClearIdentifier = (product?.service_type === HobsServiceType.BROADBAND || product?.service_type === HobsServiceType.BUNDLE) &&
    (broadband?.productOfferingId !== product?.productOfferingId)
  const dynamicIdentifier = shouldClearIdentifier ? '' : (cartInstanceIdentifier || '')
  const key = product?.service_type as string
  const productsToBeAdded = { [key]: product } as any
  const productExists = allProducts.some(Boolean)
  let discountExists = discount

  const productsToBeRemoved = productExists && await removeProductsFromCart({
    product,
    client,
    channel,
    options,
  })

  const reqObj: ProductToCartRequest = {
    channel,
    cartInstanceIdentifier: dynamicIdentifier,
  }
  const data: any = await client.cart.addPreConfiguredProductToCart(product, reqObj)
  marketingEvents.engageWithCart('add_to_cart', product, options)

  const cartData = Object.keys(data.responseObject.mapOfCartItem)
    .map(key => {
      return data.responseObject.mapOfCartItem[key]
    })
  const dcmsAddon = Boolean(cartData.find(e => e.productOfferingID === 'Dcms_Product'))

  if (isBroadband || isBundle) {
    const discount = product?.productOffering?.find((el: ProductOffering) => el.productType === 'Discount')
    discountExists = discount ? discount : null

    if (appliedProductId && appliedReferralCode) {
      const products = await getProducts() as ProductProps[]
      const decryptedProductId = decryptString(appliedProductId)
      const product = products
        ?.find(e => e.productOfferingId === decryptedProductId)
      const RafObject: RAFProps = {
        appliedProductId,
        appliedReferralCode,
        channel,
        cartInstanceIdentifier: dynamicIdentifier || data.cartInstanceIdentifier,
        product: product!,
      }

      await client.cart.addRafToCart(RafObject)
    }

    if (dcmsEligibility && !dcmsAddon) {
      const DCMSObject: DCMSProps = {
        cartInstanceIdentifier: dynamicIdentifier || data.cartInstanceIdentifier,
        channel,
        product,
      }

      await client.cart.addDCMSToCart(DCMSObject)
    }
  }

  return {
    ...options,
    ...productsToBeRemoved,
    ...productsToBeAdded,
    discount: discountExists,
    channel,
    cartInstanceIdentifier: dynamicIdentifier || data.cartInstanceIdentifier,
    voucher_flags: [],
    promo: isBroadband || isBundle ? null : promo,
    hide_strikethrough,
    voucherProduct: null,
  }
}

export const checkForVoucher = async (broadband: PackageProps, options: OrderOptions, channel: string, cartInstanceIdentifier: string) => {
  const client = createCRMApiClient()
  const productsData = await getProducts() as ProductProps[]
  if (!broadband?.voucher) {
    return {
      ...options,
      voucher_flags: [],
      voucherProduct: null,
    }
  }

  const voucherProduct: any = productsData.find((el: HobsProduct) => el.productOfferingId === broadband.voucher![0]?.voucher_code! && el.productType === 'VAS')

  if (!voucherProduct) {
    return {
      ...options,
      voucher_flags: [],
      voucherProduct: null,
    }
  }

  const reqObj: ProductToCartRequest = {
    channel,
    cartInstanceIdentifier,
  }

  if (options.voucherProduct) {
    if (options.voucherProduct.productOfferingId === voucherProduct.productOfferingId) {
      return {
        ...options,
        voucher_flags: [broadband.voucher![0]],
        voucherProduct,
      }
    }

    await client.cart.removeProductFromCart(cartInstanceIdentifier, options.voucherProduct, channel)
  }

  await client.cart.addPreConfiguredProductToCart(voucherProduct, reqObj)
  marketingEvents.discountVoucherChecked(voucherProduct.productOfferingId)
  return {
    ...options,
    voucher_flags: [broadband.voucher![0]],
    voucherProduct,
  }
}
