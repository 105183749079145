import { css } from 'linaria'
import theme, {
  COLORS, fontWeights,
} from '../../../../../styles/theme'

const classes = {
  steps: css`
    position: relative;
    list-style: none;
    margin: 3rem auto 2.5rem;
    padding: 0;
    width: 100%;
    max-width: ${theme.spacing(75.25)}px;
    height: ${theme.spacing(2.5)}px;
    display: flex;
    justify-content: space-between;
    color: #a9a9a9;

    ${theme.breakpoints.down('sm')} {
      margin: 0;
      padding: 0;
      margin-top: ${theme.spacing(2.5)}px;
      max-width: 90%;
    }

    &:after {
        content: '';
        display: block;
        height: 7px;
        width: 92%;
        background-color: #e4e4e4;
        position: absolute;
        border-radius: 8px;
        left:  ${theme.spacing(2.75)}px;
        top: -${theme.spacing(2.25)}px;

        ${theme.breakpoints.down('sm')} {
          top: ${theme.spacing(1.875)}px;
          height: 2px;
        }
      }
    &:before {
        content: '';
        display: block;
        height: 7px;
        width: 92%;
        background-color: ${theme.palette.primary.main};
        position: absolute;
        border-radius: 8px;
        left: ${theme.spacing(2.75)}px;
        top: -${theme.spacing(2.25)}px;
        z-index: 2;
        transition: width 2s;

        ${theme.breakpoints.down('sm')} {
          top: ${theme.spacing(1.875)}px;
          height: 2px;
        }
      }

    li {
      width: auto;
      display: inline-block;
      height: ${theme.spacing(2.5)}px;
      font-size: ${theme.spacing(1.75)}px;

      ${theme.breakpoints.down('sm')} {
        width: ${theme.spacing(3.75)}px;
        height:  ${theme.spacing(3.75)}px;
        line-height: ${theme.spacing(3.75)}px;
        display: inline-block;
        border: 1px solid ${COLORS.gray3};
        border-radius: 50%;
        font-weight: ${fontWeights.semibold};
        text-align: center;
        z-index: 3;
        background-color: white;
        font-weight: ${fontWeights.semibold};
      }

      a {
        text-decoration: none;
        color: ${theme.palette.primary.main};

        ${theme.breakpoints.down('sm')} {
          width: ${theme.spacing(3.75)}px;
          height:  ${theme.spacing(3.75)}px;
          line-height: ${theme.spacing(3.75)}px;
          display: inline-block;
          border: 1px solid ${theme.palette.primary.main};
          border-radius: 50%;
          text-align: center;
          background-color: white;
          font-weight: ${fontWeights.semibold};
        }
      }
    }
  `,
  lessMargin: css`
    margin-bottom: 0px;
    ${theme.breakpoints.down('sm')} {
      margin-top: 0px;
      margin-bottom: -${theme.spacing(1.25)}px;
    } 
  `,
  link: css`
    ${theme.breakpoints.down('sm')} {
      border: none !important;
    } 
  `,
  activeStep: css`
    color: ${theme.palette.primary.main};
    font-weight: bold;

    ${theme.breakpoints.down('sm')} {
      border: 1px solid ${theme.palette.primary.main} !important;
      background-color: ${theme.palette.primary.main} !important;
      color:  white;
    }
  `,
  step1: css`
   &:before {
     width: 8%;

    ${theme.breakpoints.down('sm')} {
      width: 0%;
    }
   }
 `,
  stepConditional1: css`
    &:before {
      width: 7%;

      ${theme.breakpoints.down('sm')} {
      width: 0%;
      }
    }
  `,
  step2: css`
   &:before {
     width: 21%;

    ${theme.breakpoints.down('sm')} {
      width: 15%;
    }
   }
 `,
  stepConditional2: css`
    &:before {
      width: 17%;
    }
  `,
  step3: css`
   &:before {
     width: 36.5%;
   }
 `,
  stepConditional3: css`
    &:before {
      width: 30%;
    }
  `,
  step4: css`
   &:before {
     width: 55%;
   }
 `,
  stepConditional4: css`
   &:before {
     width: 45%;
   }
 `,
  step5: css`
   &:before {
     width: 76%;
   }
 `,
  stepConditional5: css`
   &:before {
     width: 62%;
   }
 `,
  step6: css`
   &:before {
     width: 92%;
   }
 `,
  stepConditional6: css`
   &:before {
     width: 79%;
   }
 `,
  stepConditional7: css`
   &:before {
     width: 92%;
   }
 `,
}

export default classes
