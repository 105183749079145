import React from 'react'
import { Paper } from '@material-ui/core'
import Axios from 'axios'

import classes from './CurrentVacancies.styles'
import { sanitiseHtml, SanitiseProfile } from '../../utils/sanitise'

export interface CurrentVacanciesProps {
  vacanciesEmbedScript: string;
}

export default class CurrentVacanciesWrapperContainer extends React.PureComponent<CurrentVacanciesProps> {
  static async getInitialProps() {
    const embedScriptResponse = await Axios.get('https://communityfibre.bamboohr.com/jobs/embed2.php')

    return {
      vacanciesEmbedScript: embedScriptResponse.data,
    }
  }

  render() {
    return <CurrentVacancies {...this.props}/>
  }
}

function CurrentVacancies({ vacanciesEmbedScript }: CurrentVacanciesProps) {
  return (
    <Paper elevation={10} square className={classes.panel}>
      <div
        id="BambooHR"
        /* External embed content - using relaxed sanitization profile */
        dangerouslySetInnerHTML={{ __html: sanitiseHtml(vacanciesEmbedScript, SanitiseProfile.EMBED) }}
      />
    </Paper>
  )
}
