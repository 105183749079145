import React, {
  useEffect, useState,
} from 'react'
import ClockIcon from '@material-ui/icons/AccessTime'
import {
  Typography,
  useMediaQuery,
  Box,
} from '@material-ui/core'
import {
  useOrderContext,
} from '../../../OrderContext'
import { classes } from './TimeoutModal.styles'
import {
  ChangeCollapsible,
} from '../CheckoutStep'
import { useRouter } from 'next/router'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import ModalBox from '../../../../ModalBox/ModalBox'
import theme from '../../../../../styles/theme'
import StyledButton from '../../../../StyledButton/StyledButton'
import { StepType } from '../../../../../utils/commonEnums'

const TIMEOUT_DURATION = 10

function getTimeoutDuration(start: number | null): number {
  const fullLength = TIMEOUT_DURATION * 60000

  if (!start) {
    return fullLength
  }

  const offset = new Date()
    .getTime() - start

  const timeLeft = fullLength - offset
  if (timeLeft < 0) {
    return 0
  }

  return timeLeft
}

export default function TimeoutModal({
  changeCollapsible,
}:
  { changeCollapsible: ChangeCollapsible}) {
  const {
    options, setOptions,
  } = useOrderContext()

  const [
    modalOpen,
    setModalOpen,
  ] = useState<boolean>(false)

  const router = useRouter()
  const { countDownTimerStart } = options
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const buttonLabel = 'Select a new slot'

  useEffect(() => {
    if (countDownTimerStart === null) {
      return
    }

    const timer = setTimeout(() => {
      setModalOpen(true)
      setOptions({
        ...options,
        timeslot: null,
        countDownTimerStart: null,
      })
    }, getTimeoutDuration(countDownTimerStart))

    return () => {
      clearTimeout(timer)
    }
  }, [countDownTimerStart])

  function onChangeInstallationClick() {
    changeCollapsible(StepType.checkoutInstallation)
    marketingEvents.cta({
      name: buttonLabel,
      router,
      options,
    })
    setOptions({
      ...options,
      timeslotError: 'Your slot expired, please select another slot',
    })
    setModalOpen(false)
  }

  function onCancelClick() {
    router.push('/')
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <ModalBox
      aria-labelledby="timeout-modal-title"
      aria-describedby="timeout-modal-body"
      visible={modalOpen}
      sync={handleClose}
      width={isMobile ? 'auto' : '701px'}
      noCloseIcon
      fullScreen={isMobile} px={4} py={4}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <ClockIcon fontSize="large" className={classes.icon} color="primary"/>
        <Box py={2}>
          <Typography color="primary" variant="h3" id="timeout-modal-title">Time&apos;s up</Typography>
        </Box>
        <Typography color="primary" className={classes.bodyText} id="timeout-modal-body">Your installation appointment has expired.</Typography>
        <Box py={2}>
          <Typography color="primary" className={classes.bodyText}>Would you like to select another installation appointment?</Typography>
        </Box>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="center" pt={2}>
          <Box display="flex" pr={!isMobile && 1} pb={isMobile && 2} justifyContent="center">
            <StyledButton color="primary" handleClick={onChangeInstallationClick}>
              <Typography className={classes.button}>{buttonLabel}</Typography>
            </StyledButton>
          </Box>
          <StyledButton color="secondary" variant="outlined" handleClick={onCancelClick}>
            <Typography className={classes.button}>Back to homepage</Typography>
          </StyledButton>
        </Box>
      </Box>
    </ModalBox>
  )
}
