import {
  css,
} from 'linaria'
import theme from '../../../../../styles/theme'

const classes = {
  submit: css`
  display: flex;
  width: 100%;
  gap:8px;
  justify-content: space-between;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column-reverse;

      }
          ${theme.breakpoints.up('sm')} {
      flex-direction: row;
      }
    &.MuiGrid-item {
      margin: 0 auto;
    }
  `,
  backButton: css`
  p{
    font-weight: ${theme.typography.fontWeightBold!};
  }
  min-width: 212px !important;
  `,
  loading: css`
  margin-left:${theme.spacing(3)}px;
  vertical-align: middle;
  `,
  button: css`
  font-weight: ${theme.typography.fontWeightBold!} !important;
  `,
}

export default classes
