import React from 'react'
import {
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import classes from './CopyWithCTA.styles'
import StyledButton from '../StyledButton/StyledButton'
import { cx } from 'linaria'
import { CtaProps } from '../../utils/commonProps'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { useRouter } from 'next/router'
import theme, { fontWeights } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import { MediaImageProps } from '../Media/MediaImage'
import { sanitiseHtml } from '../../utils/sanitise'
enum MediaType {
  image = 'image',
  video = 'video',
  icon = 'icon',
}

export interface CopyWithCTAProps {
  readonly title: string;
  readonly title_variant? : 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'| 'body1'| 'body2';
  readonly body?: string;
  readonly background_image?: MediaImageProps;
  readonly media_image?: MediaImageProps;
  readonly styled_text?: string;
  readonly extra_width?: boolean;
  readonly padding_top_desktop?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  readonly padding_bottom_desktop?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  readonly padding_top_mobile?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  readonly padding_bottom_mobile?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  readonly primary_button?: CtaProps;
  readonly new_version_url?: string;
  readonly secondary_button?: CtaProps;
  readonly media_type?: MediaType;
  readonly media_src?: string;
  readonly video_poster?: string;
  readonly anchor_ref?: string;
  hide_me?: boolean;
  new_version?: boolean;
}

export default function CopyWithCTA({
  title, title_variant, body, styled_text, padding_top_desktop = 8, padding_bottom_desktop = 8, padding_top_mobile = 8, padding_bottom_mobile = 8, extra_width, primary_button, secondary_button, media_type, media_src, anchor_ref, video_poster, hide_me, new_version, new_version_url,
}: CopyWithCTAProps) {
  const textContainerClasses = extra_width ? cx(classes.text, classes.widerText) : classes.text
  const styledTextClasses = extra_width ? cx(classes.styledText, classes.widerText) : classes.styledText
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const routerPath = useRouter()
  const handleClick = async (e: React.ChangeEvent<{}>) => {
    e.stopPropagation()

    if (new_version_url) {
      routerPath.push(routerPath.asPath + new_version_url, undefined, { shallow: true })
    }
  }

  if (hide_me) {
    return null
  }

  const CTAMedia = () => {
    if (media_type === MediaType.image) {
      return (
        <div className={classes.mediaImage}>
          <img src={media_src} width="100%" alt={title}/>
        </div>
      )
    }

    return (
      <div className={classes.mediaVideo}>
        <video width="100%" height="100%" poster={video_poster} controls>
          <source src={media_src}/>
          <track kind="captions"/>
        </video>
      </div>
    )
  }

  const CTANew = () => {
    const CTAIcon = () => {
      if (media_type === MediaType.icon) {
        return (
          <div className={classes.mediaIcon}>
            <img src={media_src} alt={body}/>
          </div>
        )
      }

      return null
    }

    return (
      <Link underline="none" onClick={handleClick} href={new_version_url}>
        <Box padding="16px" ml="16px" mr="16px" className={classes.newVersionContainer}>
          <CTAIcon/>
          {body && (
            <Box>
              <Typography
                style={{
                  fontWeight: isMdUp ? fontWeights.semibold : fontWeights.medium,
                  fontSize: isMdUp ? fontSizes.body1 : fontSizes.body3,
                }}
                className={classes.newVersionText}
              >
                {body}
              </Typography>
            </Box>
          )}
          <KeyboardArrowRightIcon/>
        </Box>
      </Link>
    )
  }

  return (
    <Grid container item xs={12} sm={10} style={{ margin: 'auto' }}>
      <Box
        id={anchor_ref}
        pt={{
          xs: Number(padding_top_mobile),
          sm: Number(padding_top_desktop),
        }}
        pb={{
          xs: Number(padding_bottom_mobile),
          sm: Number(padding_bottom_desktop),
        }}
        className={classes.boxContainer}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          {new_version ? (
            <CTANew/>
          ) : (
            <>
              <Box mb={3} className={textContainerClasses}>
                <Typography
                  variant={title_variant ? title_variant : 'h3'}
                  color="primary"
                  align="center"
                  component="header"
                >
                  {title}
                </Typography>
                {body && (
                  <Box mt={3} className={styledTextClasses}>
                    <Typography align="center">{body}</Typography>
                  </Box>
                )}
                {styled_text && (
                  <Box mt={3} textAlign="center">
                    {/* Sanitized HTML content */}
                    <div className={styledTextClasses} dangerouslySetInnerHTML={{ __html: sanitiseHtml(styled_text) }}/>
                  </Box>
                )}
              </Box>
              <Grid className={classes.media}>{media_src && <CTAMedia/>}</Grid>
              <Box>
                {primary_button?.url && (
                  <StyledButton
                    color="primary"
                    isModal={primary_button.modal}
                    url={primary_button.url}
                    className={cx(
                      primary_button.animated_icon && classes.animatedButtonIcon,
                    )}
                  >
                    {primary_button.text}
                    {primary_button.icon && (
                      <img src={primary_button.icon} alt=""/>
                    )}
                  </StyledButton>
                )}
                {secondary_button?.url && (
                  <StyledButton
                    color="secondary"
                    variant="outlined"
                    isModal={secondary_button.modal}
                    url={secondary_button.url}
                    className={cx(
                      secondary_button.animated_icon && classes.animatedButtonIcon,
                    )}
                  >
                    {secondary_button.text}
                    {secondary_button.icon && (
                      <img src={secondary_button.icon} alt=""/>
                    )}
                  </StyledButton>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}
