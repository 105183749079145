import React, {
  useEffect, useState,
} from 'react'
import {
  PostListResponse,
} from '../../../utils/blog'
import butter from '../../../butter'
import OverviewItems from '../OverviewItems/OverviewItems'
import { useRouter } from 'next/router'

export interface PressReleaseListProps {
  readonly press_release_data: PostListResponse;
  readonly link_text: string;
}

const PAGE_SIZE = 3

export default function PressReleaseList({
  press_release_data,
  link_text,
}: PressReleaseListProps) {
  const router = useRouter()
  const currentPage = Number(router.query.page) || 1

  const [
    filteredArticles,
    setFilteredArticles,
  ] = useState<PostListResponse>(press_release_data)

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        if (currentPage === 1) {
          setFilteredArticles(press_release_data)
        } else {
          const response = await butter.post.list({
            page: currentPage,
            page_size: PAGE_SIZE,
            exclude_body: true,
            category_slug: 'press-release',
          })
          if (response.data.data.length === 0) {
            router.push('/not-found')
          } else {
            setFilteredArticles(response.data)
          }
        }
      } catch (err) {
        console.error('Error fetching press releases:', err)

        if (err.response && err.response.status === 404) {
          router.push('/not-found')
        } else {
          setFilteredArticles({
            data: [],
            meta: {
              count: 0,
              next_page: null,
              previous_page: null,
            },
          })
        }
      }
    }

    fetchArticles()
  }, [
    currentPage,
    press_release_data,
  ])

  return (
    <OverviewItems
      item_data={filteredArticles}
      link_text={link_text}
      type_items="press-release"
      page_size_items={PAGE_SIZE}
      currentPage={currentPage}
    />
  )
}

PressReleaseList.getInitialProps = async function () {
  const initialPosts = await butter.post.list({
    page: 1,
    page_size: PAGE_SIZE,
    exclude_body: true,
    category_slug: 'press-release',
  })

  return {
    press_release_data: initialPosts.data,
  }
}
