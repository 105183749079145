import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  FormControl,
  Select,
  MenuItem,
  Hidden,
  IconButton,
  Typography
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Done, Close } from "@material-ui/icons";
import { useSwipeable } from "react-swipeable";
import classes from "./ComparisonTableNew.styles";

import TextWithImage from "../ComparisonModal/TextWithImage";
import StyledButton from "../StyledButton/StyledButton";
import { cx } from "linaria";
import Logo from "../../../public/new-logo-green.svg";
import theme from "../../styles/theme";

interface ModalProps {
  last_updated: string;
  button: {
    modal?: boolean;
    url: string;
    title: string;
    text: string;
  };
  legal: {
    text: string;
    logo: string;
  };
  text_with_image: any;
  comparison_table_file?: string;
}

const filterOptions = ["Show all", "Speed", "Price and savings", "Features"];

export default function ComparisonTableNew({
  comparison_table_file,
  text_with_image,
  button,
  legal,
  last_updated
}: ModalProps) {
  const [tableData, setTableData] = useState<any[]>([]);

  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<string>("");
  const [currentActiveSpeedTab, setCurrentActiveSpeedTab] = useState<number>(1);
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<string>("Show all");
  const [selectedProvider, setSelectedProvider] = useState<string>(
    "All Providers"
  );
  const [providers, setProviders] = useState<string[]>([]);
  const [currentProviderIndex, setCurrentProviderIndex] = useState<number>(0);
  const [activeProvider, setActiveProvider] = useState<string>("");
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNextProvider();
    },
    onSwipedRight: () => {
      handlePreviousProvider();
    }
  });
  const swipeContainerRef = useRef<HTMLDivElement>();

  const refPassthrough = (el: HTMLDivElement) => {
    handlers.ref(el);
    swipeContainerRef.current = el;
  };
  const formatData = (data: any[]) => {
    return data.map(row => {
      const formattedRow: any = {};
      Object.keys(row).forEach(key => {
        const value = row[key];
        if (typeof value === "number") {
          formattedRow[key] = value.toFixed(2);
        } else if (typeof value === "string" && value.includes("£")) {
          formattedRow[key] = value.trim();
        } else {
          formattedRow[key] = value;
        }
      });
      return formattedRow;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (comparison_table_file) {
        try {
          const response = await fetch(comparison_table_file);
          const blob = await response.blob();
          const reader = new FileReader();

          reader.addEventListener("load", e => {
            if (e.target) {
              const data = new Uint8Array(e.target.result as ArrayBuffer);
              const workbook = XLSX.read(data, { type: "array" });
              setSheetNames(workbook.SheetNames);
              const defaultSheetName =
                workbook.SheetNames[currentActiveSpeedTab] ||
                workbook.SheetNames[0];
              setSelectedSheet(defaultSheetName);

              const jsonData: any[] = [];
              workbook.SheetNames.forEach(sheetName => {
                const worksheet = workbook.Sheets[sheetName];
                const sheetData = XLSX.utils.sheet_to_json(worksheet, {
                  defval: "",
                  blankrows: false,
                  raw: false
                });
                jsonData.push({
                  sheetName,
                  data: formatData(sheetData)
                });
              });

              const activeSheetData =
                jsonData[currentActiveSpeedTab]?.data || jsonData[0]?.data;
              setTableData(jsonData);
              extractSpecialValues(activeSheetData);
              if (activeSheetData.length > 0) {
                const providerKeys = Object.keys(activeSheetData[0]).filter(
                  key => key !== "Feature" && key !== "Community Fibre"
                );
                setProviders(providerKeys);
              }
            }
          });

          reader.readAsArrayBuffer(blob);
        } catch (err) {
          console.error("Error fetching the file:", err);
        }
      }
    };

    fetchData();
  }, [comparison_table_file, currentActiveSpeedTab]);

  useEffect(() => {
    if (tableData.length > 0) {
      const sheetData =
        tableData.find(sheet => sheet.sheetName === selectedSheet)?.data || [];
      if (sheetData.length > 0) {
        const providerKeys = Object.keys(sheetData[0]).filter(
          key => key !== "Feature" && key !== "Community Fibre"
        );
        setProviders(providerKeys);
        if (!providerKeys.includes(selectedProvider)) {
          setSelectedProvider("All Providers");
        }
      }
    }
  }, [selectedSheet, tableData]);

  useEffect(() => {
    if (sheetNames.length > 0) {
      if (
        !sheetNames
          .map(name => name.toLowerCase().replace(/\s+/g, ""))
          .includes(selectedProduct.toLowerCase().replace(/\s+/g, ""))
      ) {
        setSelectedProduct(sheetNames[1]);
      }
    }
  }, [sheetNames]);

  const extractSpecialValues = (data: any[]) => {
    const thValues: any[] = [];
    const yhValues: any[] = [];
    data.forEach(row => {
      Object.values(row).forEach(value => {
        if (value === "þ") {
          thValues.push(value);
        } else if (value === "ý") {
          yhValues.push(value);
        }
      });
    });
  };

  const handleSheetChange = (index: number) => {
    if (index >= 0 && index < sheetNames.length) {
      setSelectedSheet(sheetNames[index]);
      setCurrentActiveSpeedTab(index);
    }
  };

const handleProductChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  const product = event.target.value as string;
  setSelectedProduct(product);

  const index = sheetNames.indexOf(product);
  if (index >= 0) {
    handleSheetChange(index);

    const sheetData =
      tableData.find(sheet => sheet.sheetName === sheetNames[index])?.data || [];
    const providersWithData = getProvidersWithData(sheetData);

    if (activeProvider && !providersWithData.includes(activeProvider)) {
    
      setActiveProvider(providersWithData[0] || "");
      setCurrentProviderIndex(0);
    }
  }
};

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFilter(event.target.value as string);
  };

  const handleProviderChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const provider = event.target.value as string;
    setSelectedProvider(provider);
    setCurrentProviderIndex(0);
  };

  const handlePreviousProvider = () => {
    const sheetData =
      tableData.find(sheet => sheet.sheetName === selectedSheet)?.data || [];
    const providersWithData = getProvidersWithData(sheetData);
  
    if (providersWithData.length === 0) return;
  
    setCurrentProviderIndex(prevIndex => {
      const prevIndexValue = prevIndex > 0 ? prevIndex - 1 : providersWithData.length - 1;
      setActiveProvider(providersWithData[prevIndexValue]); 
      return prevIndexValue;
    });
  };

  const handleNextProvider = () => {
    const sheetData =
      tableData.find(sheet => sheet.sheetName === selectedSheet)?.data || [];
    const providersWithData = getProvidersWithData(sheetData);
  
    if (providersWithData.length === 0) return;
  
    setCurrentProviderIndex(prevIndex => {
      const nextIndex = prevIndex < providersWithData.length - 1 ? prevIndex + 1 : 0;
      setActiveProvider(providersWithData[nextIndex]); 
      return nextIndex;
    });
  };

  const getProvidersWithData = (sheetData: any[]) => {
    if (!sheetData || sheetData.length === 0) {
      return []; 
    }

    return Object.keys(sheetData[0])
      .filter((key: string) => key !== "Feature" && key !== "Community Fibre")
      .filter((provider: string) =>
        sheetData.some((row: any) => row[provider] && row[provider] !== "")
      );
  };

  const renderIcon = (
    cellValue: string | number,
    provider: string,
    feature: string
  ) => {
    const isYes = cellValue === "þ";
    const isNo = cellValue === "ý";

    if (isYes) {
      return (
        <span
          className={classes.doneIncon}
          aria-label={`${provider} ${feature} yes`}
        >
          <Done />
        </span>
      );
    }

    if (isNo) {
      return (
        <span
          className={classes.closeIcon}
          aria-label={`${provider} ${feature} no`}
        >
          <Close />
        </span>
      );
    }

    if (typeof cellValue === "number") {
      cellValue = cellValue.toFixed(2);
    }

    if (typeof cellValue === "string" && cellValue.includes("£")) {
      return (
        <Typography
          variant="body2"
          color="textPrimary"
          aria-label={`${provider} ${feature} ${cellValue.trim()}`}
        >
          {cellValue.trim()}
        </Typography>
      );
    }

    return (
      <Typography
        variant="body2"
        color="textPrimary"
        aria-label={`${provider} ${feature} ${cellValue}`}
      >
        {cellValue}
      </Typography>
    );
  };
  const comparisonFooter = (sheetData: any[]) => {
    const hasProviderData =
      selectedProvider === "All Providers"
        ? sheetData.some(row =>
            Object.keys(row).some(
              key =>
                key !== "Feature" &&
                key !== "Community Fibre" &&
                row[key] &&
                row[key] !== ""
            )
          )
        : sheetData.some(
            row => row[selectedProvider] && row[selectedProvider] !== ""
          );

    if (!hasProviderData) {
      return null;
    }

    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        gridGap={theme.spacing(1.5)}
        mt={3}
        px={2}
      >
        {button.text && (
          <Box display="flex" justifyContent="center" mt={2}>
            <StyledButton
              isModal={button?.modal}
              color="primary"
              url={button?.url}
            >
              {button.text}
            </StyledButton>
          </Box>
        )}
        <Typography variant="caption" color="textPrimary">
          Last updated on {last_updated}. {legal.text}
        </Typography>
        {legal.logo && <img src={legal.logo} alt={legal.text} height="25" />}
      </Box>
    );
  };
  const renderTable = () => {
    if (tableData.length === 0 || !selectedSheet) {
      return null;
    }
  
    const sheetData =
      tableData.find(sheet => sheet.sheetName === selectedSheet)?.data || [];
    if (!sheetData || sheetData.length === 0) {
      return <TextWithImage text_with_image={text_with_image} />;
    }
  
    const providersWithData: string[] = Object.keys(
      sheetData[0] as Record<string, any>
    )
      .filter((key: string) => key !== "Feature" && key !== "Community Fibre")
      .filter((provider: string) =>
        sheetData.some(
          (row: Record<string, any>) => row[provider] && row[provider] !== ""
        )
      );
  
    
    if (
      selectedProvider !== "All Providers" &&
      !providersWithData.includes(selectedProvider)
    ) {
      return <TextWithImage text_with_image={text_with_image} />;
    }
  
    
    if (
      selectedProvider === "All Providers" &&
      activeProvider &&
      !providersWithData.includes(activeProvider)
    ) {
      setActiveProvider(providersWithData[0] || "");
      setCurrentProviderIndex(0);
      return null; 
    }
  
    const filteredProviders =
      selectedProvider === "All Providers"
        ? providersWithData
        : [selectedProvider];
  
  

  
    const filteredData = sheetData.filter((row: any) => {
      if (selectedFilter.toLowerCase() === "show all") {
        return true;
      }
    
      const featureCategoryMatch = row.Feature.match(/^\[(.*?)\]/);
      const featureCategory = featureCategoryMatch ? featureCategoryMatch[1] : null;
    
      const isPackageRow = row.Feature.toLowerCase() === "package";
    
      return isPackageRow || featureCategory === selectedFilter;
    });
    
    const cleanedData = filteredData.map((row: any) => ({
      ...row,
      Feature: row.Feature.replace(/^\[.*?\]/, "").trim()
    }));
  
    return (
      <TableContainer component={Paper} aria-label="Comparison Table">
        <Table
          className={classes.table}
          aria-labelledby="comparison-table-heading"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} scope="col" tabIndex={0}>
                <Typography variant="body2" color="textPrimary">
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} scope="col" tabIndex={0}>
                <img src={Logo} alt="Community Fibre" />
              </TableCell>
              <Hidden smDown>
                {filteredProviders.map((provider, index) => (
                  <TableCell
                    key={index}
                    className={classes.tableCell}
                    scope="col"
                    tabIndex={0}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.providerName}
                    >
                      {provider}
                    </Typography>
                  </TableCell>
                ))}
              </Hidden>
              <Hidden mdUp>
                {selectedProvider === "All Providers" && (
                  <TableCell
                    className={classes.tableCell}
                    scope="col"
                    tabIndex={0}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      position="relative"
                      justifyContent="center"
                    >
                      <IconButton
                        className={cx(
                          classes.mobileArrowBack,
                          classes.mobileArrow
                        )}
                        onClick={handlePreviousProvider}
                        disabled={
                          providersWithData.length <= 1 ||
                          currentProviderIndex === 0
                        }
                        aria-label="Previous Provider"
                      >
                        <ArrowBackIos />
                      </IconButton>
                      {providersWithData.length > 0 && (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className={classes.providerName}
                        >
                          {providersWithData[currentProviderIndex]}
                        </Typography>
                      )}
                      <IconButton
                        className={cx(
                          classes.mobileArrowForward,
                          classes.mobileArrow
                        )}
                        onClick={handleNextProvider}
                        disabled={
                          providersWithData.length <= 1 ||
                          currentProviderIndex === providersWithData.length - 1
                        }
                        aria-label="Next Provider"
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </TableCell>
                )}
                {selectedProvider !== "All Providers" && (
                  <TableCell
                    className={classes.tableCell}
                    scope="col"
                    tabIndex={0}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.providerName}
                    >
                      {selectedProvider}
                    </Typography>
                  </TableCell>
                )}
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
  {cleanedData.map((row: any, index: number) => (
    <TableRow key={index} tabIndex={0}>
      <TableCell
        className={classes.tableCell}
        style={{ textAlign: "left" }}
        scope="row"
        tabIndex={0}
      >
        <Typography variant="body2" color="textPrimary">
          {row.Feature}
        </Typography>
      </TableCell>
      <TableCell
        className={`${
          row.Feature === "Price difference"
            ? classes.priceDiferenceColourCell
            : classes.secondaryColourCell
        } ${classes.tableCell}`}
        tabIndex={0}
      >
        {renderIcon(
          row["Community Fibre"],
          "Community Fibre",
          row.Feature
        )}
      </TableCell>
      <Hidden smDown>
        {filteredProviders.map((provider, index) => (
          <TableCell
            key={index}
            className={`${
              row.Feature === "Price difference"
                ? classes.priceDiferenceColourCell
                : classes.tableCell
            }`}
            tabIndex={0}
          >
            {renderIcon(row[provider], provider, row.Feature)}
          </TableCell>
        ))}
      </Hidden>
      <Hidden mdUp>
        {selectedProvider === "All Providers" && (
          <TableCell
            className={`${
              row.Feature === "Price difference"
                ? classes.priceDiferenceColourCell
                : classes.tableCell
            }`}
            tabIndex={0}
          >
            {renderIcon(
              row[providersWithData[currentProviderIndex]],
              providersWithData[currentProviderIndex],
              row.Feature
            )}
          </TableCell>
        )}
        {selectedProvider !== "All Providers" && (
          <TableCell
            className={`${
              row.Feature === "Price difference"
                ? classes.priceDiferenceColourCell
                : classes.tableCell
            }`}
            tabIndex={0}
          >
            {renderIcon(
              row[selectedProvider],
              selectedProvider,
              row.Feature
            )}
          </TableCell>
        )}
      </Hidden>
    </TableRow>
  ))}
</TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box className={classes.compare}>
        <Box
          gridGap={8}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body1"
            color="primary"
            style={{ fontWeight: 600 }}
          >
            Compare
          </Typography>

          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="provider-label"
              value={selectedProvider}
              onChange={handleProviderChange}
            >
              <MenuItem value="All Providers">All Providers</MenuItem>
              {providers.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Box>

        <Box display="flex" alignItems="center" gridGap={8} width="100%">
        <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="product-label"
              value={selectedProduct}
              onChange={handleProductChange}
            >
              {sheetNames.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography
            variant="body1"
            color="primary"
            style={{ fontWeight: 600 }}
          >
            on
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="filter-label"
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              {filterOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Box>
      </Box>
      {!tableData.find(sheet => sheet.sheetName === selectedSheet)?.data
        .length ? (
        <TextWithImage text_with_image={text_with_image} />
      ) : (
        <Box className={classes.wrapper}>
          <div className={classes.root} {...handlers} ref={refPassthrough}>
            <Hidden smDown>{renderTable()}</Hidden>
            <Hidden mdUp>
              <Box overflow="hidden">{renderTable()}</Box>
            </Hidden>
          </div>
        </Box>
      )}
      {comparisonFooter(
        tableData.find(sheet => sheet.sheetName === selectedSheet)?.data || []
      )}
    </Box>
  );
}
