import React, {
  useState, useEffect,
} from 'react'
import { Discount } from '../../../../../../utils/commonInterfaces'
import PhoneIcon from '../icons/PhoneIcon'
import TVIcon from '../icons/TVIcon'
import WifiIcon from '../icons/WifiIcon'
import WIERIcon from '../icons/WIERIcon'
import BundleIcon from '../icons/BundleIcon'
import {
  Box, Grid,
  Typography,
} from '@material-ui/core'
import { classes } from '../SummarySection.styles'
import { getOrderProducts } from '../../../../../../utils/getOrderProducts'
import {
  renderDiscountDetails, getFormattedMonthlyCost, getTotalInstallationCost, getDiscountedPeriod, getDiscountFreePeriod, getFreePeriod, formattedFreePeriod, getProductWithLongestFreePeriod, getMonthlyCost, renderDiscountPeriod, getPackageLength, getProductDiscountPeriod, getStandardMonthlyCost,
} from '../../../../../../utils/getProductDetails'
import NortonIcon from '../icons/NortonIcon'
import { getButterPage } from '../../../../../../butter'

const ProductDetails = ({
  broadband, bundle, voice, tv, wier, vas, discount, promo, summaryPriceCopy,
}: {broadband: any; bundle: any; voice?: any | null; tv?: any | null; wier?: any | null; vas?: any | null; discount: Discount | null; promo: any | null; summaryPriceCopy?: any | null}) => {
  const monthlyCost = getFormattedMonthlyCost({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
    discount,
  })
  const installationCost = getTotalInstallationCost({
    broadband,
    bundle,
    voice,
    wier,
    vas,
    tv,
  })

  const broadbandLength = broadband ? getPackageLength(broadband) : null
  const discountLength = getProductDiscountPeriod(broadband)
  const broadbandIsDiscountFree = getDiscountFreePeriod(broadband)
  const broadbandDiscountLength = broadband ? getDiscountedPeriod(broadband) : null
  const broadbandIsFree = getFreePeriod(broadband) > 0
  const voiceIsFree = getFreePeriod(voice) > 0
  const tvIsFree = getFreePeriod(tv) > 0
  const wierIsFree = getFreePeriod(wier) > 0
  const vasIsFree = getFreePeriod(vas) > 0
  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })

  const [
    isMonthlyCost,
    setIsMonthlyCost,
  ] = useState(false)

  useEffect(() => {
    async function getGeneralSettings() {
      const generalSettings: any = await getButterPage('general-settings', {})
      const show_monthly_cost = generalSettings.components.find((e: any) => e.type === 'monthly_cost')?.show_monthly_cost
      setIsMonthlyCost(show_monthly_cost)
    }

    getGeneralSettings()
  }, [])

  return (
    <>
      <Grid item xs={12} md={6}>
        <Box mb={2}>
          <Typography variant="h4">Products</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box pb={4}>
          {broadband &&
            <div>
              <WifiIcon/>
              <Typography variant="body1">
                {broadband.display_name}
              </Typography>
            </div>}
          {bundle &&
            <div>
              <BundleIcon/>
              <Typography variant="body1">
                {bundle.display_name}
              </Typography>
            </div>}
          {voice &&
            <div>
              <PhoneIcon/>
              <Typography variant="body1">
                {voice.display_name}
              </Typography>
            </div>}
          {tv &&
            <div>
              <TVIcon/>
              <Typography variant="body1">
                {tv.display_name}
              </Typography>
            </div>}
          {wier &&
            <div className={classes.wier}>
              <WIERIcon/>
              <Typography variant="body1">
                {wier.display_name}
              </Typography>
            </div>}
          {vas &&
            <div>
              <NortonIcon/>
              <Typography variant="body1">
                {vas.display_name}
              </Typography>
            </div>}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box py={3}>
          <Typography variant="h4">Price you will pay</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} className={classes.monthlyCost}>
        <Typography variant="h3">
          {broadbandIsDiscountFree && broadbandDiscountLength && broadbandIsDiscountFree > 0 ? (
            <span>{broadbandDiscountLength} FREE</span>
          ) : (
            <>
              <span>{monthlyCost}</span><span>/month</span>
            </>
          )}
        </Typography>
        {renderDiscountDetails(broadband, classes.discount)}
        {(broadbandIsFree || voiceIsFree || tvIsFree || wierIsFree || vasIsFree) &&
          <Typography variant="body2">Promotion applies for the first {formattedFreePeriod(getProductWithLongestFreePeriod(orderProducts))}</Typography>}
        {promo &&
          <>
            {!broadband.promo_code &&
              <Typography variant="body2">
                {`Promo '${promo.name}' applied`}
              </Typography>}
            <Typography variant="subtitle2" className={classes.discount}>
              {isMonthlyCost ?
                <>
                  <strong>£{getMonthlyCost({
                    broadband,
                    bundle,
                    voice,
                    tv,
                    wier,
                    vas,
                  })}/month
                  </strong> after {renderDiscountPeriod(promo)}
                </> :
                <>
                  <strong>
                    Free broadband {' '}
                  </strong>
                  for {' '}{renderDiscountPeriod(promo)}
                </>}

            </Typography>
          </>}
        {discountLength && broadbandLength && discountLength < broadbandLength &&
          <Typography variant="subtitle2" className={classes.discount}>
            <strong>
              {getStandardMonthlyCost({
                broadband,
                voice,
                tv,
                wier,
                vas,
              })}/month
            </strong> after {discountLength} months
          </Typography>}
        <Box pt={1}>
          {installationCost > 0 ?
            <><Typography variant="h3"><span>Setup fee: £{installationCost}</span></Typography>
              <Typography variant="body2">Added to your first bill only</Typography>
            </> :
            <Typography variant="h3"><span>No setup fee</span></Typography>}
        </Box>
        {summaryPriceCopy &&
        <Typography className={classes.summaryPrice} variant="body2">{summaryPriceCopy}</Typography>}
      </Grid>
    </>
  )
}

export default ProductDetails
