import React from 'react'
import { sanitiseHtml, SanitiseProfile } from '../../utils/sanitise'

const iframeStyles = {
  width: '100%',
  minHeight: '550px',
}

export function MsDynamicsHeaderScripts() {
  /* eslint-disable-next-line quotes */
  const scriptString = "<script>var sandboxSrc = 'https://0db26486115a4b6a9590e9ddf24dd315.svc.dynamics.com/t/formsandbox/3CgYIuX6qWSwkZiyMf-laIpJ0w8qDk25KlwkHSTg-Ik/99ac11d3-c904-ec11-b6e6-000d3a86b60f?ad=' + encodeURIComponent(document.location.toString()); document.getElementById('7d4ae8c7-d996-4068-b2e0-acc925143f02').setAttribute('src', sandboxSrc); </script>"
  return (
    <>
      <iframe title="msDynamics" frameBorder="0" id="7d4ae8c7-d996-4068-b2e0-acc925143f02" scrolling="no" style={iframeStyles}/>
      {/* External embed content - using relaxed sanitization profile */}
      <div dangerouslySetInnerHTML={{ __html: sanitiseHtml(scriptString, SanitiseProfile.EMBED) }}/>
    </>
  )
}
