import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  useForm, FormContext,
  useFormContext,
} from 'react-hook-form'
import {
  Container, Box, Grid, TextField,
  Button, Typography, InputAdornment,
  useMediaQuery,
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import { cx } from 'linaria'

import classes from './PersonalDetailsForm.styles'
import PersonalDetails from '../../../../PersonalDetails/PersonalDetails'
import createCRMApiClient from '../../../../../api/CRMApi'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'
import logger from '../../../../../utils/logger'
import {
  ChangeCollapsible,
} from '../CheckoutStep'
import { useOrderContext } from '../../../OrderContext'
import { JsonObject } from 'type-fest'
import { getProducts } from '../../../../../api/Products'
import { ProductProps } from '../../../../Products/Product'
import { HobsProduct } from '../../../../../api/Packages'
import { ProductToCartRequest } from '../../../../../api/Cart'
import { useRouter } from 'next/router'
import StyledButton from '../../../../StyledButton/StyledButton'
import theme from '../../../../../styles/theme'
import { StepType } from '../../../../../utils/commonEnums'

export interface PersonalDetailsFormData extends JsonObject {
  sprn: string;
  address: string;
  firstName: string;
  lastName: string;
  phone: string;
  contactMarketing: boolean;
  email: string;
  contactEmail: boolean;
  contactPhone: boolean;
  contactSMS: boolean;
  contactPost: boolean;
  readTerms: boolean;
  promo: string;
}
export interface PersonalDetailsProps {
  saveFormDetails: (formDetails: {}) => void;
  changeCollapsible: ChangeCollapsible;
  verifiedAddress?: boolean;
  verifiedAddressHobs?: boolean;
  showOTS?: boolean;
  backUrl: string;
}

export default function PersonalDetailsForm(
  {
    saveFormDetails,
    changeCollapsible,
    verifiedAddress,
    verifiedAddressHobs,
    showOTS,
    backUrl,
  }: PersonalDetailsProps) {
  const methods = useForm<PersonalDetailsFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const {
    handleSubmit, formState,
  } = methods
  const { options } = useOrderContext()
  const { oneTouchSwitching } = options
  const { isSubmitting } = formState
  const buttonSubmitText = 'Continue'
  const router = useRouter()

  const onSubmit = handleSubmit(async (data) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      contactMarketing,
      sprn,
    } = data

    marketingEvents.cta({
      name: buttonSubmitText,
      router,
      options,
    })
    const authMarketing = contactMarketing

    const client = createCRMApiClient()

    const result = await client.hobsLeads.register(email, firstName, lastName, phone, authMarketing, sprn)

    if ('error' in result) {
      logger.error(result.message, result.error)
    }

    const personalFormData = {
      ...data,
      readTerms: true,
      authMarketing,
      contactEmail: authMarketing,
      contactPhone: authMarketing,
      contactSMS: authMarketing,
      contactPost: authMarketing,
    }

    if (oneTouchSwitching) {
      marketingEvents.addContactInfo()
      marketingEvents.onCheckoutOption(3)
      marketingEvents.onCheckoutSection('switching')
      saveFormDetails({ data: personalFormData })
      changeCollapsible(StepType.checkoutOneTouchSwitching)
    } else if (!oneTouchSwitching) {
      marketingEvents.addContactInfo()
      marketingEvents.onCheckoutOption(3)
      marketingEvents.onCheckoutSection('installation')
      saveFormDetails({ data: personalFormData })
      changeCollapsible(StepType.checkoutInstallation)
    }
  })
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <FormContext {...methods}>
      <Container>
        <Box pt={2} pb={isMobile ? 0 : 2}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={6}>
              <PersonalDetails
                showConfirmEmailField={false}
                showLandlineField={false}
                formData={options?.data}
                gridConfig={{ spacing: 6 }}
                gridClassName={classes.gridNoMargin}
                showOTS={showOTS}
              />
            </Grid>
            <Grid item className={cx(isSubmitting && classes.submitting, classes.submit)}>
              <StyledButton className={classes.backButton} color="secondary" variant="outlined" handleClick={() => router.push(backUrl, undefined, { shallow: true })}>
                <Typography noWrap>Back</Typography>
              </StyledButton>
              <StyledButton type="submit" variant="contained" color="primary" id="step-1" disabled={isSubmitting || verifiedAddress || verifiedAddressHobs}>
                <Typography noWrap>{buttonSubmitText}</Typography>
              </StyledButton>
            </Grid>
          </form>
        </Box>
      </Container>
    </FormContext>
  )
}

export function PromoCode({
  savedPromo,
  saveFormDetails,
}: {
  savedPromo: any | null;
  saveFormDetails: (formDetails: {}) => void;
}) {
  const {
    options,
  } = useOrderContext()

  const {
    cartInstanceIdentifier, channel, promoToApply, broadband,
  } = options

  const {
    register, getValues,
  } = useFormContext<{promo: string; sprn: string; address: string}>()

  const [
    error,
    setError,
  ] = useState<string | null>(null)

  const [
    promo,
    setPromo,
  ] = useState<any | null>(savedPromo)

  const { premise } = useContext(PremiseContext)

  useEffect(() => {
    if ((promoToApply || broadband?.promo_code) && !promo) {
      checkPromoCode(promoToApply || broadband.promo_code)
    }
  }, [
    promoToApply,
    promo,
    broadband,
  ])

  async function checkPromoCode(promoCode?: string) {
    const client = createCRMApiClient()
    const errorName = 'Personal details form error'
    let { promo } = getValues()

    if (!promo && savedPromo) {
      promo = savedPromo.productOfferingId
    }

    if (promoCode) {
      promo = promoCode
    }

    if (options?.voucherProduct) {
      const errorMsg = 'Voucher already applied'
      setError(errorMsg)
      marketingEvents.error(options, 'ERR-PROMO-3', errorName, errorMsg)
      return
    }

    const productsData = await getProducts() as ProductProps[]

    let promoProduct: any = productsData.find((el: HobsProduct) => el.productOfferingId === promo && el.productType === 'VAS')

    if (!promoProduct) {
      const errorMsg = 'Promo code not recognised'
      marketingEvents.error(options, 'ERR-PROMO-2', errorName, errorMsg)
      setError(errorMsg)
      setPromo(null)
      saveFormDetails({ promo: null })
      return
    }

    promoProduct = {
      ...promoProduct,
      service_type: 'promo',
    }

    const reqObj: ProductToCartRequest = {
      channel,
      cartInstanceIdentifier,
    }
    await client.cart.addPreConfiguredProductToCart(promoProduct, reqObj)

    marketingEvents.discountCodeChecked(promoProduct.productOfferingId)

    saveFormDetails({ promo: promoProduct })
    setPromo(promoProduct)

    if (!premise) {
      const errorMsg = 'No address selected'
      setError(errorMsg)
      marketingEvents.error(options, 'ERR-PROMO-1', errorName, errorMsg)
    }
  }

  const id = 'promo'

  return (
    <Grid
      item
      container direction="row" wrap="nowrap"
      alignItems="center"
      className={classes.discountWrapper}
    >
      {promo ?
        <TextField
          multiline
          disabled={Boolean(promo.name)}
          value={broadband.promo_code ? 'Promotion applied' : `'${promo.name}' Promo code applied`}
          className={classes.discountText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input },
            startAdornment: (
              <InputAdornment position="start">
                <Check/>
              </InputAdornment>
            ),
          }}
        /> :
        <TextField
          id={id}
          inputRef={register}
          name={id}
          error={Boolean(error)}
          helperText={error}
          className={classes.discountText}
          placeholder="Enter code if you have one"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input },
          }}
        />}
      {!promo &&
        <div className={classes.buttonWrapper}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault()
              checkPromoCode()
            }}
          >
            Submit
          </Button>
        </div>}
    </Grid>
  )
}
