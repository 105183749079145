import React, { useContext } from 'react'
import classes from './Stepper.styles'
import { cx } from 'linaria'
import getStepUrl from '../../../getStepUrl'
import {
  Grid, useMediaQuery,
} from '@material-ui/core'
import Link from 'next/link'
import { StepTypes } from '../CheckoutStep'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'
import theme from '../../../../../styles/theme'
import { StepType } from '../../../../../utils/commonEnums'

interface StepProps {
  number: number;
  slug: StepTypes;
  text: string;
}

/* eslint complexity: ["error", 100] */
const Stepper = ({
  currentStep, lessMargin,
}: {currentStep: StepTypes | StepTypes; lessMargin?: boolean}) => {
  const { premise } = useContext(PremiseContext)
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  let currentStepClass
  let currentStepNumber: number

  const steps: StepProps[] = [
    {
      number: 1,
      slug: StepType.broadband,
      text: 'Packages',
    },
    {
      number: 2,
      slug: StepType.addons,
      text: 'Extras',
    },
    {
      number: 3,
      slug: StepType.checkoutDetails,
      text: 'Details',
    },
    {
      number: 4,
      slug: StepType.checkoutInstallation,
      text: 'Installation',
    },
    {
      number: 5,
      slug: StepType.checkoutPayment,
      text: 'Payment',
    },
    {
      number: 6,
      slug: StepType.checkoutOrderSummary,
      text: 'Review',
    },
  ]

  switch (currentStep) {
    case StepType.broadband:
      currentStepClass =  classes.step1
      currentStepNumber = 1
      break
    case StepType.addons:
      currentStepClass = classes.step2
      currentStepNumber = 2
      break
    case StepType.checkoutDetails:
      currentStepClass = classes.step3
      currentStepNumber = 3
      break
    case StepType.checkoutOneTouchSwitching:
      currentStepClass = classes.step3
      currentStepNumber = 3
      break
    case StepType.checkoutInstallation:
      currentStepClass = classes.step4
      currentStepNumber = 4
      break
    case StepType.checkoutPayment:
      currentStepClass = classes.step5
      currentStepNumber = 5
      break
    case StepType.checkoutOrderSummary:
      currentStepClass = classes.step6
      currentStepNumber = 6
      break
    default:
      break
  }

  return (
    <nav aria-label="checkout steps" id="stepper">
      <Grid container justify="center">
        <ul className={cx(classes.steps, currentStepClass, lessMargin && classes.lessMargin)}>
          {steps.map((step, i) => {
            const stepRender = mobile ? step.number.toString() : step.text
            if (step.number < currentStepNumber && step.number <= 2) {
              return (
                <li key={i} className={classes.link}><Link href={getStepUrl(step.slug, premise?.sprn)}>{stepRender}</Link></li>
              )
            }

            if (step.number < currentStepNumber && step.number > 2) {
              return (
                <li key={i} className={classes.link}><Link href={getStepUrl(step.slug)}>{stepRender}</Link></li>
              )
            }

            return (
              <li key={i} className={cx(step.number === currentStepNumber && classes.activeStep)}>{stepRender}</li>
            )
          })}
        </ul>
      </Grid>
    </nav>
  )
}

export default Stepper
