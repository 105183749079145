import React, {
  useEffect,
} from 'react'
import DesktopHeader from './DesktopHeader/DesktopHeader'
import MobileHeader from './MobileHeader/MobileHeader'
import marketingEvents from '../../utils/marketing/marketingEvents'
import StickyBannerFinishOrder from './StickyBannerFinishOrder/StickyBannerFinishOrder'
import { Hidden } from '@material-ui/core'
import { ContactUsProps } from '../ContactUs/ContactUs'
import ComparisonModal, {
  ComparisonModalProps,
} from '../ComparisonModal/ComparisonModal'
import { CtaProps } from '../../utils/commonProps'
import classes from './Header.styles'
import { cx } from 'linaria'
import { TrustPilotBoxProps } from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'

export interface MenuItem {
  title: string;
  url?: string;
  icon?: string;
  open_new_window?: boolean;
  modal?: boolean;
  sub_menu_items?: Array<{
    title: string;
    url?: string;
    modal?: boolean;
  }>;
}

export interface MobileNavigationTab {
  title: string;
  url: string;
}

export interface MenuCategoryTab {
  title: string;
  menu_items: MenuItem[];
  url?: string;
  button?: CtaProps;
  mobile_navigation_tabs?: MobileNavigationTab[];
  mini_basket?: boolean;
}
export interface HeaderProps {
  shared_menu_items?: MenuItem[];
  categories?: MenuCategoryTab[];
  secure?: boolean;
  overlap?: boolean;
  inverted?: boolean;
  sticky_banner?: boolean;
  mini_basket_error?: string;
  contact_us?: ContactUsProps;
  trustpilot_box?: TrustPilotBoxProps;
  mobile_button?: CtaProps;
  modal_comparison?: ComparisonModalProps;
  children?: React.ReactNode;
  my_account_link?: MenuItem;
}

export default function Header(props: HeaderProps) {
  useEffect(() => {
    marketingEvents.pageView(location.pathname)
  }, [])

  const {
    children, ...other
  } = props

  return (
    <div id="header" className={cx(props.overlap ? classes.overlap : '', props.inverted ? classes.inverted : '')}>
      {props.modal_comparison?.anchor_id &&
      <ComparisonModal
        comparison_table_file={props.modal_comparison?.comparison_table_file}
        text_with_image={props.modal_comparison?.text_with_image}
        anchor_id={props.modal_comparison?.anchor_id}
        button={props.modal_comparison?.button}
        columns_company={props.modal_comparison?.columns_company}
        columns_time={props.modal_comparison?.columns_time}
        last_updated={props.modal_comparison?.last_updated}
        legal_logo={props.modal_comparison?.legal_logo}
        legal_text={props.modal_comparison?.legal_text}
        modal_display={props.modal_comparison?.modal_display}
        row_names={props.modal_comparison?.row_names}
        tabs={props.modal_comparison?.tabs}
        product_id={props.modal_comparison?.product_id}
        tv_columns_company={props.modal_comparison?.tv_columns_company}
        sync_with_packages={props.modal_comparison?.sync_with_packages}
        contract_length={props.modal_comparison?.contract_length}
        title={props.modal_comparison.title}
      />}
      <Hidden smDown>
        <DesktopHeader {...other}>{children}</DesktopHeader>
        <StickyBannerFinishOrder sticky_banner={props.sticky_banner}/>
      </Hidden>
      <Hidden mdUp>
        <MobileHeader {...other}>{children}</MobileHeader>
        <StickyBannerFinishOrder sticky_banner={props.sticky_banner}/>
      </Hidden>
    </div>
  )
}
