import React, {
  useEffect,
} from 'react'
import { useRouter } from 'next/router'

import {
  ButterCMSComponent,
} from '../../butter'
import PackageStep from './steps/PackageStep/PackageStep'
import CheckoutStep, { StepTypes } from './steps/CheckoutStep/CheckoutStep'
import useValidateStep from './useValidateStep'
import OrderComponents from './OrderComponents'
import OrderManagement from './OrderManagement/OrderManagement'
import OrderManagementConfirmation from './OrderManagement/OrderManagementConfirmation'
import { useOrderContext } from './OrderContext'
import { StepType } from '../../utils/commonEnums'

interface Props {
  bundlesData: {
    components: ButterCMSComponent[];
  };
  checkoutData: {
    components: any;
  };
  orderManagementData: {
    components: ButterCMSComponent[];
  };
  step: StepTypes;
  orderManagementPortingConfirmation: {
    components: ButterCMSComponent[];
  };
  orderManagementNewPortingConfirmation: {
    components: ButterCMSComponent[];
  };
  orderConfirmationData: {
    components: ButterCMSComponent[];
  };
  generalSettings: {
    components: ButterCMSComponent[];
  };
}

export interface OrderRedirectInterface {
  error_5000_url: string;
  error_5001_url: string;
  error_5003_url: string;
  default_error_url: string;
  type: string;
}

export default function OrderFlow({
  step, bundlesData, checkoutData, orderManagementData,
  orderManagementPortingConfirmation, orderConfirmationData, orderManagementNewPortingConfirmation, generalSettings,
}: Props) {
  const router = useRouter()
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    broadband,
  } = options

  let stepComponent: ButterCMSComponent | null = null
  const redirectComponent: any = checkoutData.components.find((item: ButterCMSComponent): any => item.type === 'order_status_code_url_redirect')
  const OTSMoreInfoModal: any = checkoutData.components.find((item: any) => item.ots_more_info_modal)?.ots_more_info_modal
  const accountTooltip: any = checkoutData.components.find((item: any) => item.account_tooltip)?.account_tooltip
  const hideSkipAppointment: boolean = checkoutData.components.find((item: any) => item.hide_skip_installation_appointment)?.hide_skip_installation_appointment
  const summaryPriceCopy: boolean = checkoutData.components.find((item: any) => item.summary_price_copy)?.summary_price_copy

  const butterCheckoutContent = {
    details: {
      OTSMoreInfoModal,
    },
    installation: {
      hideSkipAppointment,
    },
    payment: {},
    summary: {
      accountTooltip,
      summaryPriceCopy,
    },
  }

  useEffect(() => {
    const {
      error_5000_url,
      error_5001_url,
      error_5003_url,
      default_error_url,
    }: OrderRedirectInterface = redirectComponent
    setOptions({
      ...options,
      error_5000_url,
      error_5001_url,
      error_5003_url,
      default_error_url,
      butterCheckoutContent: JSON.stringify(butterCheckoutContent),
    })
  }, [redirectComponent])
  const { validateStep } = useValidateStep()

  switch (step) {
    case StepType.checkout:
    case StepType.checkoutDetails:
    case StepType.checkoutInstallation:
    case StepType.checkoutOneTouchSwitching:
    case StepType.checkoutPayment:
    case StepType.checkoutOrderSummary:
      stepComponent = (
        <OrderComponents data={checkoutData}>
          <CheckoutStep router={router} step={step} data={bundlesData} generalSettings={generalSettings}/>
        </OrderComponents>
      )
      break
    case StepType.checkoutOrderProcessing:
      stepComponent = (
        <OrderComponents data={checkoutData}>
          <CheckoutStep router={router} step={step} data={bundlesData} generalSettings={generalSettings}/>
        </OrderComponents>
      )
      break
    case StepType.checkoutOrderConfirmation:
      stepComponent = (
        <OrderComponents data={orderConfirmationData}/>
      )
      break
    case StepType.orderManagement:
      stepComponent = (
        <OrderComponents data={orderManagementData}>
          <OrderManagement data={orderManagementData}/>
        </OrderComponents>
      )
      break
    case StepType.orderManagementNewNumberConfirmation:
      stepComponent = (
        <OrderManagementConfirmation data={orderManagementNewPortingConfirmation}/>
      )
      break
    case StepType.orderManagementPortingConfirmation:
      stepComponent = (
        <OrderManagementConfirmation data={orderManagementPortingConfirmation}/>
      )
      break
    case StepType.broadband:
    case StepType.broadbandBundles:
    case StepType.addons:
      stepComponent = (
        <PackageStep bundlesData={bundlesData} generalSettings={generalSettings} preselectedPackageId={broadband?.productOfferingId ?? null} step={step}/>
      )
      break
    default:
      stepComponent = (
        <PackageStep bundlesData={bundlesData} generalSettings={generalSettings} preselectedPackageId={broadband?.productOfferingId ?? null} step={step}/>
      )
  }

  useEffect(() => {
    (async () => {
      await validateStep(step)
    })()
  }, [step])

  return (
    <div>
      {stepComponent}
    </div>
  )
}
