import React, { useState } from 'react'
import {
  ExpansionPanel, ExpansionPanelSummary,
  Typography, ExpansionPanelDetails, Grid,
  Box, Container,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Wysiwyg, { Color } from '../Wysiwyg/Wysiwyg'
import classes from './FAQDropdown.styles'
import { cx } from 'linaria'
import StyledButton from '../StyledButton/StyledButton'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { ExpandMore } from '@material-ui/icons'
import { JsonLd } from '../StructuredData'
import { generateFAQData } from '../../utils/structuredData'

export interface FAQ {
  title: string;
  body: string;
}

export interface FAQDropdownProps {
  readonly meta: {
    id: number;
  };
  title?: string;
  faqs: FAQ[];
  dropdown: boolean;
  button_text?: string;
  button_url?: string;
  background_colour?: boolean;
  blue_gradient?: boolean;
  button_color?: 'primary' | 'secondary';
  anchor_ref?: string;
}

export default function FAQDropdown({
  meta,
  title,
  faqs,
  dropdown,
  button_url,
  button_text,
  background_colour,
  blue_gradient,
  button_color = 'primary',
  anchor_ref,
}: FAQDropdownProps) {
  const [
    openPanel,
    setOpenPanel,
  ] = useState<number | undefined>(undefined)

  const handleExpansionChange = (index: number) => {
    setOpenPanel(index === openPanel ? undefined : index)
  }

  const FaqTitle = () => {
    return <Typography variant="h3" color="primary">{title}</Typography>
  }

  const FaqContent = () => {
    return (
      <Grid
        container
        alignItems="center"
        direction="row"
      >
        <Grid item xs={12}>
          {faqs.map(({
            title, body,
          }, index) => (
            <ExpansionPanel
              square
              classes={{ root: cx(classes.panel, background_colour && classes.panelWithBackground) }}
              elevation={0}
              key={index}
              expanded={index === openPanel}
              onChange={() => handleExpansionChange(index)}
            >
              <ExpansionPanelSummary
                classes={{ expandIcon: classes.icon }}
                expandIcon={<AddIcon color="primary"/>}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
              >
                <Box>
                  <Typography color="primary" variant="h5" component="span">{title}</Typography>
                </Box>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Box>
                  <Wysiwyg body={body} variant="body1" color={Color.textPrimary}/>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Grid>
        {(button_text && button_url) &&
        <Grid item xs={12} md={4} classes={{ root: classes.button.wrapper }}>
          <StyledButton
            url={button_url}
            color={blue_gradient ? 'primary' : button_color}
            blueGradient={blue_gradient}
            variant="outlined"
          >
            {button_text}
          </StyledButton>
        </Grid>}
      </Grid>
    )
  }

  // Generate FAQ structured data
  const faqStructuredData = generateFAQData(
    faqs.map(faq => ({
      question: faq.title,
      answer: faq.body.replace(/<[^>]*>/g, ''), // Strip HTML tags for structured data
    })),
  )

  return (
    <ContainerWithPadding id={anchor_ref ? anchor_ref : `FAQDropdown_${meta.id}`}>
      <JsonLd data={faqStructuredData}/>
      <SectionWithMargin>
        <Box
          className={classes.mainBox}
        >
          <Container>
            {dropdown ? (
              <ExpansionPanel classes={{ root: cx(classes.wrapperPanel) }} elevation={0}>
                <ExpansionPanelSummary expandIcon={<ExpandMore color="primary"/>}>
                  {title && <FaqTitle/>}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <FaqContent/>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : (
              <>
                {title && <FaqTitle/>}
                <FaqContent/>
              </>
            )}
          </Container>
        </Box>
      </SectionWithMargin>
    </ContainerWithPadding>
  )
}
