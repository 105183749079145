import React from 'react'
import Head from 'next/head'
import { sanitiseHtml } from '../../utils/sanitise'

interface JsonLdProps {
  data: Record<string, unknown> | Record<string, unknown>[];
}

/**
 * Component for adding JSON-LD structured data to the page
 *
 * Using dangerouslySetInnerHTML is necessary for JSON-LD but is safe in this context
 * as long as the data comes from a trusted source and not user input.
 */
export const JsonLd: React.FC<JsonLdProps> = ({ data }) => {
  // Create the JSON string with null replacing undefined values
  const jsonString = JSON.stringify(
    data,
    (_key, value) => value === undefined ? null : value,
    process.env.NODE_ENV === 'development' ? 2 : 0,
  )

  return (
    <Head>
      <script
        type="application/ld+json"
        // This usage is safe for JSON-LD when data comes from trusted sources, but we still sanitize
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitiseHtml(jsonString) }}
      />
    </Head>
  )
}
