import React, {
  useCallback,
  useContext,
  useEffect, useMemo, useState,
} from 'react'
import {
  Box, Container, PropTypes, Typography, useMediaQuery,
} from '@material-ui/core'
import {
  HobsProduct,
} from '../../api/Packages'
import Package, {
  ButtonPropsExtended, PackageProps,
} from './Package'
import { TrustPilotBoxProps } from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import PricingPackageTabs, {
  Tab, TabLike,
} from './PricingPackageTabs'
import { TypographyProps } from '../Typography/Typography'
import SliderCarousel from './SliderCarousel'
import {
  getDiscountPrice,
  getSpeed, getProductTerm, getBBSpeed,
} from '../../utils/getProductDetails'
import Bundles, { BundlesProps } from '../Bundles/Bundles'
import theme from '../../styles/theme'
import {
  ChannelType, SegmentType,
} from '../../utils/commonEnums'
import PackagesFooter from './PackagesFooter'
import OfferBanner, { OfferBannerProps } from '../OfferBanner/OfferBanner'
import Wrapper from '../Wrapper/Wrapper'
import LoadingSpinner from '../SearchBar/LoadingSpinner'
import classes from './Packages.styles'
import WarningMessage, { WarningMessageColor } from '../WarningMessage/WarningMessage'
import { ProductsContext } from '../ProductsContext/ProductsContext'
import { getHybridPackages } from '../../utils'
import Heading, { HeadingProps } from '../Heading/Heading'
import ValueProps, { ValuePropsProps } from '../ValueProps/ValueProps'
import marketingEvents from '../../utils/marketing/marketingEvents'

export interface PackagesProps {
  name?: string;
  heading?: HeadingProps;
  offer_banner?: OfferBannerProps;
  offer_banner_position?: string;
  value_props?: ValuePropsProps;
  packages: PackageProps[];
  bundles?: BundlesProps;
  footnote?: TypographyProps;
  footnote_link?: LinkProps;
  trustpilot?: TrustPilotBoxProps;
  anchor?: string;
  file_size?: number;
  contract_terms?: ContractLengthChoices[];
  filter_labels?: FilterLabels[];
  filter_labels_option_2?: FilterLabels[];
  filter_labels_option_3?: FilterLabels[];
  package_align?: PropTypes.Alignment;
  package_cta: ButtonPropsExtended;
  package_cta_secondary?: ButtonPropsExtended;
  free_set_up_copy?: string;
  package_card_flip: boolean;
  can_be_selected: boolean;
  on_click_package?: (product: HobsProduct, cartInstanceIdentifier: string, channel: string, hide_strikethrough: boolean) => void;
  on_click_bundle?: (product: HobsProduct, cartInstanceIdentifier: string, channel: string) => void;
  stacked_cards_on_mobile?: boolean;
  customer_segment?: SegmentType;
  show_free_months?: boolean;
  background_gradient?: boolean;
  hide_strikethrough?: boolean;
  channel?: ChannelType;
  default_contract_length?: string;
}

export interface FilterLabels {
  readonly label: string;
}

export interface FilterLabels {
  readonly label: string;
}

export interface ContractLengthChoices {
  readonly title: string;
  readonly title_text?: string;
  readonly body_text?: string;
  readonly type: 'ANNUAL_24' | 'ANNUAL' | 'MONTHLY';
  title_2: TypographyProps;
  subtitle?: TypographyProps;
  contract_length?: number;
}

export interface LinkProps {
  label?: string;
  href?: string;
}

/* eslint complexity: ["error", 100] */
function Packages({
  customer_segment,
  packages,
  offer_banner,
  offer_banner_position,
  value_props,
  bundles,
  footnote,
  footnote_link,
  trustpilot,
  anchor,
  file_size,
  contract_terms,
  filter_labels,
  filter_labels_option_2,
  filter_labels_option_3,
  on_click_package,
  on_click_bundle,
  package_cta,
  can_be_selected,
  package_cta_secondary,
  free_set_up_copy,
  package_card_flip,
  package_align,
  stacked_cards_on_mobile,
  show_free_months,
  background_gradient,
  default_contract_length,
  hide_strikethrough,
  heading,
}: PackagesProps) {
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    broadband,
    bundle,
    packagesTerm,
    filterLabel,
  } = options

  const contractTerms = contract_terms || []

  const tabs = useMemo(() => {
    return contractTerms.map(
      ({
        type,
        title,
        title_2,
        subtitle,
        contract_length,
      }: ContractLengthChoices): Tab => {
        return {
          type,
          label: title,
          title_2,
          subtitle,
          contract_length,
        }
      },
    )
  }, [contractTerms])

  const [
    cardTogglers,
    setCardTogglers,
  ] = useState<string>('option_0')

  // Update cardTogglers state from local storage - CRO magic
  useEffect(() => {
    localStorage.setItem('card_togglers', 'option_0')
    setTimeout(() => {
      const cardTogglers = localStorage.getItem('card_togglers')
      cardTogglers && setCardTogglers(cardTogglers)
    }, 5000)
  }, [])

  const filterLabels = useMemo(() => {
    switch (cardTogglers) {
      case 'option_0':
        return []
      case 'option_1':
        return filter_labels || []
      case 'option_2':
        return filter_labels_option_2 || []
      case 'option_3':
        return filter_labels_option_3 || []
      default:
        return []
    }
  }, [
    cardTogglers,
    filter_labels,
    filter_labels_option_2,
    filter_labels_option_3,
  ])

  const tabsFilter = useMemo(() =>
    filterLabels.map(({ label }: FilterLabels): TabLike => ({
      label,
    }))
  , [filterLabels])

  // Making sure the correct tab is selected even after refresh or/and CRO magic
  useEffect(() => {
    if (cardTogglers === 'option_0' || !cardTogglers || tabsFilter.length === 0) {
      setActiveFilterTab('')
      setNoFilters(true)
      return
    }

    setActiveFilterTab(options.filterLabel)
    setNoFilters(false)
  }, [
    cardTogglers,
  ])

  const [
    activeTabIndex,
    setActiveTabIndex,
  ] = useState<number>()
  const [
    activeFilterTabIndex,
    setActiveFilterTabIndex,
  ] = useState<number>()

  const [
    startSpeedAnimation,
    setStartSpeedAnimation,
  ] = useState<boolean>(true)

  const [
    activeTermLength,
    setActiveTermLength,
  ] = useState<number>(24)

  const [
    activeFilterLabel,
    setActiveFilterLabel,
  ] = useState<string>('')

  const [
    errorMessage,
    setErrorMessage,
  ] = useState(false)

  const [
    disabledButton,
    setDisabledButton,
  ] = useState(false)

  const [
    noTerms,
    setNoTerms,
  ] = useState<boolean>(false)

  const [
    noFilters,
    setNoFilters,
  ] = useState<boolean>(false)

  const setTermInOptions = (term: number) => {
    setOptions(
      {
        ...options,
        packagesTerm: {
          ...packagesTerm,
          [customer_segment!]: term,
        },
      },
    )
  }

  const setFilterInOptions = (label: string) => {
    setOptions(
      {
        ...options,
        filterLabel: label,
      },
    )
  }

  const setActiveTab = (term: number) => {
    setActiveTermLength(term)
    setActiveTabIndex(tabs?.findIndex(e => e.contract_length === term))
  }

  const setTermAndOptions = (term: number) => {
    setTermInOptions(term)
    setActiveTab(term)
  }

  const setActiveFilterTab = (label: string) => {
    setActiveFilterLabel(label)
    setActiveFilterTabIndex(tabsFilter?.findIndex(e => e.label === label))
  }

  const setFilterAndOptions = (label: string) => {
    setFilterInOptions(label)
    setActiveFilterTab(label)
  }

  const handleChangeTerm = useCallback((
    _event: React.ChangeEvent<{}>,
    newActiveIndex: number,
  ) => {
    const term = tabs[newActiveIndex]?.contract_length
    setStartSpeedAnimation(false)

    if (term) {
      setActiveTab(term)
      setTermInOptions(term)
    }
  }, [
    tabs,
    setActiveTab,
    setTermInOptions,
  ])

  const handleChangeFilter = useCallback((
    _event: React.ChangeEvent<{}>,
    newActiveIndex: number,
  ) => {
    const label = tabsFilter[newActiveIndex]?.label
    setStartSpeedAnimation(false)

    if (label) {
      setActiveFilterTab(label)
      setFilterAndOptions(label)
    }
    marketingEvents.simpleEvent(`click_${label}`, options)
  }, [
    tabsFilter,
    setActiveFilterTab,
    setFilterAndOptions,
  ])

  const defaultCMSTerm = Number(default_contract_length)

  useEffect(() => {
    const getFirstTabTerm = tabs[0]?.contract_length
    const defaultStateIsAvailable = tabs.some(e => e.contract_length === activeTermLength)
    const storedTerm = packagesTerm[customer_segment!]
    const storedTermExists = tabs.some(e => e.contract_length === storedTerm)

    if (tabs.length === 0) {
      return setNoTerms(true)
    }

    if (storedTerm && storedTermExists) {
      return setActiveTab(storedTerm)
    }

    if (defaultCMSTerm) {
      return setTermAndOptions(defaultCMSTerm)
    }

    if (defaultStateIsAvailable) {
      return setTermAndOptions(activeTermLength)
    }

    if (getFirstTabTerm) {
      return setTermAndOptions(getFirstTabTerm)
    }
  }, [
    packagesTerm,
    customer_segment,
  ])

  useEffect(() => {
    const getFirstTabFilterLabel = tabsFilter[0]?.label
    const defaultFilterState = tabsFilter.some(e => e.label === activeFilterLabel)
    const storedFilterLabel = filterLabel
    const storedFilterLabelExists = tabsFilter.some(e => e.label === storedFilterLabel)

    if (tabsFilter.length === 0) {
      return setNoFilters(true)
    }

    if (storedFilterLabel && storedFilterLabelExists) {
      return setActiveFilterTab(storedFilterLabel)
    }

    if (defaultFilterState) {
      return setFilterAndOptions(activeFilterLabel)
    }

    if (getFirstTabFilterLabel) {
      return setFilterAndOptions(getFirstTabFilterLabel)
    }
  }, [filterLabel, tabsFilter])

  const { dataPackages } = useContext(ProductsContext)
  const hybridPackages = getHybridPackages(dataPackages!, packages!)

  useEffect(() => {
    setTimeout(() => {
      if (hybridPackages === undefined || hybridPackages?.length === 0) {
        setErrorMessage(true)
      } else {
        setErrorMessage(false)
      }
    }
    , 30000)
  }, [hybridPackages])

  useEffect(() => {
    errorMessage && marketingEvents.error(options, 'ERR-PACKAGES-1', 'Packages timeout', 'Packages timed out.')
  }, [errorMessage])

  const [
    multipleTabs,
    setMultipleTabs,
  ] = useState<boolean>(tabsFilter.length > 1 && tabs.length > 1)

  useEffect(() => {
    setMultipleTabs(tabsFilter.length > 1 && tabs.length > 1)
  }, [tabsFilter, tabs])
  
  const callback = (val: boolean) => {
    setStartSpeedAnimation(val)
  }

  const setDisableButton = () => {
    setDisabledButton(true)
    setTimeout(() => {
      setDisabledButton(false)
    }
    , 3000)
  }

  const createPackageProps = useCallback((item: PackageProps) => ({
    customer_segment,
    display_name: item.display_name,
    button: item.button && item.button.text ? item.button : package_cta,
    button_secondary: item.button_secondary && item.button_secondary.text ? item.button_secondary : package_cta_secondary,
    flip: package_card_flip,
    anchor: item.anchor,
    flag: item.flag,
    bullets_with_icons: item.bullets_with_icons,
    voucher: item.voucher,
    promo_code: item.promo_code,
    free_months_with_promo_code: item.free_months_with_promo_code,
    after_promo_copy: item.after_promo_copy,
    price_guidance: item?.price_guidance,
    name_first_line: item?.name_first_line,
    name_second_line: item?.name_second_line,
    strikethrough_name: item?.strikethrough_name,
    price: item?.price, // Price from butter which overrides HOBS
    old_price: item?.old_price, // Old price from butter which overrides HOBS
  }), [
    customer_segment,
    package_cta,
    package_cta_secondary,
  ])

  const renderBusinessPackages = (packages: PackageProps[]) => {
    return packages?.map((item: PackageProps, index: number) => {
      const itemTerm = Number(item.contract_length)
      return (activeTermLength === itemTerm || noTerms) && (
        <Package
          key={index}
          bullet_align="center"
          simple_bullets={item.simple_bullets}
          bullets_back={item.bullets_back}
          upload_speed={getBBSpeed(item)}
          hide={item.hide}
          file_size={file_size}
          startSpeedAnimation={startSpeedAnimation}
          align={item.align ? item.align : package_align}
          setDisableButton={setDisableButton}
          disabledButton={disabledButton}
          activeTermLength={activeTermLength}
          {...createPackageProps(item)}
        />
      )
    })
  }

  const renderHybridPackages = (hybridPackages: PackageProps[], useFilters = false) => {
    if (useFilters) {
      const hasMatchingPackages = hybridPackages.some((item: PackageProps) => {
        const itemFilterLabels = item.filter_labels
        const allLabels = itemFilterLabels && itemFilterLabels.map(e => e.label)
        return (
          (activeTermLength === getProductTerm(item) || noTerms) &&
          ((allLabels && allLabels.includes(activeFilterLabel)) || noFilters)
        )
      })
  
      if (!hasMatchingPackages) {
        return [
          <Box key="no-matches" className={classes.noPackagesMessage}>
            <Typography variant="body1" color="primary" align="center">
              No packages available for the selected filters
            </Typography>
            <Typography variant="body1" color="primary" align="center">
              Please select a different contract length or broadband package.
            </Typography>
          </Box>
        ]
      }
    }
  
    return hybridPackages
      .map((item: PackageProps, index: number) => {
        const itemFilterLabels = item.filter_labels
        const allLabels = itemFilterLabels && itemFilterLabels.map(e => e.label)
        const isPackageVisible = (activeTermLength === getProductTerm(item) || noTerms) && 
          (!useFilters || ((allLabels && allLabels.includes(activeFilterLabel)) || noFilters))
  
        return isPackageVisible ? (
          <Package
            key={index}
            product={item}
            simple_bullets={item.simple_bullets}
            bullets_back={item.bullets_back}
            upload_speed={getSpeed(item, 'UPLOAD_SPEED')}
            download_speed={getSpeed(item, 'DOWNLOAD_SPEED')}
            file_size={file_size}
            startSpeedAnimation={startSpeedAnimation}
            discount={getDiscountPrice(item)}
            hide={item?.hide}
            on_click_package={on_click_package}
            selected={can_be_selected && (
              item?.productOfferingId === broadband?.productOfferingId ||
              item?.productOfferingId === bundle?.productOfferingId
            )}
            term={getProductTerm(item)}
            align={item.align ? item.align : package_align}
            show_free_months={show_free_months}
            hide_strikethrough={hide_strikethrough}
            out_of_contract_copy={item?.out_of_contract_copy}
            setDisableButton={setDisableButton}
            disabledButton={disabledButton}
            free_set_up_copy={free_set_up_copy}
            activeTermLength={activeTermLength}
            {...createPackageProps(item)}
          />
        ) : null
      })
      .filter(Boolean)
  }

  // Main packagesFilteredCards logic
  const packagesFilteredCards = useMemo(() => {
    if (customer_segment === SegmentType.BUSINESS) {
      return renderBusinessPackages(packages)
    }
  
    if (hybridPackages?.length) {
      return renderHybridPackages(hybridPackages, multipleTabs)
    }

    return []
  }, [
    customer_segment,
    packages,
    hybridPackages,
    activeTermLength,
    activeFilterLabel,
    noTerms,
    noFilters,
    package_align,
    file_size,
    startSpeedAnimation,
    setDisableButton,
    disabledButton,
    on_click_package,
    can_be_selected,
    broadband?.productOfferingId,
    bundle?.productOfferingId,
    show_free_months,
    hide_strikethrough,
    free_set_up_copy,
    createPackageProps,
  ])

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const offerBanner = offer_banner ? Object.entries(offer_banner).length > 0 : false
  const valueProps = value_props ? Object.entries(value_props).length > 0 : false

  const packagesProps =
    isMobile && stacked_cards_on_mobile ?
      {
        display: 'flex',
        flexDirection: 'column',
        gridGap: 32,
      } : {
        component: SliderCarousel,
        parentCallback: callback,
      }

  const fetchingStatus = (
    <Box>
      {errorMessage ?
        <Box className={classes.error}>
          <WarningMessage
            color={WarningMessageColor.yellowLight}
            text="<strong><b>Sorry, we are having technical issues displaying our packages</b></strong></br></br>
             To proceed, please try the following:</br></br>
             Refresh the page</br></br>
             Try refreshing or clearing your browser cache</br></br>
             Make sure your device is up-to-date</br></br>
             Call us on <a href='tel:08081966262'>0808 196 6262</a> (Monday to Friday, 8am-9pm, weekends and bank holidays, 9am-7pm.)"
          />
        </Box> :
        <Box className={classes.spinner}>
          <LoadingSpinner/>
        </Box>}
    </Box>
  )

  return (
    <Wrapper id={anchor} gradient={background_gradient} container={false} horizontalPaddings={false} verticalPaddings>
      <Box component={Container} mb={valueProps ? 3 : 4}>
        <Box flexDirection="column" display="flex" alignItems="center">
          {heading && <Heading {...heading}/>}
          {offerBanner && offer_banner_position === 'top' &&
          <Box my={3}>
            <OfferBanner {...offer_banner!}/>
          </Box>}
          {multipleTabs ? (
            <Box className={classes.togglerContainer}>
              <Box id="filterToggler" className={classes.filterToggler}>
                <PricingPackageTabs
                  active={activeFilterTabIndex!}
                  onChange={handleChangeFilter}
                  tabs={tabsFilter}
                />
              </Box>
              <Box id="termToggler">
                <PricingPackageTabs
                  active={activeTabIndex!}
                  onChange={handleChangeTerm}
                  tabs={tabs}
                />
              </Box>
            </Box>
          ) : (
            // Only show termToggler when multipleTabs is false
            tabs.length > 1 && (
              <Box
                width="100%"
                mx="auto"
                id="termToggler"
              >
                <PricingPackageTabs
                  active={activeTabIndex!}
                  onChange={handleChangeTerm}
                  tabs={tabs}
                />
              </Box>
            )
          )}
          {offerBanner && offer_banner_position === 'middle' &&
          <Box mt={5}>
            <OfferBanner {...offer_banner!}/>
          </Box>}
        </Box>
      </Box>
      {valueProps &&
        <Box mb={2}>
          <ValueProps {...(value_props as ValuePropsProps)}/>
        </Box>}
      {((hybridPackages && hybridPackages.length > 0) || customer_segment === SegmentType.BUSINESS) ?
        <Box {...packagesProps}>
          {packagesFilteredCards}
        </Box> :
        fetchingStatus}
      {bundles && bundles?.bundles && Object.values(bundles?.bundles).length > 0 &&
        <Bundles
          hide_strikethrough={hide_strikethrough}
          can_be_selected={can_be_selected}
          on_click_package={on_click_bundle}
          bundles={bundles?.bundles}
          bundle_cta={package_cta}
          activeTerm={activeTermLength}
          mt={4}
          noContainer
        />}
      {offerBanner && offer_banner_position === 'bottom' &&
      <Box mt={6} mx={2}>
        <OfferBanner {...offer_banner!}/>
      </Box>}
      <PackagesFooter trustpilot={trustpilot} footnote={footnote} footnote_link={footnote_link}/>
    </Wrapper>
  )
}

export default Packages
